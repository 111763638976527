import { Divider, Input, Select, Space } from 'antd'
import React from 'react'

const CustomSelectFeedback = (props) => {
    const dropdownStyles = {
        zIndex: 10000,
        minWidth: "270px"
    };

    const handleChange = (e) => {
        props.setOtherFeedback(e.target.value)
    }
    return (
        <Select
            style={{ minWidth: "270px" }}
            mode={"multiple"}
            placeholder="Select"
            dropdownStyle={dropdownStyles}
            onChange={
                props.onChange
            }
            defaultValue={props.selectedValues}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                            style={{ width: "250px" }}
                            value={props.otherFeedback}
                            placeholder="Write your feedback"
                            onChange={handleChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />

                    </Space>
                </>
            )}
            options={props.options}
        />
    )
}

export default CustomSelectFeedback
