import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllProjectsCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/count", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getAllProjects = (current, pageSize) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/projects?page=" + current + "&size=" + pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const getOneProject = (value) => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/" + value, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addProject = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/projects", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateProject = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/projects", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const imageUpload = (formData, id) => {
  return axios.post(APP_CONTEXT_URL + "/v1/uploadImage/" + id, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const excelParserApi = (formData, id) => {
  return axios.post(APP_CONTEXT_URL + "/v1/excel/" + id, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "blob",
  });
};

export const imageUploadApi = (id, formData) => {
  return axios.post(APP_CONTEXT_URL + "/v1/uploadImage/" + id, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const drawingUploadApi = (id, formData) => {
  return axios.post(APP_CONTEXT_URL + "/v1/uploadDrawing/" + id, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteUploadedImage = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/deleteImage/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteUploadedDrawing = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/deleteDrawing/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteGrayBackground = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/deleteGrayBackground/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteAllFloorPlans = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/deleteAllFloorPlans/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const downloadImagesZip = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downloadImage/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "json",
  });
};

export const downloadDrawingZip = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downloadDrawing/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "json",
  });
};

export const downloadGrayBackgroundZip = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downloadGrayBackground/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "json",
  });
};

export const getAllImages = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getImages/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getAllDrawings = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getDrawings/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getAllGrayBackground = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getGrayBackground/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const managerAcceptProject = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/managerAcceptProject/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const reAssignProjectCoodinator = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/reAssignProjectCoodinator", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const assignToContractor = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/assignToContractor/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const assignQualityChecker = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/assignQualityChecker/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const reAssignToContractor = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/reAssignToContractor/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const reAssignQualityChecker = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/reAssignQualityChecker/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const submitProject = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/submitProject/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};


export const convertGrayBackgroundCheck = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/convertGrayBackgroundCheck/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const convertImageToExcelCheck = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/convertImageToExcelCheck/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const convertGrayBackground = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/convertGrayBackground/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const convertImageToExcel = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/convertImageToExcel/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const finalCheckProject = (id, isAssignToMe) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/finalCheckProject/" + id,
    isAssignToMe,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
        'Content-Type': 'application/json'
      },
    }
  );
};


export const acceptedProject = (id, isAssignToMe) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/acceptedProject/" + id,
    isAssignToMe,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
        'Content-Type': 'application/json'
      },
    }
  );
};

export const makeChangesProject = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/makeChangesProject/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const changesRequestedProject = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/changesRequestedProject/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const deliveredProjects = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/deliveredProjects/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const deliveredAllProjects = () => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/deliveredAllProjects/",
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const deleteUploadExcel = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/deleteUploadExcel/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const uploadPdf = (formData, id) => {
  return axios.post(APP_CONTEXT_URL + "/v1/uploadPdf/" + id, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const previewPdfFile = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/previewPdfFile/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "json",
  });
};

export const deleteUploadPdf = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/deleteUploadPdf/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const searchProject = (phrase) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/projects/search",
    phrase ? phrase : " ",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
        "Content-Type": "text/plain",
      },
    }
  );
};

export const searchProjectCount = (phrase) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/projects/search/count",
    phrase ? phrase : " ",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
        "Content-Type": "text/plain",
      },
    }
  );
};

export const exportProject = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downProject/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "blob",
  });
};

export const deliveredToAdjustmentRequired = (project) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/deliveredToAdjustmentRequired",
    project,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    }
  );
};

export const updateImageName = (oldImageId, newImageName) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/updateImageName/" + oldImageId,
    newImageName,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
        "Content-Type": "text/plain",
      },
    }
  );
};

export const updateAdjustmentData = (project) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateAdjustment", project, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateProjectFeedback = (project) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateProjectFeedback", project, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateDropboxLink = (project) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateDropboxLink", project, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const checkDrawingStatus = (projectId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/drawingStatus/" + projectId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const copyProject = (projectId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/copyProject/" + projectId, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

