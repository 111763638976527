/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import inProgress from "./../../resources/in-progress.svg";
import ready from "./../../resources/ready.svg";
import {
  managerAcceptProject,
  finalCheckProject,
  makeChangesProject,
  acceptedProject,
  changesRequestedProject,
  submitProject,
  deliveredProjects,
  deliveredAllProjects
} from "../../apis/ProjectApis";
import {
  ACCEPTED,
  ASSIGNED,
  ADJUSTMENTS_REQUESTED,
  DASHBOARD_CARD_COLOR,
  IN_PROGRESS,
  MAKE_CHANGES,
  QUALITY_CHECK,
  FINAL_CHECK,
  SUBMITTED,
  ASSIGN,
  USER_TYPE_ADMIN,
  USER_TYPE_MANAGER,
  USER_TYPE_QUALITY_CHECKER,
  USER_TYPE_CONTRACTOR,
  compareEta,
  DELIVERED,
  conditionForProjectToFinalCheckForQualityCheck,
  conditionToReturnToFinalCheck,
  conditionToAssignProjectCoodinator,
  conditionToProjectAssignToContractor,
  conditionToProjectReAssignToContractor,
  conditionToProjectAssignToQualityChecker,
  conditionToProjectFinalCheckForCustomerReview,
  conditionToProjectForMakeChanges,
  conditionToProjectForAccept,
  conditionToProjectForRequestChanges,
  conditionToProjectForDelivered,
  conditionToForDeliveredAll,
  etaDateDisplay,
  conditionToETAProject,
  USER_TYPE_OWNER,
  PROJECT_IDS
} from "../../utils/constants";
import { Tag } from "primereact/tag";
import "./style.scss";
import { Popover, Spin } from "antd";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import AssignToContractorDialog from "../project/dialog/assigntocontractordialog";
import AssigntoqualityCheckerDialog from "../project/dialog/assigntoqualitycheckerdialog";
import { getAllTilesCount, getTileData, exportAllProject } from "../../apis/DashboardApi";
import AssignToManagerDialog from "../project/dialog/assigntomanagerdialog";
import CustomerRequestChangeDialog from "../project/dialog/CustomerRequestChangesDialog";
import { downloadedFile } from "../../functions/functions";
import FeedbackDialog from "../project/dialog/FeedbackDialog";

const Dashboard = () => {
  const [allTilesCount, setAllTilesCount] = useState([]);
  const [tableData, setTableData] = useState();
  const [tileName, setTileName] = useState("critical");
  const [tableHeader, setTableHeader] = useState("Critical Projects");
  const [apiLoading, setApiLoading] = useState(false);
  const [visibleContractorDialog, setVisibleContractorDialog] = useState(false);
  const [visibleQualityCheckerDialog, setVisibleQualityCheckerDialog] = useState(false);
  const [visibleCustomerRequestChangeDialog, setVisibleCustomerRequestChangeDialog] = useState(false);
  const [visibleFeedbackDialog, setVisibleFeedbackDialog] = useState(false);
  const [visibleManagerDialog, setVisibleManagerDialog] = useState(false);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [project, setProject] = useState(null);
  const toast = useRef(null);
  const history = useHistory();
  const [permissions, setPermissions] = useState([]);
  const [showDeliveredAll, setShowDeliveredAll] = useState(false);

  const fetchTileData = (tileName) => {
    setApiLoading(true);
    getTileData({
      tileName: tileName,
    })
      .then((response) => {
        if (response.status === 200) {
          setTableData(response.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: response.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  const changeVisibleContractorDialogState = (bool) => {
    setApiLoading(false);
    fetchAllTilesCount(tileName);
    setProject(null);
    setVisibleContractorDialog(bool);
  };

  const changeVisibleQualityCheckerDialogState = (bool) => {
    setApiLoading(false);
    fetchTileData(tileName);
    setProject(null);
    setVisibleQualityCheckerDialog(bool);
  };

  const changeVisibleManagerDialogState = (bool) => {
    setApiLoading(false);
    fetchTileData(tileName);
    setProject(null);
    setVisibleManagerDialog(bool);
  };

  const fetchAllTilesCount = (tileName) => {
    setApiLoading(true);
    getAllTilesCount()
      .then((response) => {
        if (response.status === 200) {
          setAllTilesCount(response.data);
          fetchTileData(tileName);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: response.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  useEffect(() => {
    if (currentUser?.id) {
      fetchAllTilesCount(tileName);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleManagerAcceptProject = (record, tileName) => {
    if (record && record.id) {
      setApiLoading(true);
      managerAcceptProject(record.id)
        .then(async (res) => {
          if (res.status === 200) {
            fetchAllTilesCount(tileName);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Assigned Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleDeliveredAllProjects = () => {
    setApiLoading(true)
    deliveredAllProjects()
      .then(async (res) => {
        if (res.status === 200) {
          fetchAllTilesCount("accepted")
          toast.current.show({
            severity: "success",
            summary: "Status",
            detail: "Project Delivered Successfully",
            life: 3000,
          });
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  const handleFinalCheckProject = (record, tileName, isAssignToMe) => {
    if (record && record.id) {
      setApiLoading(true);
      finalCheckProject(record.id, isAssignToMe)
        .then((res) => {
          if (res.status === 200) {
            fetchAllTilesCount(tileName);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Final Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleMakeChangesProject = (record, tileName) => {
    makeChangesProject(record.id)
      .then((res) => {
        if (res.status === 200) {
          fetchAllTilesCount(tileName);
          toast.current.show({
            severity: "success",
            summary: "Status",
            detail: "Project Status Change To Make Changes Successfully",
            life: 3000,
          });
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  const customerAcceptProject = (record, tileName, isAssignToMe) => {
    if (record && record.id) {
      setApiLoading(true);
      acceptedProject(record.id, isAssignToMe)
        .then((res) => {
          if (res.status === 200) {
            fetchAllTilesCount(tileName);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Accepted Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const customerChangesRequestedProject = (record, tileName) => {
    if (record && record.id) {
      setApiLoading(true);
      changesRequestedProject(record.id)
        .then((res) => {
          if (res.status === 200) {
            fetchAllTilesCount(tileName);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail:
                "Project Status Change To Adjustments Requested Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleSubmitProject = (project, tileName) => {
    if (project && project.id) {
      setApiLoading(true);
      submitProject(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchAllTilesCount(tileName);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Submitted For Quality Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const managerDeliverProject = (project, tileName) => {
    if (project && project.id) {
      setApiLoading(true);
      deliveredProjects(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchAllTilesCount(tileName);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Submitted For Quality Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  function IsSafari() {
    console.log(navigator.userAgent);
    var is_safari = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
    return is_safari;
  
  }

  const exportAllProjectData = async (tileName) => {
    try {
      setApiLoading(true);
      const batchSize = 5; // Number of files to download per batch
      const totalBatches = Math.ceil(allTilesCount[tileName] / batchSize);
  
      const packInfos = [];
      for (let i = 0; i < totalBatches; i++) {
        const startIdx = i * batchSize;
        const endIdx = Math.min(startIdx + batchSize, allTilesCount[tileName]);
        packInfos.push({ startIdx: startIdx, endIdx: endIdx, packId: i });
      }

      const exportPromises = packInfos.map(packInfo => exportAllProject(tileName, packInfo.startIdx, packInfo.endIdx, packInfo.packId));
      const responses = await Promise.all(exportPromises);
      for (let res of responses) {
        if (res.status === 200) {
          downloadedFile(res, decodeURIComponent(res.headers.filename));
          toast.current.show({
            severity: "success",
            summary: "Status",
            detail: `Project ${tileName} Exported Successfully`,
            life: 3000,
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: "Error creating zip for export project",
            life: 3000,
          });
        }
        if (window.safari !== undefined) {
          await new Promise(resolve => { setTimeout(resolve, 2000) }); // Adjust the delay as needed
        }
      }

      setApiLoading(false);
      setShowDeliveredAll(true);
    } catch (error) {
      console.error("Error exporting projects:", error);
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: error.message || "An error occurred while exporting projects",
        life: 3000,
      });
      setApiLoading(false);
    }
  };
  

  const handleReturnToFinalCheck = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      finalCheckProject(record.id, false)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Final Check Successfully",
              life: 3000,
            });
            fetchAllTilesCount(tileName);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const changeVisibleCustomerRequestChangeDialogState = (bool) => {
    setApiLoading(false);
    fetchAllTilesCount(tileName);
    setProject(null);
    setVisibleCustomerRequestChangeDialog(bool);
  };

  const changeVisibleFeedbackDialogState = (bool) => {
    setApiLoading(false);
    fetchAllTilesCount(tileName);
    setVisibleFeedbackDialog(bool);
    if (project.projectStatus === FINAL_CHECK) {
      if (project.needUpdateAdjustment) {
        setApiLoading(true);
        setProject(project);
        setVisibleCustomerRequestChangeDialog(true);
      } else {
        if (project.manager == null) {
          confirmDialog({
            message: "Do you want to assign yourself to Project Final Checker?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => customerAcceptProject(project, tileName, true),
            reject: () => customerAcceptProject(project, tileName, false),
          });
        } else {
          confirmDialog({
            message: "Are you sure you want to change status to Accept?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => customerAcceptProject(project, tileName, false),
          });
        }
        setProject(null);
      }
    } else {
      setProject(null);
    }
  };

  const content = (record) => {
    return (
      <React.Fragment>
        <div className="flex flex-column align-items-start gap-2">
          <div>
            <Button
              style={{ width: "140px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="View Details"
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: `${record.id}/projectEdit`,
                  state: {
                    id: record.id,
                  },
                });
              }}
            />
          </div>

          {conditionForProjectToFinalCheckForQualityCheck(
            record,
            permissions
          ) &&
          (tileName === "makeChanges" || tileName === "inProgress") ? (
            <div>
              <Button
                style={{ width: "120px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Ready for Quality Check"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDialog({
                    message:
                      "Are you sure you want to submit the project for Quality Check?",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => handleSubmitProject(record, tileName),
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToAssignProjectCoodinator(record, permissions) &&
          tileName !== "tomorrow" &&
          tileName !== "critical" &&
          tileName !== "new" ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label={
                  project?.manager?.id
                    ? "Re-Assign Project Final Checker"
                    : "Assign Project Final Checker"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setProject(record);
                  setApiLoading(true);
                  setVisibleManagerDialog(true);
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectAssignToContractor(record, permissions) &&
          tileName === "assigned" ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Assign to Contractor"
                onClick={(e) => {
                  e.stopPropagation();
                  setProject(record);
                  setApiLoading(true);
                  setVisibleContractorDialog(true);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {conditionToProjectReAssignToContractor(record, permissions) &&
          tileName !== "tomorrow" &&
          tileName !== "critical" &&
          tileName !== "assigned" ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Re-Assign Contractor"
                onClick={(e) => {
                  e.stopPropagation();
                  setProject(record);
                  setApiLoading(true);
                  setVisibleContractorDialog(true);
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectAssignToQualityChecker(record, permissions) &&
          tileName !== "critical" &&
          tileName !== "tomorrow" &&
          tileName !== "new" ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label={
                  project?.qualityChecker?.id
                    ? "Re-Assign Quality Checker"
                    : "Assign Quality Checker"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  setProject(record);
                  setApiLoading(true);
                  setVisibleQualityCheckerDialog(true);
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectFinalCheckForCustomerReview(record, permissions) &&
          (tileName === "qualityCheck" || tileName === "changesRequested") ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Ready for Final Check"
                onClick={(e) => {
                  e.stopPropagation();
                  if(record?.projectStatus === QUALITY_CHECK){
                    setProject(record);
                    setVisibleFeedbackDialog(true);
                  }else{
                    if(record.qualityChecker == null && record.employee.id != currentUser.id){
                      confirmDialog({
                        message: "Do you want to assign yourself to Quality Checker?",
                        header: "Confirmation",
                        icon: "pi pi-exclamation-triangle",
                        accept: () => handleFinalCheckProject(record, tileName, true),
                        reject: () => handleFinalCheckProject(record, tileName, false),
                      });
                    }else{
                      confirmDialog({
                        message: "Are you sure you want to proceed?",
                        header: "Confirmation",
                        icon: "pi pi-exclamation-triangle",
                        accept: () => handleFinalCheckProject(record, tileName, false),
                      });                  
                    }
                  }
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectForMakeChanges(record, permissions) &&
          (tileName === "qualityCheck" || tileName === "changesRequested") ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Make Changes"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDialog({
                    message: "Are you sure you want to proceed?",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => handleMakeChangesProject(record, tileName),
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectForAccept(record, permissions) &&
          tileName === "finalCheck" ? (
            <div>
              <Button
                style={{ width: "120px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Accept"
                onClick={(e) => {
                  e.stopPropagation();
                  setProject(record);
                  setVisibleFeedbackDialog(true);
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectForRequestChanges(record, permissions) &&
          tileName === "finalCheck" ? (
            <div>
              <Button
                style={{ width: "120px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Request Changes"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDialog({
                    message: "Are you sure you want to proceed?",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () =>
                      customerChangesRequestedProject(record, tileName),
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToReturnToFinalCheck(
            record,
            permissions
          ) ? (
            <div>
              <Button
                style={{ width: "140px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Return to final check"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDialog({
                    message: "Are you sure you want to proceed?",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => handleReturnToFinalCheck(record),
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}

          {conditionToProjectForDelivered(record, permissions) &&
          tileName === "accepted" ? (
            <div>
              <Button
                style={{ width: "120px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Delivered"
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDialog({
                    message: "Are you sure you want to proceed?",
                    header: "Confirmation",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => managerDeliverProject(record, tileName),
                  });
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Spin spinning={apiLoading}>
          <div className="grid dashboard">
            <div
              className="col-12 lg:col-6 xl:col-3 custom-grab"
              onClick={() => {
                setTableHeader("ETA Delivery Projects");
                setTileName("critical");
                fetchTileData("critical");
              }}
            >
              <div
                className="card mb-0 p-3"
                style={{
                  backgroundColor:
                    tileName === "critical" ? DASHBOARD_CARD_COLOR : "",
                }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-color text-500 font-medium mb-3">
                      ETA today
                    </span>
                    <div className="text-900 font-medium text-xl">
                      {allTilesCount["critical"]
                        ? allTilesCount["critical"]
                        : "None"}
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-pink-500 border-round"
                    style={{ width: "4rem", height: "4rem" }}
                  >
                    <i className="pi pi-exclamation-triangle text-white text-4xl" />
                  </div>
                </div>
              </div>
            </div>

            {conditionToETAProject(permissions) ? (
              <React.Fragment>
                <div
                  className="col-12 lg:col-6 xl:col-3 custom-grab"
                  onClick={() => {
                    setTableHeader("ETA + 1 Delivery Projects");
                    setTileName("tomorrow");
                    fetchTileData("tomorrow");
                  }}
                >
                  <div
                    className="card mb-0 p-3"
                    style={{
                      backgroundColor:
                        tileName === "tomorrow" ? DASHBOARD_CARD_COLOR : "",
                    }}
                  >
                    <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-color text-500 font-medium mb-3">
                        ETA + 1
                      </span>
                      <div className="text-900 font-medium text-xl">
                        {allTilesCount["tomorrow"]
                          ? allTilesCount["tomorrow"]
                          : "None"}
                      </div>
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-orange-500 border-round"
                      style={{ width: "4rem", height: "4rem" }}
                    >
                      <i className="pi pi-clock text-white text-4xl" />
                    </div>
                  </div>
                </div>
              </div>

                <div
                  className="col-12 lg:col-6 xl:col-3 custom-grab"
                  onClick={() => {
                    setTableHeader("ETA+2 Delivery Projects");
                    setTileName("ETA+2");
                    fetchTileData("ETA+2");
                  }}
                >
                  <div
                    className="card mb-0 p-3"
                    style={{
                      backgroundColor:
                        tileName === "ETA+2" ? DASHBOARD_CARD_COLOR : "",
                    }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-color text-500 font-medium mb-3">
                          ETA + 2
                        </span>
                        <div className="text-900 font-medium text-xl">
                          {allTilesCount["ETA+2"]
                            ? allTilesCount["ETA+2"]
                            : "None"}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center bg-orange-500 border-round"
                        style={{ width: "4rem", height: "4rem" }}
                      >
                        <i className="pi pi-clock text-white text-4xl" />
                      </div>
                    </div>
                  </div>
                </div>


                <div
                  className="col-12 lg:col-6 xl:col-3 custom-grab"
                  onClick={() => {
                    setTableHeader("ETA+3 Delivery Projects");
                    setTileName("ETA+3");
                    fetchTileData("ETA+3");
                  }}
                >
                  <div
                    className="card mb-0 p-3"
                    style={{
                      backgroundColor:
                        tileName === "ETA+3" ? DASHBOARD_CARD_COLOR : "",
                    }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-color text-500 font-medium mb-3">
                          ETA + 3
                        </span>
                        <div className="text-900 font-medium text-xl">
                          {allTilesCount["ETA+3"]
                            ? allTilesCount["ETA+3"]
                            : "None"}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center bg-orange-500 border-round"
                        style={{ width: "4rem", height: "4rem" }}
                      >
                        <i className="pi pi-clock text-white text-4xl" />
                      </div>
                    </div>
                  </div>
                </div>      
                </React.Fragment>
                            ) : (
              ""
            )}
                  
            <div
              className="col-12 lg:col-6 xl:col-3 custom-grab"
              onClick={() => {
                setTableHeader("Make Changes Projects");
                setTileName("makeChanges");
                fetchTileData("makeChanges");
              }}
            >
              <div
                className="card mb-0 p-3"
                style={{
                  backgroundColor:
                    tileName === "makeChanges" ? DASHBOARD_CARD_COLOR : "",
                }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-color text-500 font-medium mb-3">
                      Make Changes
                    </span>
                    <div className="text-900 font-medium text-xl">
                      {allTilesCount["makeChanges"]
                        ? allTilesCount["makeChanges"]
                        : "None"}
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center surface-600 border-round"
                    style={{ width: "4rem", height: "4rem" }}
                  >
                    <i className="pi pi-undo text-white text-4xl" />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-12 lg:col-6 xl:col-3 custom-grab"
              onClick={() => {
                setTableHeader("Final Check Projects");
                setTileName("finalCheck");
                fetchTileData("finalCheck");
              }}
            >
              <div
                className="card mb-0 p-3"
                style={{
                  backgroundColor:
                    tileName === "finalCheck" ? DASHBOARD_CARD_COLOR : "",
                }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-color text-500 font-medium mb-3">
                      Final Check
                    </span>
                    <div className="text-900 font-medium text-xl">
                      {allTilesCount["finalCheck"]
                        ? allTilesCount["finalCheck"]
                        : "None"}
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-yellow-600 border-round"
                    style={{ width: "4rem", height: "4rem" }}
                  >
                    <i className="pi pi-thumbs-up text-white text-4xl" />
                  </div>
                </div>
              </div>
            </div>
            {currentUser?.userType === USER_TYPE_MANAGER ||
            currentUser?.userType === USER_TYPE_ADMIN || currentUser?.userType === USER_TYPE_OWNER ? (
              <div
                className="col-12 lg:col-6 xl:col-3 custom-grab"
                onClick={() => {
                  setTableHeader("New Projects");
                  setTileName("new");
                  fetchTileData("new");
                }}
              >
                <div
                  className="card mb-0 p-3"
                  style={{
                    backgroundColor:
                      tileName === "new" ? DASHBOARD_CARD_COLOR : "",
                  }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-color text-500 font-medium mb-3">
                        New
                      </span>
                      <div className="text-900 font-medium text-xl">
                        {allTilesCount["new"] ? allTilesCount["new"] : "None"}
                      </div>
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-green-500 border-round"
                      style={{ width: "4rem", height: "4rem" }}
                    >
                      <i className="pi pi-star text-white text-4xl" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div
              className="col-12 lg:col-6 xl:col-3 custom-grab"
              onClick={() => {
                setTableHeader("In Progress Projects");
                setTileName("inProgress");
                fetchTileData("inProgress");
              }}
            >
              <div
                className="card mb-0 p-3"
                style={{
                  backgroundColor:
                    tileName === "inProgress" ? DASHBOARD_CARD_COLOR : "",
                }}
              >
                <div className="flex justify-content-between mb-3">
                  <div>
                    <span className="block text-color text-500 font-medium mb-3">
                      In Progress
                    </span>
                    <div className="text-900 font-medium text-xl">
                      {allTilesCount["inProgress"]
                        ? allTilesCount["inProgress"]
                        : "None"}
                    </div>
                  </div>
                  <div
                    className="flex align-items-center justify-content-center bg-blue-300 border-round"
                    style={{ width: "4rem", height: "4rem" }}
                  >
                    <i className="pi pi-eye text-white text-4xl" />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "contents" }}>
              <div
                className="col-12 lg:col-6 xl:col-3 custom-grab"
                onClick={() => {
                  setTableHeader("Quality Check Projects");
                  setTileName("qualityCheck");
                  fetchTileData("qualityCheck");
                }}
              >
                <div
                  className="card mb-0 p-3"
                  style={{
                    backgroundColor:
                      tileName === "qualityCheck" ? DASHBOARD_CARD_COLOR : "",
                  }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-color text-500 font-medium mb-3">
                        Quality Check
                      </span>
                      <div className="text-900 font-medium text-xl">
                        {allTilesCount["qualityCheck"]
                          ? allTilesCount["qualityCheck"]
                          : "None"}
                      </div>
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-purple-500 border-round"
                      style={{ width: "4rem", height: "4rem" }}
                    >
                      <i className="pi pi-flag text-white text-4xl" />
                    </div>
                  </div>
                </div>
              </div>

              <React.Fragment>
                <div
                  className="col-12 lg:col-6 xl:col-3 custom-grab"
                  onClick={() => {
                    setTableHeader("Assigned Projects");
                    setTileName("assigned");
                    fetchTileData("assigned");
                  }}
                >
                  <div
                    className="card mb-0 p-3"
                    style={{
                      backgroundColor:
                        tileName === "assigned" ? DASHBOARD_CARD_COLOR : "",
                    }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-color text-500 font-medium mb-3">
                          Assigned to me
                        </span>
                        <div className="text-900 font-medium text-xl">
                          {allTilesCount["assigned"]
                            ? allTilesCount["assigned"]
                            : "None"}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center bg-indigo-500 border-round"
                        style={{ width: "4rem", height: "4rem" }}
                      >
                        <i className="pi pi-user-plus text-white text-4xl" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 lg:col-6 xl:col-3 custom-grab"
                  onClick={() => {
                    setTableHeader("Adjustments Requested");
                    setTileName("changesRequested");
                    fetchTileData("changesRequested");
                  }}
                >
                  <div
                    className="card mb-0 p-3"
                    style={{
                      backgroundColor:
                        tileName === "changesRequested"
                          ? DASHBOARD_CARD_COLOR
                          : "",
                    }}
                  >
                    <div className="flex justify-content-between mb-3">
                      <div>
                        <span className="block text-color text-500 font-medium mb-3">
                          Customer adjust request
                        </span>
                        <div className="text-900 font-medium text-xl">
                          {allTilesCount["changesRequested"]
                            ? allTilesCount["changesRequested"]
                            : "None"}
                        </div>
                      </div>
                      <div
                        className="flex align-items-center justify-content-center bg-teal-500 border-round"
                        style={{ width: "4rem", height: "4rem" }}
                      >
                        <i className="pi pi-user-edit text-white text-4xl" />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>

              <div
                className="col-12 lg:col-6 xl:col-3 custom-grab"
                onClick={() => {
                  setTableHeader("Accepted Projects");
                  setTileName("accepted");
                  fetchTileData("accepted");
                }}
              >
                <div
                  className="card mb-0 p-3"
                  style={{
                    backgroundColor:
                      tileName === "accepted" ? DASHBOARD_CARD_COLOR : "",
                  }}
                >
                  <div className="flex justify-content-between mb-3">
                    <div>
                      <span className="block text-color text-500 font-medium mb-3">
                        Accepted
                      </span>
                      <div className="text-900 font-medium text-xl">
                        {allTilesCount["accepted"]
                          ? allTilesCount["accepted"]
                          : "None"}
                      </div>
                    </div>
                    <div
                      className="flex align-items-center justify-content-center bg-cyan-600 border-round"
                      style={{ width: "4rem", height: "4rem" }}
                    >
                      <i className="pi pi-check-circle text-white text-4xl" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 xl:col-12">
            <div className="card">
              <DataTable
                value={tableData}
                rows={30}
                paginator
                responsiveLayout="scroll"
                header={
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p>{tableHeader}</p>
                    {tileName === "accepted" && allTilesCount["accepted"]  ? (
                      <div style={{ display: "flex"}}>
                        {
                          conditionToForDeliveredAll(permissions) && showDeliveredAll ?
                          <Button
                          style={{ backgroundColor: "#037eea", marginRight: "10px" }}
                          icon="pi pi-file"
                          label="Delivered All"
                          onClick={(e) => {
                            e.stopPropagation();
                            confirmDialog({
                              message: "Are you sure you want to proceed?",
                              header: "Confirmation",
                              icon: "pi pi-exclamation-triangle",
                              accept: () => handleDeliveredAllProjects(),
                            });
                          }}
                        />: ("")
                        }
                        <Button
                          style={{ backgroundColor: "#037eea" }}
                          icon="pi pi-file"
                          label="Export All"
                          onClick={(e) => {
                            e.stopPropagation();
                            exportAllProjectData("accepted");
                          }}
                        />
                      </div>
                    ) : ""}
                  </div>
                }
                selectionMode="single"
                onRowClick={(e) => {      
                  window.localStorage.setItem(PROJECT_IDS, tableData.map(t => t.id));
                  history.push({
                    pathname: `${e.data.id}/projectEdit`,
                    state: {
                      id: e.data.id,
                      agencyFormat: e.data.agency.agencyFormat,
                    },
                  });
                }}
              >
                <Column field="projectId" header={<span>ID</span>} />
                <Column
                  field="street"
                  header="Project Adress"
                  body={(record) => {
                    return (
                      <React.Fragment>
                        {record &&
                        (record.street != null ||
                          record.number != null ||
                          record.city != null)
                          ? record.street +
                            " " +
                            record.number +
                            " " +
                            record.city
                          : ""}
                      </React.Fragment>
                    );
                  }}
                />
                <Column
                header="Rework Type"
                body={(record) => {
                  if (record?.parentId != null)
                    return (
                      <React.Fragment>
                        Rework
                      </React.Fragment>
                    );
                  else if (record?.reworkProjects?.length > 0)
                    return <React.Fragment>
                      Origin
                    </React.Fragment>
                  else
                    return <React.Fragment>
                    </React.Fragment>
                }}
              ></Column>
                <Column
                  // field="importStatus"
                  header="Import Status"
                  body={(record) => {
                    if (
                      record?.import2d === 0 &&
                      record?.import3d === 0 &&
                      record?.importBackground === 0
                    )
                      return <React.Fragment></React.Fragment>;
                    else if (
                      record?.import2d === 1 ||
                      record?.import3d === 1 ||
                      record?.importBackground === 1
                    )
                      return (
                        <React.Fragment>
                        <img width={25}
                          src={inProgress}
                          alt="In Progress"
                        />
                      </React.Fragment>
                      );
                    else if (
                      record?.import2d === 2 &&
                      record?.import3d === 2 &&
                      record?.importBackground === 2
                    )
                      return (
                        <React.Fragment>
                        <img width={25}
                          src={ready}
                          alt="Ready"
                        />
                      </React.Fragment>
                      );
                  }}
                ></Column>
                <Column
                  field="customer"
                  header="Owner"
                  body={(record) => {
                    return (
                      <React.Fragment>{record.customer?.name}</React.Fragment>
                    );
                  }}
                />
                <Column
                  field="manager"
                  header="Manager"
                  body={(record) => {
                    return (
                      <React.Fragment>{record.manager?.name}</React.Fragment>
                    );
                  }}
                />
                <Column
                  field="companyName"
                  header="Customer"
                  body={(record) => {
                    return (
                      <React.Fragment>{record.company?.name}</React.Fragment>
                    );
                  }}
                />
                <Column
                  field="employee"
                  header="Contractor"
                  body={(record) => {
                    return (
                      <React.Fragment>{record.employee?.name}</React.Fragment>
                    );
                  }}
                />
                <Column
                  field="qualityChecker"
                  header="Quality Checker"
                  body={(record) => {
                    return (
                      <React.Fragment>
                        {record?.qualityChecker?.name}
                      </React.Fragment>
                    );
                  }}
                />
                <Column
                  field="houseType"
                  header="House Type"
                  body={(record) => {
                    return <React.Fragment>{record?.houseType}</React.Fragment>;
                  }}
                />
                <Column
                  field="ndd"
                  header="NDD"
                  body={(record) => {
                    return record?.ndd === "Yes" ? (
                      <React.Fragment>
                        <div style={{ border: "2px solid red" }}>
                          <b>{record?.ndd}</b>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>{record?.ndd}</React.Fragment>
                    );
                  }}
                />
                <Column
                  field="projectStatus"
                  header="Status"
                  body={(record) => {
                    switch (record?.projectStatus) {
                      case SUBMITTED:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="info"
                              value="Submitted"
                            ></Tag>
                          </React.Fragment>
                        );
                      case IN_PROGRESS:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              value="In Progress"
                            ></Tag>
                          </React.Fragment>
                        );
                      case FINAL_CHECK:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{
                                width: "125px",
                                backgroundColor: "#C79805",
                              }}
                              className="mr-2 text-lg"
                              value="Final Check"
                            ></Tag>
                          </React.Fragment>
                        );
                      case MAKE_CHANGES:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="danger"
                              value="Make Changes"
                            ></Tag>
                          </React.Fragment>
                        );
                      case ACCEPTED:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{
                                width: "125px",
                                backgroundColor: "#059BB4",
                              }}
                              className="mr-2 text-lg"
                              severity="success"
                              value="Accepted"
                            ></Tag>
                          </React.Fragment>
                        );
                      case QUALITY_CHECK:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="warning"
                              value="Quality Check"
                            ></Tag>
                          </React.Fragment>
                        );
                      case ASSIGNED:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="info"
                              value="Assigned"
                            ></Tag>
                          </React.Fragment>
                        );

                        case ASSIGN:
                          return (
                            <React.Fragment>
                              <Tag
                                style={{ width: "125px" }}
                                className="mr-2 text-lg"
                                severity="info"
                                value="Assign"
                              ></Tag>
                            </React.Fragment>
                          );
                      case ADJUSTMENTS_REQUESTED:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="danger"
                              value="Adjustments Requested"
                            ></Tag>
                          </React.Fragment>
                        );
                      case DELIVERED:
                        return (
                          <React.Fragment>
                            <Tag
                              style={{ width: "125px" }}
                              className="mr-2 text-lg"
                              severity="success"
                              value="Delivered"
                            ></Tag>
                          </React.Fragment>
                        );
                      default:
                        break;
                    }
                  }}
                />
                <Column field="crETAionDate" header="Creation Date" />
                <Column
                  field="eta"
                  header="ETA "
                  body={(record) => {
                    return compareEta(
                      record,
                      etaDateDisplay(currentUser, record)
                    );
                  }}
                />
                <Column
                  field="action"
                  header="Action"
                  body={(record) => {
                    return (
                      <React.Fragment>
                        <div className="flex justify-content-center">
                          <div>
                            <Popover
                              placement="leftBottom"
                              content={() => content(record)}
                            >
                              <Button
                                type="button"
                                icon="pi pi-ellipsis-v"
                                aria-haspopup
                                aria-controls="overlay_panel"
                                className="select-product-button"
                                style={{ backgroundColor: "#037eea" }}
                              />
                            </Popover>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </Spin>
        <AssignToContractorDialog
          visible={visibleContractorDialog}
          setVisible={changeVisibleContractorDialogState}
          project={project}
        />

        <AssigntoqualityCheckerDialog
          visible={visibleQualityCheckerDialog}
          setVisible={changeVisibleQualityCheckerDialogState}
          project={project}
        />

        <AssignToManagerDialog
          visible={visibleManagerDialog}
          setVisible={changeVisibleManagerDialogState}
          project={project}
        />
          <CustomerRequestChangeDialog
            visible={visibleCustomerRequestChangeDialog}
            setVisible={changeVisibleCustomerRequestChangeDialogState}
            project={project}
        />

      <FeedbackDialog
        visible={visibleFeedbackDialog}
        setVisible={changeVisibleFeedbackDialogState}
        project={project}
        currentUser={currentUser}
      />
      </div>
    </React.Fragment>
  );
};
export default Dashboard;
