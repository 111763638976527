/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Upload, Tooltip } from "antd";
import { Avatar } from "primereact/avatar";
import { InputTextarea } from "primereact/inputtextarea";
import Linkify from "react-linkify";
import moment from "moment";
import {
    addProjectComment,
    downloadAttachments,
    deleteProjectComment
} from "../../../../apis/projectCommentApis";
import {
    checkUserRole,
    conditionDeleteComment
} from "../../../../utils/constants";
import { downloadedFile } from "../../../../functions/functions";
import { confirmDialog } from "primereact/confirmdialog";

const CommentComponent = (props) => {
    const [commentsImagesMap, setCommentsImagesMap] = useState(new Map());
    const [commentValue, setCommentValue] = useState("");
    const [attachmentfileList, setAttachmentFileList] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);

    const handleDeleteComment = (id) => {
        setApiLoading(true);
        deleteProjectComment(id)
            .then((res) => {
                console.log(JSON.stringify(res.status))
                if(res.status === 200){
                    //todo reload comment
                    props.fetchProject();
                    props.toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Delete project comment successfully",
                        life: 3000,
                    });
                    setApiLoading(false);
                }else{
                    props.toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: "Delete project comment fail",
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                props.toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const handleDownloadAttachment = (name) => {
        const newMap = new Map(commentsImagesMap);
        if (props.project && props.project.id) {
            setApiLoading(true);
            let newValues = {
                fileName: name,
                projectId: `${props.project.id}`,
            };
            downloadAttachments(newValues)
                .then((res) => {
                    if (res.status === 200) {
                        if (name.endsWith(".zip")) downloadedFile(res, name);
                        else {
                            newMap.set(name, res.data);
                            setCommentsImagesMap(newMap);
                        }
                        setApiLoading(false);
                    } else {
                        props.toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: res.response.data,
                            life: 3000,
                        });
                        setApiLoading(false);
                    }
                })
                .catch((e) => {
                    props.toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });
        } else {
            props.toast.current.show({
                severity: "warn",
                summary: "Status",
                detail: "No Selected Project",
                life: 3000,
            });
        }
    };

    const handleCommentSave = () => {
        if (
            (commentValue && commentValue.length > 0) ||
            (attachmentfileList && attachmentfileList.length > 0)
        ) {
            setApiLoading(true);
            let newVales = {
                comment: commentValue,
                feedback: props.feedback,
                project: {
                    id: props.project.id,
                },
            };
            let formData = new FormData();
            formData.append(
                "file",
                attachmentfileList[0] ? attachmentfileList[0] : null
            );
            formData.append("commentData", JSON.stringify(newVales));
            addProjectComment(formData)
                .then((res) => {
                    if (res.status === 200) {
                        setCommentValue("");
                        setAttachmentFileList([]);
                        props.fetchProject();
                        setApiLoading(false);
                    } else {
                        props.toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: res.response.data,
                            life: 3000,
                        });
                        setApiLoading(false);
                    }
                })
                .catch((e) => {
                    props.toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });
        } else {
            props.toast.current.show({
                severity: "warn",
                summary: "Status",
                detail: "Cannot Send a Empty Comment",
                life: 3000,
            });
        }
    };

    return (
        <div>
            <div className="col-24">
                {/* User comments start here */}

                <div className="flex flex-column">
                    <div
                        className="scrollbar scrollbar-pink bordered-pink thin force-overflow"
                        style={{
                            height: props.height,
                            overflowX: "hidden",
                            overflowY: "revert",
                            marginBottom: "2rem",
                            paddingLeft: "1px",
                            paddingRight: "7px",
                        }}
                    >
                        {props.projectComments.map((comment) => (
                            <React.Fragment>
                                <div className="card p-fluid mt-2 mb-2">
                                    <div className="field grid">
                                        <div
                                            className="col-20 md:col-18"
                                            style={{ width: "100%" }}
                                        >
                                            <div className="flex justify-content-between">
                                                <div className="flex">
                                                    <Avatar
                                                        label={comment?.createdBy?.name
                                                            ?.charAt(0)
                                                            .toUpperCase()}
                                                        className="mr-2"
                                                        size="large"
                                                        shape="circle"
                                                        aria-haspopup
                                                        aria-controls="overlay_panel"
                                                        style={{
                                                            backgroundColor: checkUserRole(
                                                                comment?.createdBy
                                                            )
                                                                ? checkUserRole(comment?.createdBy)
                                                                : "",
                                                            color: "#ffffff",
                                                        }}
                                                    />
                                                    <div className="ml-1">
                                                        <h6 className="font-bold m-0">
                                                            {comment && comment.createdBy
                                                                ? comment.createdBy.name
                                                                : ""}
                                                        </h6>
                                                        <small className="font-bold m-0">
                                                            {comment &&
                                                                comment.createdBy &&
                                                                comment.createdBy.userType
                                                                ? comment.createdBy.userType
                                                                : ""}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="flex gap-1">
                                                    {comment && comment.attachmentName ? (
                                                        <React.Fragment>
                                                            <div>
                                                                <Tooltip title="Download Attachment">
                                                                    <Button
                                                                        icon="pi pi-paperclip font-bold text-xl"
                                                                        className="p-button-rounded p-button-secondary p-button-text"
                                                                        aria-label="Upload"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            handleDownloadAttachment(
                                                                                comment.attachmentName
                                                                            );
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                                {commentsImagesMap.has(
                                                                    comment.attachmentName
                                                                ) ? (
                                                                    <Image
                                                                        preview={{ visible: false }}
                                                                        width={200}
                                                                        src={URL.createObjectURL(
                                                                            commentsImagesMap.get(
                                                                                comment.attachmentName
                                                                            )
                                                                        )}
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                                <div className="flex">
                                                    <small>
                                                        {comment && comment.createdBy ? (
                                                            <React.Fragment>
                                                                <div>
                                                                    {moment(
                                                                        comment.createdOn
                                                                    ).format("MM/DD/YYYY")}
                                                                    <br />
                                                                    {moment(
                                                                        comment.createdOn
                                                                    ).format("hh:mm a")}
                                                                </div>
                                                                <div>
                                                                    {conditionDeleteComment(props.permissions) ?
                                                                    <Button
                                                                    icon="pi pi-trash"
                                                                    className="p-button-rounded p-button-secondary p-button-text"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        confirmDialog({
                                                                            message: "Are you sure you want to remove this comment?",
                                                                            header: "Confirmation",
                                                                            icon: "pi pi-exclamation-triangle",
                                                                            accept: () => handleDeleteComment(comment.id),
                                                                        });
                                                                    }}
                                                                /> : ""}
                                                                </div>
                                                            </React.Fragment>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </small>
                                                </div>
                                            </div>
                                            <p
                                                className="ml-1 mr-1 mt-3"
                                                style={{ overflowWrap: "anywhere" }}
                                            >
                                                {comment && comment.comment ? (
                                                    <Linkify>{comment.comment}</Linkify>
                                                ) : (
                                                    ""
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
                    <div>
                        {/* User comments end here */}
                        <div
                            className=" p-fluid "
                            style={{
                                bottom: "0",
                                width: "100%",
                            }}
                        >
                            <div className="col-24 md:col-20 ">
                                <div className="buttonIn">
                                    <div className="flex gap-2">
                                        <div style={{ width: "100%" }}>
                                            <InputTextarea
                                                rows={3}
                                                className="scrollbar-auto"
                                                value={commentValue}
                                                onChange={(e) =>
                                                    setCommentValue(e.target.value)
                                                }
                                            />
                                        </div>
                                        <div className="flex flex-column gap-1">
                                            <div className="custom-file-upload">
                                                {attachmentfileList.length ? (
                                                    <Tooltip title="Clear Attachment">
                                                        <Button
                                                            icon="pi pi-undo"
                                                            className="p-button-rounded p-button-secondary"
                                                            aria-label="Clear"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setAttachmentFileList([]);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title="Attachment">
                                                        <Upload
                                                            accept=".jpeg, .jpg, .png, .zip"
                                                            showUploadList={false}
                                                            fileList={attachmentfileList}
                                                            // onChange={handleAttachmentChange}
                                                            disabled={
                                                                attachmentfileList.length
                                                                    ? true
                                                                    : false
                                                            }
                                                            beforeUpload={(e) => {
                                                                setAttachmentFileList([e]);
                                                            }}
                                                        >
                                                            <Button
                                                                icon="pi pi-paperclip"
                                                                className="p-button-rounded"
                                                                aria-label="Upload"
                                                                disabled={
                                                                    attachmentfileList.length
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        </Upload>
                                                    </Tooltip>
                                                )}
                                            </div>
                                            <div>
                                                <Tooltip title="Send">
                                                    <Button
                                                        className="p-button-rounded"
                                                        icon="pi pi-send"
                                                        onClick={() => handleCommentSave(false)}
                                                        disabled={
                                                            commentValue.length ||
                                                                attachmentfileList.length > 0
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommentComponent
