import React from "react";
import "./style.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import "primeflex/primeflex.css";
import { useDispatch } from "react-redux";
import { loginUser } from "./../../actions/user";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
// import RegisterForm from "../register/registerform";
import logo from "./../../resources/logo-login.png";

const LoginForm = (props) => {
  document.documentElement.style.fontSize = "13px";

  const dispatch = new useDispatch();
  // const [checkForRegisterForm, setCheckForRegisterForm] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const defaultValues = {
    username: "",
    password: "",
  };

  const { control, handleSubmit, reset } = useForm({ defaultValues });

  function handleLogin(values) {
    dispatch(loginUser(values, history, location, props));
  }

  const onSubmit = (data) => {
    handleLogin(data);
    reset();
  };

  const passwordHeader = <h6>Pick a password</h6>;

  // const registerBtn = () => {
  //   setCheckForRegisterForm(true);
  // };

  return (
    <React.Fragment>
      {/* {!checkForRegisterForm && ( */}
      <div
        className="flexgrid-demo p-dir-col custom-button-color"
        style={{ margin: "0" }}
      >
        <div className="p-col">
          <div className="box">
            <div className="flex align-items-center justify-content-center">
              <div
                className="surface-card p-4 shadow-2 border-round w-full lg:w-6"
                style={{ width: "35%" }}
              >
                <div className="text-center mb-2">
                  <img
                    src={logo}
                    alt="logo"
                    width={380}
                    // style={{ mar }}
                    className="mt-5 w-100"
                  />
                  <span className="text-600 font-medium line-height-3">
                    {/* Don't have an account? */}
                  </span>
                  <a
                    href
                    className="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
                    // onClick={registerBtn}
                  >
                    {/* Create Account! */}
                  </a>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-900 font-medium mb-2 custom-text-align"
                    >
                      Username*
                    </label>
                    <Controller
                      name="username"
                      control={control}
                      rules={{ required: "Username is required." }}
                      render={({ field, fieldState }) => (
                        <InputText
                          id={field.name}
                          {...field}
                          autoFocus
                          className="w-full mb-3"
                        />
                      )}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-900 font-medium mb-2 custom-text-align"
                    >
                      Password*
                    </label>
                    <Controller
                      name="password"
                      control={control}
                      rules={{ required: "Password is required." }}
                      render={({ field, fieldState }) => (
                        <div className="custom-password-field">
                          <Password
                            id={field.name}
                            {...field}
                            className="w-full mb-3"
                            toggleMask
                            header={passwordHeader}
                            feedback={false}
                          />
                        </div>
                      )}
                    />

                    <Button
                      type="submit"
                      label="Login"
                      icon="pi pi-user"
                      className="custom-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
      {/* {checkForRegisterForm && (
        <RegisterForm checkFalse={() => setCheckForRegisterForm(false)} />
      )} */}
    </React.Fragment>
  );
};
export default LoginForm;
