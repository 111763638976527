import { Form, Spin, Input, Select } from "antd";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import {
    updateUserInvoice,
} from "../../../apis/AdminApis";

const UpdateUserInvoiceDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        form.setFieldsValue({ ...props.userInvoice })
    });

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            values.id = props.userInvoice.id;
            values.company = { id: props.userInvoice.company.id };
            setApiLoading(true);
            updateUserInvoice(values).then((res) => {
                if (res.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Update User Invoice successfully",
                        life: 3000
                    })

                    setApiLoading(false);
                    props.setVisible(false);
                } else {
                    toast.current.show({
                        severity: "warn",
                        summary: "Status",
                        detail: "Update User Invoice failed!",
                        life: 3000
                    })
                    setApiLoading(false);
                    props.setVisible(false);
                }
            }).catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
        })
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                style={{ width: "480px" }}
                header={"Update User Invoice Config"}
                visible={props.visible}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item 
                                    name="role" label="Role"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "270px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item 
                                    name="product" label="Product"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}
                                >
                                    <Select style={{ width: 270 }} dropdownStyle={{ zIndex: "9999" }} placeholder="Select" allowClear = {true}>
                                        <Option value="BOG">BOG</Option>
                                        <Option value="House">House</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item 
                                    name="type" label="Type"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}
                                >
                                    <Select style={{ width: "270px" }} dropdownStyle={{ zIndex: "9999" }} placeholder="Select">
                                        <Option value="A">A</Option>
                                        <Option value="B">B</Option>
                                        <Option value="C">C</Option>
                                        <Option value="D">D</Option>
                                        <Option value="E">E</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item 
                                    name="ndd" label="Ndd"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Select style={{ width: 270 }} dropdownStyle={{ zIndex: "9999" }} placeholder="Select" allowClear={true}>
                                        <Option value="Yes">Yes</Option>
                                        <Option value="No">No</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item 
                                    name="isAutocad" label="Autocad"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Select style={{ width: 270 }} dropdownStyle={{ zIndex: "9999" }} placeholder="Select" allowClear={true}>
                                        <Option value="Yes">Yes</Option>
                                        <Option value="No">No</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item 
                                    name="price" label="Price"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "270px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>

            </Dialog>
        </React.Fragment>
    )
}

export default UpdateUserInvoiceDialog
