/**
 *
 *   APPLICATION CONSTANTS
 *
 */

import { Tag } from "primereact/tag";
import React from "react";
import moment from "moment";

export const APP_CONTEXT_URL = process.env.REACT_APP_APP_CONTEXT_URL;
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOAD_CURRENT_USER = "SET_CURRENT_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

/** CONTRIES Constants */
export const GET_CONTRIES = "GET_CONTRIES";
export const SET_CONTRY = "SET_CONTRY";

export const GET_ENTITIES = "GET_ENTITIES";
export const SET_ENTITY = "SET_ENTITY";
export const RESET_ENTITIES = "RESET_ENTITIES";

/** DATE Format Constants */
export const DATETIME_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const DATE_FORMAT = "YYYY-MM-DD";
export const TIME_FORMAT = "HH:mm:ss";

/** Regex Alphabatic Inputs */
export const ALPHABETICAL_INPUTS = "^[a-zA-Z ]+$";

//** Regex Numeric Inputs */
export const NUMERIC_INPUTS = "^[0-9]+$";
export const NORDOYMAINCOLOR = "#fbb618";

//** System Permissions */
export const VIEW_USER = "VIEW_USER";

export const ADD_USER = "ADD_USER";

export const UPDATE_USER = "UPDATE_USER";

export const DELETE_USER = "DELETE_USER";

export const VIEW_ROLE = "VIEW_ROLE";

export const ADD_ROLE = "ADD_ROLE";

export const UPDATE_ROLE = "UPDATE_ROLE";

export const DELETE_ROLE = "DELETE_ROLE";

export const VIEW_PERMISSION = "VIEW_PERMISSION";

export const ADD_PERMISSION = "ADD_PERMISSION";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

export const DELETE_PERMISSION = "DELETE_PERMISSION";

export const DASHBOARD_CARD_COLOR = "#dfe1e4";

export const VIEW_AGENCY = "VIEW_AGENCY";

export const VIEW_AGENCY_MENUITEM = "VIEW_AGENCY_MENUITEM";

export const ADD_AGENCY = "ADD_AGENCY";

export const EDIT_AGENCY = "EDIT_AGENCY";

export const DELETE_AGENCY = "DELETE_AGENCY";

export const NEW_PROJECT = "NEW_PROJECT";

export const DASHBOARD = "DASHBOARD";

export const GENERATES_INVOICE_MENUITEM = "GENERATES_INVOICE_MENUITEM";

export const GENERATES_PROJECTS_INVOICE = "GENERATES_PROJECTS_INVOICE";

export const GENERATES_USERS_INVOICE = "GENERATES_USERS_INVOICE";

export const SHOW_STATISTIC = "SHOW_STATISTIC";

export const VIEW_PROJECT = "VIEW_PROJECT";

export const EDIT_PROJECT = "EDIT_PROJECT";

export const ADD_COMPANY = "ADD_COMPANY";

export const RECONNECT_FP = "RECONNECT_FP"

export const ACTION_QUALITY_CHECK = "ACTION_QUALITY_CHECK"
export const ACTION_ASSIGN_PROJECT_COORDINATOR = "ACTION_ASSIGN_PROJECT_COORDINATOR"
export const ACTION_ASSIGN_CONTRACTOR = "ACTION_ASSIGN_CONTRACTOR"
export const ACTION_ASSIGN_QUALITY_CHECKER = "ACTION_ASSIGN_QUALITY_CHECKER"
export const ACTION_CUSTOMER_REVIEW = "ACTION_CUSTOMER_REVIEW"
export const ACTION_CUSTOMER_REQUEST_CHANGE = "ACTION_CUSTOMER_REQUEST_CHANGE"
export const ACTION_MAKE_CHANGE = "ACTION_MAKE_CHANGE"
export const ACTION_ACCEPT = "ACTION_ACCEPT"
export const ACTION_REQUEST_CHANGE = "ACTION_REQUEST_CHANGE"
export const ACTION_DELIVERED = "ACTION_DELIVERED"
export const FLOOR_PLANNER_API = "FLOOR_PLANNER_API"
export const SHOW_ALL_PROJECT = "SHOW_ALL_PROJECT"
export const SHOW_ASSIGN_PROJECT = "SHOW_ASSIGN_PROJECT"
export const SHOW_ALL_ETA = "SHOW_ALL_ETA"
export const COPY_PROJECT = "COPY_PROJECT"

export const USER_TYPE_OWNER = "Customer";

export const USER_TYPE_CONTRACTOR = "Contractor";

export const USER_TYPE_MANAGER = "Manager";

export const USER_TYPE_ADMIN = "Admin";

export const USER_TYPE_QUALITY_CHECKER = "Quality Checker";

export const WONING_MEDIA_ID = 0;

export const ADMIN_EDIT = "ADMIN_EDIT";

export const UPDATE_ADJUSTMENT = "UPDATE_ADJUSTMENT";

export const SHOW_REWORK_DETAIL = "SHOW_REWORK_DETAIL";

export const SHOW_CUSTOMER_DETAIL = "SHOW_CUSTOMER_DETAIL";

export const DELETE_FEEDBACK_COMMENT = "DELETE_FEEDBACK_COMMENT";

export const CALENDAR = "CALENDAR";

export const PAGE_3D_EDIT = "PAGE_3D_EDIT";

export const PROJECT_IDS = "PROJECT_IDS";

export const BACKGROUND_REPORT = "BACKGROUND_REPORT";

export const COMPANY_FILE = "COMPANY_FILE"

export const USERS_TYPE = [
  { id: "1", name: "Admin" },
  { id: "2", name: "Manager" },
  { id: "3", name: "Contractor" },
  { id: "4", name: "Customer" },
  { id: "5", name: "Quality Checker" },
];

export const VALID_ROLES = [
  USER_TYPE_OWNER,
  USER_TYPE_CONTRACTOR,
  USER_TYPE_MANAGER,
  USER_TYPE_ADMIN,
  USER_TYPE_QUALITY_CHECKER,
];

export const optionEmployeeFeedbacks = [
  { value: "GOOD", label: "1. Good" },
  { value: "M2_NOT_CORRECT", label: "2. M2 not correct" },
  { value: "H_LESS_THAN_150CM", label: "3. h<1,50m" },
  { value: "PARTS_MISSING", label: "4. Parts missing" },
  { value: "NEN_RULES_WRONG", label: "5. NEN rules wrong" }
];
export const optionManagerFeedbacks = [
  { value: "GOOD", label: "1. Good" },
  { value: "PICTURES_MISSING", label: "2. Pictures Missing" },
  { value: "EXTRA_INFORMATION_MISSING", label: "3. Extra information missing" },
  { value: "SCAN_CORRUPT", label: "4. Scan corrupt" },
  { value: "WRONG_PROJECT_DETAILS", label: "5. Wrong project details" }
];
export const optionQualityCheckerFeedbacks = [
  { value: "GOOD", label: "1. Good" },
  { value: "M2_NOT_CORRECT", label: "2. M2 not correct" },
  { value: "H_LESS_THAN_150CM_MISSING", label: "3. h<1,50m missing" },
  { value: "TABLE_IN_REPORT_WRONG", label: "4. Table in report wrong" },
  { value: "NEN_RULES_WRONG", label: "5. NEN rules wrong" }
];

export const mapEmployeeFeedbacks = [
  { value: "GOOD", label: "Good" },
  { value: "M2_NOT_CORRECT", label: "M2 not correct" },
  { value: "H_LESS_THAN_150CM", label: "h<1,50m" },
  { value: "PARTS_MISSING", label: "Parts missing" },
  { value: "NEN_RULES_WRONG", label: "NEN rules wrong" }
];
export const checkUserRole = (user) => {
  if (user?.userType) {
    switch (user.userType) {
      case USER_TYPE_OWNER:
        return "var(--yellow-800)";
      case USER_TYPE_CONTRACTOR:
        return "var(--cyan-800)";
      case USER_TYPE_MANAGER:
        return "var(--orange-800)";
      case USER_TYPE_ADMIN:
        return "var(--purple-800)";
      case USER_TYPE_QUALITY_CHECKER:
        return "var(--blue-800)";
      default:
        return "var(--surface-800)";
    }
  }
};

// export const checkUserRoleName = (user) => {
//   let roleName = "";
//   // if (user?.roles) {
//   //   user?.roles.forEach((role) => {
//       if (VALID_ROLES.includes(user?.userType)) {
//         switch (user?.userType) {
//           case USER_TYPE_OWNER:
//             roleName = USER_TYPE_OWNER;
//             break;
//           case USER_TYPE_CONTRACTOR:
//             roleName = USER_TYPE_CONTRACTOR;
//             break;
//           case USER_TYPE_MANAGER:
//             roleName = USER_TYPE_MANAGER;
//             break;
//           case USER_TYPE_ADMIN:
//             roleName = USER_TYPE_ADMIN;
//             break;
//           case USER_TYPE_QUALITY_CHECKER:
//             roleName = USER_TYPE_QUALITY_CHECKER;
//             break;
//           default:
//             roleName = "";
//             break;
//         }
//       // }
//     // });
//   }
//   return roleName;
// };

//** Constants */
export const NDD = "Yes";
export const MAKE_CHANGES = "MAKE_CHANGES";
export const IN_PROGRESS = "IN_PROGRESS";
export const SUBMITTED = "SUBMITTED";
export const ASSIGN = "ASSIGN";
export const ACCEPTED = "ACCEPTED";
export const FINAL_CHECK = "FINAL_CHECK";
export const ASSIGNED = "ASSIGNED";
export const QUALITY_CHECK = "QUALITY_CHECK";
export const ADJUSTMENTS_REQUESTED = "ADJUSTMENTS_REQUESTED";
export const DELIVERED = "DELIVERED";

export const compareEta = (record, newEta) => {
  if (
    moment(newEta).format("MM/DD/yyyy") <
    moment(new Date()).format("MM/DD/yyyy")
  ) {
    if (
      record.projectStatus === ACCEPTED ||
      record.projectStatus === DELIVERED
    ) {
      return (
        <React.Fragment>
          {
            <Tag
              style={{ width: "80px" }}
              className="mr-2 text-lg"
              severity="success"
              value={newEta ? moment(newEta).format("MM/DD/yyyy") : ""}
            ></Tag>
          }
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {
            <Tag
              style={{ width: "80px" }}
              className="mr-2 text-lg"
              severity="danger"
              value={newEta ? moment(newEta).format("MM/DD/yyyy") : ""}
            ></Tag>
          }
        </React.Fragment>
      );
    }
  } else {
    if (
      record.projectStatus === ACCEPTED ||
      record.projectStatus === DELIVERED
    ) {
      return (
        <React.Fragment>
          {
            <Tag
              style={{ width: "80px" }}
              className="mr-2 text-lg"
              severity="success"
              value={newEta ? moment(newEta).format("MM/DD/yyyy") : ""}
            ></Tag>
          }
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {
            <Tag
              style={{ width: "80px" }}
              className="mr-2 text-lg"
              severity="warning"
              value={newEta ? moment(newEta).format("MM/DD/yyyy") : ""}
            ></Tag>
          }
        </React.Fragment>
      );
    }
  }
};

export const conditionForProjectToFinalCheckForQualityCheck = (
  project,
  permissions
) => {
  return (
      project.changeable &&
      (project?.projectStatus === IN_PROGRESS || project?.projectStatus === MAKE_CHANGES)
      && permissions?.find((permission) => permission.name === ACTION_QUALITY_CHECK)
  );
};
export const conditionToAssignProjectCoodinator = (project, permissions) => {
  return (
      project.changeable &&
      permissions?.find((permission) => permission.name === ACTION_ASSIGN_PROJECT_COORDINATOR)
  );
};

export const conditionToProjectAssignToContractor = (project, permissions) => {
  return (
      project.changeable &&
      !project?.employee?.id 
      && permissions?.find((permission) => permission.name === ACTION_ASSIGN_CONTRACTOR)
  );
};

export const conditionToProjectReAssignToContractor = (project, permissions) => {
  return (
      project.changeable &&
      project?.employee?.id
      && project?.projectStatus !== SUBMITTED
      && project?.projectStatus !== ASSIGN
      && project?.projectStatus !== ACCEPTED
      && project?.projectStatus !== ASSIGNED
      && project?.projectStatus !== DELIVERED
      && permissions?.find((permission) => permission.name === ACTION_ASSIGN_CONTRACTOR)
  );
};

export const conditionToProjectAssignToQualityChecker = (project, permissions) => {
  return (
    project.changeable &&
    project?.projectStatus !== ACCEPTED 
    && project?.projectStatus !== DELIVERED 
    && permissions?.find((permission) => permission.name === ACTION_ASSIGN_QUALITY_CHECKER)
    && project?.parentId == null
  );
};

export const conditionToProjectFinalCheckForCustomerReview = (
  project,
  permissions
) => {
  return (
      project.changeable &&
      (project.projectStatus === QUALITY_CHECK
          || project.projectStatus === ADJUSTMENTS_REQUESTED)
      && permissions?.find((permission) => permission.name === ACTION_CUSTOMER_REVIEW)
  );
};

export const conditionToProjectForAdjustmentRequired = (project, permissions) => {
  return (
      project.changeable &&
      (project.projectStatus === DELIVERED || project.projectStatus === ACCEPTED)
      && permissions?.find((permission) => permission.name === ACTION_CUSTOMER_REQUEST_CHANGE)
  );
};

export const conditionToProjectForMakeChanges = (project, permissions) => {
  return (
       project.changeable &&
      (project.projectStatus === QUALITY_CHECK || project.projectStatus === FINAL_CHECK)
      && permissions?.find((permission) => permission.name === ACTION_MAKE_CHANGE)
  );
};

export const conditionToReturnToFinalCheck = (
  project,
  permissions
) => {
  return (
    project.changeable &&
    (project.projectStatus === DELIVERED || project.projectStatus == ACCEPTED)
    && permissions?.find((permission) => permission.name === ACTION_CUSTOMER_REQUEST_CHANGE)
  );
};

export const conditionToProjectForAccept = (project, permissions) => {
  return (
      project.changeable &&
      project.projectStatus === FINAL_CHECK
      && permissions?.find((permission) => permission.name === ACTION_ACCEPT)
  );
};

export const conditionToProjectForRequestChanges = (project, permissions) => {
  return (
      project.changeable &&
      project.projectStatus === FINAL_CHECK
      && permissions?.find((permission) => permission.name === ACTION_REQUEST_CHANGE)
  );
};

export const conditionToProjectForDelivered = (project, permissions) => {
  return (
      project.changeable &&
      project.projectStatus === ACCEPTED
      && permissions?.find((permission) => permission.name === ACTION_DELIVERED)
  );
};

export const conditionToForDeliveredAll = (permissions) => {
  return (
      permissions?.find((permission) => permission.name === ACTION_DELIVERED)
  );
};

export const conditionFPApi = (permissions, project) => {
  return (
    permissions?.find((permission) => permission.name === FLOOR_PLANNER_API) && new Date(project?.creationDate)  >= new Date("2023-10-14")
);
}

export const etaDateDisplay = (currentUser, project) => {
  if ((project?.employee?.id === currentUser?.id || project?.qualityChecker?.id === currentUser?.id) && project?.ndd == "No") {
    return calculateWeekends(project, project?.employee != null &&  project?.employee?.id === currentUser.id);
  } else {
    return project?.eta;
  }
};

export const conditionForUpdateAdjustment = (
  permissions
) => {
  return (
    hasPermission(permissions, UPDATE_ADJUSTMENT)
  );
};

export const conditionShowReworkProjectDetail = (
  project,
  permissions
) => {
  return (
    project?.parentId != null &&
    hasPermission(permissions, SHOW_REWORK_DETAIL)
  )
}

export const conditionShowCustomerProjectDetail = (
  permissions
) => {
  return (
    hasPermission(permissions, SHOW_CUSTOMER_DETAIL)
  )
}

export const conditionDeleteComment = (
  permissions
) => {
  return hasPermission(permissions, DELETE_FEEDBACK_COMMENT)
}

export const hasPermission = (permissions, checkPermission) => {
  return (
    permissions?.find((permission) => permission.name === checkPermission)
  );
}

export const conditionToETAProject = (permissions) =>{
  return (
    permissions?.find((permission) => permission.name === SHOW_ALL_ETA)
  );
}

export const conditionToShowCalendar = (permissions) =>{
  return (
    permissions?.find((permission) => permission.name === CALENDAR)
  );
}

export const conditionToCopyProject = (project, permissions) => {
  return project.parentId == null && permissions?.find((permission) => permission.name === COPY_PROJECT);
}

const calculateWeekends = (project, isContractor) => {
  var day = moment(project?.eta).isoWeekday();
  //show eta for qualitiy checker or contractor
  var subtractDate = isContractor ? 2 : 1;
  //case rework subtract 1 and 0 for display for contractor and qcer
  if(project?.parentId != null){
    subtractDate = subtractDate - 1;
  }
  day = moment(
    moment(project?.eta).subtract(subtractDate, "d").format("YYYY-MM-DD")
  )

  //case day is weekend subtract two days 
  if(day.isoWeekday() > 5){
    day = moment(
      moment(day).subtract(2, "d").format("YYYY-MM-DD")
    )
  }
  return day.format("YYYY-MM-DD");
};



export const getNextProject = (currentProjectId) => {
  let projectIdData = window.localStorage.getItem(PROJECT_IDS);
  if (projectIdData == "") {
    return -1;
  }

  let projectIds = projectIdData.split(",");
  let index = projectIds.findIndex(id => id == currentProjectId);
  
  if(index == -1){
    return -1;
  }

  if (index < projectIds.length - 1) {
    return projectIds[index + 1];
  } else {
    return projectIds[0];
  }
};


export const getPreviousProject = (currentProjectId) => {
  let projectIdData = window.localStorage.getItem(PROJECT_IDS);
  if (projectIdData == "") {
    return -1;
  }

  let projectIds = projectIdData.split(",");
  let index = projectIds.findIndex(id => id == currentProjectId);

  if(index == -1){
    return -1;
  }

  if (index > 0) {
    return projectIds[index - 1];
  } else {
    return projectIds[projectIds.length - 1];
  }
};

