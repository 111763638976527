import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Column } from "primereact/column";
import {
  addRole,
  editRole,
  getPermissions,
  getRoles,
} from "../../../apis/SettingApis";
import "./style.scss";
import { Form, Input, Select, Tag, Button as AntButton, Spin } from "antd";
import { Dialog } from "primereact/dialog";
import {
  ADD_ROLE,
  ALPHABETICAL_INPUTS,
  UPDATE_ROLE,
} from "../../../utils/constants";
import { useSelector } from "react-redux";

const Roles = (props) => {
  const [newRoleDialog, setNewRoleDialog] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [editCheck, setEditCheck] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const { Option } = Select;
  const [form] = Form.useForm();
  const toast = useRef(null);
  const dt = useRef(null);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchRoles = async () => {
    setApiLoading(true);
    const response = await getRoles();
    if (response.status === 200) {
      setRoles(response.data.sort((role1, role2) => role1.id - role2.id));
      setApiLoading(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: response.response.data,
        life: 3000,
      });
      setApiLoading(false);
    }
  };

  const fetchPermission = async () => {
    setApiLoading(true);
    const response = await getPermissions();
    if (response.status === 200) {
      setPermissionOptions(response.data);
      setApiLoading(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: response.response.data,
        life: 3000,
      });
      setApiLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
    fetchPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
    labelAlign: "right",
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const newValues = {
        id: form.getFieldValue("id") ? form.getFieldValue("id") : null,
        name: values.name,
        permissions: values.permissions.map((pId) => ({ id: pId })),
        companyId: currentUser.companyId
      };
      if (editCheck) {
        editRole(newValues)
          .then((res) => {
            if (res.status === 200) {
              setNewRoleDialog(false);
              setEditCheck(false);
              form.resetFields();
              fetchRoles();
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "Role Edited Successfully",
                life: 3000,
              });
              setApiLoading(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
      } else {
        addRole(newValues)
          .then((res) => {
            if (res.status === 200) {
              setNewRoleDialog(false);
              form.resetFields();
              fetchRoles();
              setApiLoading(false);
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "New Role Added Successfully",
                life: 3000,
              });
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
      }
    });
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Spin spinning={apiLoading}>
          <div>
            <Toolbar
              className="mb-4"
              left={() => {
                return (
                  <React.Fragment>
                    <div>
                      <h3 className="m-0">Roles</h3>
                    </div>
                  </React.Fragment>
                );
              }}
              right={() => {
                return (
                  <React.Fragment>
                    {permissions.map((permission) => {
                      return permission.name === ADD_ROLE ? (
                        <Button
                          label="New"
                          icon="pi pi-plus"
                          className="p-button-success mr-2"
                          style={{
                            backgroundColor: "#037EEA",
                            borderColor: "#037EEA",
                          }}
                          onClick={() => setNewRoleDialog(true)}
                        />
                      ) : (
                        ""
                      );
                    })}
                  </React.Fragment>
                );
              }}
            ></Toolbar>
          </div>
          <div>
            <DataTable
              ref={dt}
              value={roles}
              selection={selectedRole}
              onSelectionChange={(e) => setSelectedRole(e.value)}
              dataKey="id"
              paginator
              rows={10}
              rowsPerPageOptions={[5, 10, 25]}
              responsiveLayout="scroll"
            >
              <Column
                style={{ minWidth: "12rem" }}
                field="name"
                header="Name"
              />
              <Column
                style={{ minWidth: "12rem" }}
                field="permissions"
                header="Permissions"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex flex-wrap gap-1">
                        {record.permissions.map((permission) => {
                          return (
                            <div>
                              <Tag
                                color="blue"
                                key={permission.id}
                                style={{ margin: "0.2rem" }}
                              >
                                {permission.name
                                  ? permission.name
                                  : permission.name}
                              </Tag>
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Column
                style={{ minWidth: "12rem" }}
                field="action"
                header="Action"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex justify-content-center">
                        {permissions.map((permission) => {
                          return permission.name === UPDATE_ROLE ? (
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#037EEA",
                                  borderColor: "#037EEA",
                                }}
                                icon={"pi pi-pencil"}
                                label="Edit"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setEditCheck(true);
                                  setNewRoleDialog(true);
                                  form.setFieldsValue({
                                    id: record.id,
                                    name: record.name,
                                    permissions: (record.permissions || []).map(
                                      (p) => p.id
                                    ),
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
            </DataTable>
          </div>
          <Dialog
            header="Add/Edit User"
            visible={newRoleDialog}
            style={{ width: "30vw" }}
            onHide={() => {
              setNewRoleDialog(false);
              form.resetFields();
            }}
          >
            <Spin spinning={apiLoading}>
              <Form
                {...formItemLayout}
                name="role-form"
                form={form}
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      pattern: ALPHABETICAL_INPUTS,
                      message: "Please enter valid name",
                    },
                  ]}
                >
                  <Input maxLength={256} />
                </Form.Item>
                <Form.Item
                  name="permissions"
                  label="Permissions"
                  rules={[
                    {
                      required: true,
                      message: "Please select permissions from list",
                    },
                  ]}
                >
                  <Select
                    dropdownStyle={{ zIndex: "9999" }}
                    showSearch
                    mode="multiple"
                  >
                    {permissionOptions
                      ? permissionOptions.map((permission) => {
                          return (
                            <Option key={permission.id} value={permission.id}>
                              {permission.name}
                            </Option>
                          );
                        })
                      : ""}
                  </Select>
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>
                    <AntButton type="primary" key="submit" htmlType="submit">
                      Submit
                    </AntButton>
                  </div>
                </div>
              </Form>
            </Spin>
          </Dialog>
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default Roles;
