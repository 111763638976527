import React, { useEffect, useRef, useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import {
    getAllCompanies,
    uploadCompanyFiles,
    getCompanyFiles,
    deleteCompanyFile
} from "../../apis/CompanyApis";

import {
    Spin,
    Tooltip,
    Upload,
    Image,
    Checkbox
} from "antd";
import UpdateCompanyDialog from "./dialog/UpdateCompanyDialog";
import { Dialog } from "primereact/dialog";
import { PlusOutlined } from "@ant-design/icons";
import { ListBox } from "primereact/listbox";
import { confirmPopup } from "primereact/confirmpopup";
import { Galleria } from "primereact/galleria";

import {
    COMPANY_FILE,
    BACKGROUND_REPORT
} from "../../utils/constants"

const CompanyConfig = () => {
    const [companies, setCompanies] = useState([]);
    const [apiLoading, setApiLoading] = useState(false);
    const [visibleUpdateCompanyDialog, setVisibleUpdateCompanyDialog] = useState(false);
    const [company, setCompany] = useState(null)
    const toast = useRef(null);
    const [displayResponsive, setDisplayResponsive] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [visilbeCompanyFilesDialog, setVisilbeCompanyFilesDialog] = useState(false)
    const [companyFile, setCompanyFiles] = useState([]);

    const [activeIndex, setActiveIndex] = useState(0);
    const [imageHeader, setImageHeader] = useState(null);
    const [selectCompanyId, setSelectedCompanyId] = useState(null);
    const [displayUploadCompanyFile, setDisplayUploadCompanyFile] = useState(false)
    const [fileType, setFileType] = useState("");
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const responsiveOptions = [
        {
            breakpoint: "1024px",
            numVisible: 5,
        },
        {
            breakpoint: "768px",
            numVisible: 3,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
        },
    ];

    useEffect(() => {
        fetchCompanies();
    }, [])

    const fetchCompanies = () => {
        setApiLoading(true);
        getAllCompanies()
            .then((res) => {
                if (res.status === 200) {
                    setCompanies(res.data);
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const itemTemplateGalleria = (item) => {
        setImageHeader(item[1].fileName.split("/").pop());
        if (item[1].fileName.endsWith(".pdf")) {
            return (
                <React.Fragment>
                    <div
                        style={{
                            position: "relative",
                            overflow: "hidden",
                            width: "90%",
                            paddingTop: "56.25%",
                        }}
                    >
                        <iframe
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                width: "100%",
                                height: "100%",
                            }}
                            src={item[1].url}
                        />
                    </div>
                </React.Fragment>
            );
        } else if (
            item[1].fileName.endsWith(".fml") ||
            item[1].fileName.endsWith(".doc") ||
            item[1].fileName.endsWith(".docx") ||
            item[1].fileName.endsWith(".xls") ||
            item[1].fileName.endsWith(".xlsx") ||
            item[1].fileName.endsWith(".zip")
        ) {
            return (
                <React.Fragment>
                    <div className="flex justify-content-center m-0">
                        <div>
                            <h3>No Preview Available</h3>
                        </div>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Image
                        preview
                        width="600px"
                        src={item[1].url}
                        onError={(e) =>
                        (e.target.src =
                            "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
                        }
                        alt={"No Image"}
                    />
                </React.Fragment>
            );
        }
    };

    const getCompanyFilesByType = (id, fileType) => {
        setApiLoading(true);
        getCompanyFiles(id, fileType)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        const imagesMap = res.data;
                        setCompanyFiles(imagesMap);
                        setVisilbeCompanyFilesDialog(true);
                        setSelectedCompanyId(id);
                    }else{
                        setCompanyFiles([]);
                        setVisilbeCompanyFilesDialog(false);
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: "No files found",
                            life: 3000,
                        });
                    }
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    }

    const deletePictures = (companyId, fileIndex) => {
        const fileName = companyFile[fileIndex].fileName;
        setApiLoading(true);
        deleteCompanyFile({
            fileName: fileName,
            companyId: companyId,
            fileType: fileType
        })
            .then((res) => {
                if (res.status === 200) {
                    getCompanyFilesByType(companyId, fileType);
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "File Deleted Successfully",
                        life: 3000,
                    });
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    };

    const changeVisibleUpdateCompanyDialogState = (bool) => {
        fetchCompanies();
        setVisibleUpdateCompanyDialog(bool);
    };

    const onClick = () => {
        setDisplayResponsive(true)
        setFileList([]);
        setFileType(BACKGROUND_REPORT)
    };

    const handleChange = ({ fileList }) => {
        const fileMap = {};
        fileList.forEach((file) => (fileMap[file.name] = file));
        setFileList(Object.values(fileMap));
    };

    const beforeUpload = (file) => {
        if (company != null) {
            setApiLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            formData.append("fileType", fileType);
            uploadCompanyFiles(company.id, formData)
                .then((res) => {
                    if (res.status === 200) {
                        fetchCompanies(companies.id);
                        setApiLoading(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: res.response.data,
                            life: 3000,
                        });
                        setApiLoading(false);
                    }
                })
                .catch((e) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });

        } else {
            toast.current.show({
                severity: "warn",
                summary: "Status",
                detail: "No Selected Company",
                life: 3000,
            });
        }
        return false;
    };

    const checkboxTemplate = (rowData, field) => {
        return (
            <span>
                <Checkbox checked={rowData[field]} disabled />
            </span>
        );
    };

    return (
        <React.Fragment>
            <div className="user-custom-style">
                <Toast ref={toast} />

                <div>
                    <Spin spinning={apiLoading}>
                        <Toolbar
                            className="mb-4"
                            left={() => {
                                return (
                                    <React.Fragment>
                                        <div>
                                            <h3 className="m-0">Admin</h3>
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                            right={() => {
                                return (
                                    <React.Fragment>

                                    </React.Fragment>
                                );
                            }}
                        ></Toolbar>
                        <DataTable
                            value={companies}
                            onSelectionChange={(e) => { }}
                            dataKey="id"
                            responsiveLayout="scroll"
                        >
                            <Column field="id" header="ID" />
                            <Column field="name" header="Name" />
                            <Column field="email" header="Email" />
                            <Column field="url" header="Url"/>
                            <Column field="copyRight" header="Copyright"/>
                            <Column field="firstPageTextMarginTop" header="First Page Text Margin Top" />
                            <Column field="sFirstPageTextColor" header="Color of text on first page" />
                            <Column field="sHeaderTitleBulletPointColor" header="Color of headers/titles and bulletpoints" />
                            <Column field="sTableBorderColor" header="Color of table border" />
                            <Column field="sTableTextInBorderColor" header="Color of table text in border" />                            
                            <Column
                                field="reportImage2D"
                                header="Report Image 2D"
                                body={(rowData) => checkboxTemplate(rowData, 'reportImage2D')}
                            />
                            <Column
                                field="reportImage3D"
                                header="Report Image 3D_1"
                                body={(rowData) => checkboxTemplate(rowData, 'reportImage3D')}
                            />
                            <Column
                                field="reportImage3D_2"
                                header="Report Image 3D_2"
                                body={(rowData) => checkboxTemplate(rowData, 'reportImage3D_2')}
                            />

                            <Column field="reportType" header="Report Type" body={(rowData) => {
                                switch (rowData.reportType) {
                                    case 'FORMAT_1':
                                        return 'Format 1 (wm)';
                                    case 'FORMAT_2':
                                        return 'Format 2 (new customer/floorio)';
                                    default:
                                        return rowData.reportType;
                                }
                            }} />
                            <Column header="View Background" body={(record) => {
                                return (
                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            {
                                                <div>
                                                    <Tooltip title="View Background">
                                                        <Button
                                                            icon="pi pi-search"
                                                            className="p-button-rounded p-button-info"
                                                            aria-label="Search"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setFileType(BACKGROUND_REPORT);
                                                                getCompanyFilesByType(record.id, BACKGROUND_REPORT);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            }}></Column>
                            
                            <Column header="Background" body={(record) => {
                                return (
                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            {
                                                <div>
                                                    <Tooltip title="Upload Background Images">
                                                        <Button
                                                            icon="pi pi pi-upload"
                                                            className="p-button-rounded p-button-info"
                                                            aria-label="Search"
                                                            onClick={(e) => {
                                                                setCompany(record);
                                                                e.stopPropagation();
                                                                onClick();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                            />
                            <Column header="View Company Files" body={(record) => {
                                return (
                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            {
                                                <div>
                                                    <Tooltip title="View Company Files">
                                                        <Button
                                                            icon="pi pi-search"
                                                            className="p-button-rounded p-button-info"
                                                            aria-label="Search"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setFileType(COMPANY_FILE);
                                                                getCompanyFilesByType(record.id, COMPANY_FILE);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            }}></Column>
                             <Column header="Company Files" body={(record) => {
                                return (
                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            {
                                                <div>
                                                    <Tooltip title="Upload Company Images">
                                                        <Button
                                                            icon="pi pi pi-upload"
                                                            className="p-button-rounded p-button-info"
                                                            aria-label="Search"
                                                            onClick={(e) => {
                                                                setCompany(record);
                                                                e.stopPropagation();
                                                                setDisplayUploadCompanyFile(true);
                                                                setFileList([]);
                                                                setFileType(COMPANY_FILE);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            }}></Column>
                            <Column header="Action" body={(record) => {
                                return (
                                    <React.Fragment>
                                        <div className="flex justify-content-center">
                                            {
                                                <div>
                                                    <Button
                                                        style={{
                                                            backgroundColor: "#037EEA",
                                                            borderColor: "#037EEA",
                                                        }}
                                                        icon={"pi pi-pencil"}
                                                        label="Edit"
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            setCompany(record);
                                                            setVisibleUpdateCompanyDialog(true);
                                                        }}
                                                    />
                                                </div>

                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            }}
                            />
                        </DataTable>
                    </Spin>
                </div>
            </div>
            <UpdateCompanyDialog
                company={company}
                visible={visibleUpdateCompanyDialog}
                setVisible={changeVisibleUpdateCompanyDialogState}
            />

            <Dialog
                header={
                    "Upload Background Image"
                }
                visible={displayResponsive}
                onHide={() => {
                   setDisplayResponsive(false);
                }}
                style={{ width: "50vw" }}
            >
                <Spin spinning={apiLoading}>
                    <div>
                        <Upload
                            accept=".png, .jpg"
                            listType="picture-card"
                            fileList={fileList}
                            multiple
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                            showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
                        >
                            {uploadButton}
                        </Upload>
                    </div>
                    <div style={{ display: "block" }}>Format name page: background_pageNumber.png: ex: background_1.png</div>
                    <div style={{ display: "block" }}>Format default page - for images vertical page: background_default.png: ex: background_default.png</div>
                    <div style={{ display: "block" }}>Format horizontal page: background_horizontal.png</div>
                    <div style={{ display: "block" }}>Format last page: background_last_page.png</div>
                    <div style={{ display: "block" }}>Format Legenda image: Legenda horizontal.jpg | Legenda vertical.jpg</div>
                </Spin>
            </Dialog>

            <Dialog
                header={
                    "Upload Company File"
                }
                visible={displayUploadCompanyFile}
                onHide={() => {
                    setDisplayUploadCompanyFile(false)
                }}
                style={{ width: "50vw" }}
            >
                <Spin spinning={apiLoading}>
                    <div>
                        <Upload
                            listType="picture-card"
                            fileList={fileList}
                            multiple
                            onChange={handleChange}
                            beforeUpload={beforeUpload}
                            showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
                        >
                            {uploadButton}
                        </Upload>
                    </div>
                    </Spin>
            </Dialog>


            <Dialog
                className="min-h-full max-h-full min-w-full max-w-full"
                header="Files Detail"
                visible={visilbeCompanyFilesDialog}
                footer={null}
                onHide={() => {
                    setVisilbeCompanyFilesDialog(false);
                    setActiveIndex(0);
                    setImageHeader(null);
                }}
            >
                <Spin spinning={apiLoading}>
                    <div className="flex gap-4 pt-3">
                        <div className="card" style={{ width: "25%" }}>
                            <div className="flex flex-column gap-2">
                                <div>
                                    <ListBox
                                        options={Object.entries(companyFile)}
                                        listClassName="overflow-y-auto"
                                        listStyle={{ height: "70vh" }}
                                        filter
                                        optionLabel={(e) => e.fileName}
                                        itemTemplate={(option) => {
                                            return (
                                                <React.Fragment>
                                                    <Tooltip zIndex={9999}>

                                                        <div className="flex justify-content-between align-items-center">
                                                            <div>
                                                                {option[1].fileName.split("/").pop()}
                                                            </div>
                                                            <div>
                                                                <Button
                                                                    icon="pi pi-times"
                                                                    className="p-button-rounded p-button-danger p-button-text"
                                                                    aria-label="Cancel"
                                                                    style={{ zIndex: 9999 }}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        confirmPopup({
                                                                            target: e.currentTarget,
                                                                            message:
                                                                                "Do you want to delete this record?",
                                                                            icon: "pi pi-info-circle",
                                                                            acceptClassName: "p-button-danger",
                                                                            accept: () =>
                                                                                deletePictures(
                                                                                    selectCompanyId,
                                                                                    option[0]
                                                                                ),
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Tooltip>
                                                </React.Fragment>
                                            );
                                        }}
                                        onChange={(e) => {
                                            for (let index = 0; index < companyFile.length; index++) {
                                                const element = companyFile[index];
                                                if (element === e.value[1]) {
                                                    setActiveIndex(index);
                                                    setImageHeader(e.value[1].fileName.split("/").pop());
                                                }
                                            }
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="card" style={{ width: "75%", height: "65%" }}>
                            <Galleria
                                header={
                                    <div style={{ paddingBottom: "10px" }}>
                                        <h4>{imageHeader}</h4>
                                    </div>
                                }
                                value={Object.entries(companyFile)}
                                circular
                                showItemNavigators
                                item={itemTemplateGalleria}
                                responsiveOptions={responsiveOptions}
                                showThumbnails={false}
                                activeIndex={activeIndex}
                                onItemChange={(e) => setActiveIndex(e.index)}
                            />
                        </div>
                    </div>
                </Spin>
            </Dialog>
        </React.Fragment>
    );
};

export default CompanyConfig;
