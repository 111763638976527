/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useRef, useState } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import { Image } from "primereact/image";
import { Dialog } from "primereact/dialog";
import { Upload, Spin, Tooltip, Popover, message, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ListBox } from "primereact/listbox";
import { Galleria } from "primereact/galleria";
import { downloadedFile, downloadFileUrl } from "../../../functions/functions";
import inProgress from "./../../../resources/in-progress.svg";
import ready from "./../../../resources/ready.svg";
import error from "./../../../resources/error.png";
import  { Redirect } from 'react-router-dom'
import {
  getCompanyFiles,
} from "../../../apis/CompanyApis";

import {
  deleteUploadedDrawing,
  deleteUploadedImage,
  downloadDrawingZip,
  downloadGrayBackgroundZip,
  downloadImagesZip,
  drawingUploadApi,
  imageUploadApi,
  getAllImages,
  getAllDrawings,
  getAllGrayBackground,
  excelParserApi,
  deleteUploadExcel,
  getOneProject,
  submitProject,
  exportProject,
  managerAcceptProject,
  finalCheckProject,
  makeChangesProject,
  acceptedProject,
  changesRequestedProject,
  uploadPdf,
  deleteUploadPdf,
  previewPdfFile,
  deleteAllFloorPlans,
  deliveredProjects,
  convertGrayBackground,
  convertImageToExcel,
  deleteGrayBackground,
  convertGrayBackgroundCheck,
  convertImageToExcelCheck,
  checkDrawingStatus,
  copyProject
} from "../../../apis/ProjectApis";
import "./style.css";
import {
  generateExcelApi,
  regeneratePfd,
} from "../../../apis/generateFileReportApi";
import { getFormatFiles } from "../../../apis/AgencyApis";
import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import moment from "moment";
import {
  ACCEPTED,
  ASSIGNED,
  ADJUSTMENTS_REQUESTED,
  conditionForProjectToFinalCheckForQualityCheck,
  conditionToReturnToFinalCheck,
  conditionToProjectAssignToContractor,
  conditionToProjectForAccept,
  conditionToProjectForDelivered,
  conditionToProjectForMakeChanges,
  conditionToProjectFinalCheckForCustomerReview,
  conditionToProjectReAssignToContractor,
  conditionToProjectAssignToQualityChecker,
  conditionToAssignProjectCoodinator,
  DELIVERED,
  IN_PROGRESS,
  MAKE_CHANGES,
  QUALITY_CHECK,
  FINAL_CHECK,
  SUBMITTED,
  ASSIGN,
  etaDateDisplay,
  EDIT_PROJECT,
  conditionToProjectForAdjustmentRequired,
  conditionFPApi,
  mapEmployeeFeedbacks,
  USER_TYPE_OWNER,
  RECONNECT_FP,
  conditionShowReworkProjectDetail,
  conditionShowCustomerProjectDetail,
  getPreviousProject,
  getNextProject,
  COMPANY_FILE,
  conditionToCopyProject,
} from "../../../utils/constants";
import { confirmDialog } from "primereact/confirmdialog";
import { Toolbar } from "primereact/toolbar";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AssignToContractorDialog from "../dialog/assigntocontractordialog";
import { Tag } from "primereact/tag";
import AssigntoqualityCheckerDialog from "../dialog/assigntoqualitycheckerdialog";
import UpdateProjectForm from "../form/updateprojectform";
import ExportProjectParams from "../dialog/exportProjectParams";
import AssignToManagerDialog from "../dialog/assigntomanagerdialog";
import Linkify from "react-linkify";
import CustomerRequestChangeDialog from "../dialog/CustomerRequestChangesDialog";
import {
  createProjectOnFloorPlanner,
  exportProjectOnFloorPlanner,
} from "../../../apis/floorPlannerApis";
import ChangeImageNameDialog from "../dialog/changeImageNameDialog";
import FeedbackDialog from "../dialog/FeedbackDialog";
import AddDropboxLinkDialog from "../dialog/AddDropboxLinkDialog";
import AddFloorPlannerIdDialog from "../dialog/AddFloorPlannerIdDialog";
import CommentComponent from "./components/CommentComponent";

const ProjectEdit = (props) => {
  const [project, setProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [floorPlannerProjectId, setFloorPlannerProjectId] = useState();
  const [showExportParameters, setShowExportParameters] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [displayResponsive, setDisplayResponsive] = useState(false);
  const [modelCheck, setModelCheck] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [allDrawings, setAllDrawings] = useState([]);
  const [allGrayBackground, setAllGrayBackground] = useState([]);

  const [agencyImage, setAgencyImage] = useState(null);
  const [getFileView, setGetFileView] = useState(null);
  const [projectComments, setProjectComments] = useState([]);
  const [projectFeedbacks, setProjectProjectFeedbacks] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [permissions, setPermissions] = useState([]);
  const [imageHeader, setImageHeader] = useState(null);
  const [visibleContractorDialog, setVisibleContractorDialog] = useState(false);
  const [imageData, setImageData] = useState(false);
  const [visibleChangeImageNameDialog, setVisibleChangeImageNameDialog] =
    useState(false);
  const [visibleQualityCheckerDialog, setVisibleQualityCheckerDialog] =
    useState(false);
  const [visibleManagerDialog, setVisibleManagerDialog] = useState(false);
  const [projectEditDialouge, setProjectEditDialouge] = useState(false);
  const [generatedPdfPreview, setGeneratedPdfPreview] = useState(null);
  const [pdfViewerVisible, setPdfViewerVisible] = useState(false);
  const [adjustmentMadeBy, setAdjustmentMadeBy] = useState(null);
  const [feedbackForEmployee, setFeedbackForEmployee] = useState("");
  const [visibleAddDropboxLinkDialog, setVisibleAddDropboxLinkDialog] = useState(false);
  const [disableConvertExcel, setDisableConvertExcel] = useState(false);
  const [disableConvertImage, setDisableConvertImage] = useState(false)
  const [reworkProjectOptions, setReworkProjectOptions] = useState([])

  // const [commentImage, setCommentImage] = useState();
  const { currentUser = {} } = useSelector(({ app }) => app);
  const history = useHistory();
  const toast = useRef(null);
  const [visibleCustomerRequestChangeDialog,setVisibleCustomerRequestChangeDialog] = useState(false);
  const [visibleFeedbackDialog, setVisibleFeedbackDialog] = useState(false);
  const [visibleAddFPDialog, setVisibleAddFPDialog] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [reworkType, setReworkType] = useState("");

  const [previousProject, setPreviousProject] = useState(-1);
  const [nextProject, setNextProject] = useState(-1);

  const [companyFiles, setCompanyFiles] = useState([]);

  const [drawingStatus, setDrawingStatus] = useState(null);


  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const id = props?.match?.params?.id || props?.location?.state?.id;
    if (id) {
      fetchProject(id);
      getAllPictures("drawings", id);
      getAllPictures("images", id);
      getAllPictures("grayBackground", id)
      setPreviousProject(getPreviousProject(id));
      setNextProject(getNextProject(id));
      getDrawingStatus(id);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const fetchAgencyImage = (agencyFormat) => {
    setApiLoading(true);
    getFormatFiles(agencyFormat ? agencyFormat.id : agencyFormat)
      .then((res) => {
        if (res.status === 200) {
          setAgencyImage(res);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  const fetchProject = (id) => {
    setApiLoading(true);
    getOneProject(id)
      .then((res) => {
        if (res.status === 200) {
          setProject(res.data);
          setFloorPlannerProjectId(res.data.floorPlannerProjectId);
          let projectComment = res.data.projectComment.filter(t => !t.feedback);
          let projectFeedback = res.data.projectComment.filter(t => t.feedback);
          setProjectComments(projectComment);
          setProjectProjectFeedbacks(projectFeedback)
          if (res.data.agency.agencyFormat) {
            fetchAgencyImage(res.data?.agency?.agencyFormat);
          }
          setAdjustmentMadeByFromData(res)
          setApiLoading(false);
          //map from Enum to text
          setFeedbackForEmployee(mapValuesToLabels(res.data.feedbackForEmployee));

          let reworkOptions = [];
          if (res.data.parentId != null) {
            reworkOptions.push({ value: res.data.parentId, label: "Original"});
          }
          reworkOptions = reworkOptions.concat(
            res.data.reworkProjects
              .map((p, index) => ({ value: p, label: `Rework ${index + 1}` }))
          );

          setReworkProjectOptions(reworkOptions);

          let reworkType = "";
          if (reworkOptions.length > 0) {
            const matchingOption = reworkOptions.find(t => t.value === res.data.id);
            if (matchingOption) {
              reworkType = matchingOption.label;
            }else{
              reworkType = "Original";
            }
            setReworkType(reworkType)
          }
          getCompanyFilesByType(res.data.company.id);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });

      const mapValuesToLabels = (feedbacks) => {
        if(feedbacks == "" || feedbacks == null){
            return "";
        }
        const values = feedbacks.split(',');
        const labels = values.map((value) => {
          const option = mapEmployeeFeedbacks.find((option) => option.value === value);
          return option ? option.label : value;
        }).join(", ");
       
        return labels;
      };
  };

  const getDrawingStatus = (projectId) => {
    setApiLoading(true);
    checkDrawingStatus(projectId)
        .then((res) => {
            if (res.status === 200) {
                setDrawingStatus(res.data)
                setApiLoading(false);
            } else {
                setApiLoading(false);
            }
        })
        .catch((e) => {
            toast.current.show({
                severity: "error",
                summary: "Status",
                detail: e,
                life: 3000,
            });
            setApiLoading(false);
        });
      }

  const getCompanyFilesByType = (companyId) => {
    setApiLoading(true);
    getCompanyFiles(companyId, COMPANY_FILE)
        .then((res) => {
            if (res.status === 200) {
                if (res.data.length > 0) {
                    const imagesMap = res.data;
                    setCompanyFiles(imagesMap);
                }else{
                    setCompanyFiles([]);
                }
                setApiLoading(false);
            } else {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: res.response.data,
                    life: 3000,
                });
                setApiLoading(false);
            }
        })
        .catch((e) => {
            toast.current.show({
                severity: "error",
                summary: "Status",
                detail: e,
                life: 3000,
            });
            setApiLoading(false);
        });
}


  const setAdjustmentMadeByFromData = (res) => {
    if (res.data.adjustmentMadeBy) {
      let adjustmentMadeByName = ""
      switch (res.data.adjustmentMadeBy) {
        case "Manager":
          adjustmentMadeByName = res.data.manager && res.data.manager.name ? res.data.manager.name : "";
          break;
        case "Quality Checker":
          adjustmentMadeByName = res.data.qualityChecker && res.data.qualityChecker.name ? res.data.qualityChecker.name : "";
          break;
        case "Contractor":
          adjustmentMadeByName = res.data.employee && res.data.employee.name ? res.data.employee.name : "";
          break;
      }
      setAdjustmentMadeBy(adjustmentMadeByName === "" ? res.data.adjustmentMadeBy : adjustmentMadeByName);
    }
  }

  useEffect(() => {
    if (project) {
      let reworkTypeName = reworkType.length > 0 ? " / " + reworkType : "";
      setProjectName(
        `${project.projectId ? project.projectId : ""}${
          project.street ? " - " + project.street : ""
        }${project.number ? " " + project.number : ""}${
          project.city ? " - " + project.city : ""
        }${reworkTypeName}`
      );
    }
  }, [project]);

  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 5,
    },
    {
      breakpoint: "768px",
      numVisible: 3,
    },
    {
      breakpoint: "560px",
      numVisible: 1,
    },
  ];

  function b64toImageBlob(data) {
    var byteString = atob(data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }

  function b64toPdfBlob(data) {
    var byteString = atob(data);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "application/pdf" });
  }

  const changeVisibleCustomerRequestChangeDialogState = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleCustomerRequestChangeDialog(bool);
  };

  const changeVisibleAddDropboxLinkDialog = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleAddDropboxLinkDialog(bool);
  };

  const changeVisibleAddFPDialog = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleAddFPDialog(bool);
  };

  const changeVisibleFeedbackDialogState = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleFeedbackDialog(bool);
    if (project.projectStatus === FINAL_CHECK) {
      if (project.needUpdateAdjustment) {
        setApiLoading(true);
        setProject(project);
        setVisibleCustomerRequestChangeDialog(true);
      } else {
        if (project.manager == null) {
          confirmDialog({
            message:
              "Do you want to assign yourself to Project Final Checker?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => customerAcceptProject(project, true),
            reject: () => customerAcceptProject(project, false),
          });
        } else {
          confirmDialog({
            message: "Are you sure you want to change status to Accept?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => customerAcceptProject(project, false),
          });
        }
      }
    }
  };

  const itemTemplateGalleria = (item) => {
    setImageHeader(item[1].fileName.split("/").pop());
    if (item[1].fileName.endsWith(".pdf")) {
      return (
        <React.Fragment>
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "90%",
              paddingTop: "56.25%",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
              src={item[1].url}
            />
          </div>
        </React.Fragment>
      );
    } else if (
      item[1].fileName.endsWith(".fml") ||
      item[1].fileName.endsWith(".doc") ||
      item[1].fileName.endsWith(".docx") ||
      item[1].fileName.endsWith(".xls") ||
      item[1].fileName.endsWith(".xlsx") ||
      item[1].fileName.endsWith(".zip")
    ) {
      return (
        <React.Fragment>
          <div className="flex justify-content-center m-0">
            <div>
              <h3>No Preview Available</h3>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Image
            preview
            width="600px"
            src={item[1].url}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={"No Image"}
          />
        </React.Fragment>
      );
    }
  };

  const getAllPictures = (type, id) => {
    if ((project && project.id) || id) {
      switch (type) {
        case "images":
          setApiLoading(true);
          getAllImages(project && project.id ? project.id : id)
            .then((res) => {
              if (res.status === 200) {
                const imagesMap = res.data;
                setAllImages(imagesMap);
                setApiLoading(false);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: res.response.data,
                  life: 3000,
                });
                setApiLoading(false);
              }
            })
            .catch((e) => {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: e,
                life: 3000,
              });
              setApiLoading(false);
            });
          break;
        case "drawings":
          setApiLoading(true);
          getAllDrawings(project && project.id ? project.id : id)
            .then((res) => {
              if (res.status === 200) {
                const drawingsMap = res.data;
                setAllDrawings(drawingsMap);
                setApiLoading(false);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: res.response.data,
                  life: 3000,
                });
                setApiLoading(false);
              }
            })
            .catch((e) => {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: e,
                life: 3000,
              });
              setApiLoading(false);
            });
          break;
        case "grayBackground":
          setApiLoading(true);
          getAllGrayBackground(project && project.id ? project.id : id)
            .then((res) => {
              if (res.status === 200) {
                const grayBackGroundsMap = res.data;
                setAllGrayBackground(grayBackGroundsMap);
                setApiLoading(false);
              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: res.response.data,
                  life: 3000,
                });
                setApiLoading(false);
              }
            })
            .catch((e) => {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: e,
                life: 3000,
              });
              setApiLoading(false);
            });
          break;
        default:
          setApiLoading(false);
          break;
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const uploadPdfMedia = async (file) => {
    if (project && project.id) {
      setApiLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      await uploadPdf(formData, project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "PDF File Uploaded Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e.response.data,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
    return false;
  };

  const handleFinalCheckProject = (record, isAssignToMe) => {
    if (record && record.id) {
      setApiLoading(true);
      finalCheckProject(record.id, isAssignToMe)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Ready Successfully",
              life: 3000,
            });
            fetchProject(record.id);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleReturnToFinalCheck = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      finalCheckProject(record.id, false)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Final Check Successfully",
              life: 3000,
            });
            fetchProject(record.id);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const uploadExcelMedia = async (file) => {
    if (project && project.id) {
      setApiLoading(true);
      const formData = new FormData();
      formData.append("file", file);
      await excelParserApi(formData, project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(project.id);
            downloadedFile(res, res.headers.filename);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Excel File Uploaded Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e.response.data,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
    return false;
  };

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onDownloadZip = (type) => {
    if (project && project.id) {
      switch (type) {
        case "photosDownload":
          if (project.floorPlansCount > 0) {
            setApiLoading(true);
            downloadImagesZip(project.id)
              .then((res) => {
                if (res.status === 200) {
                  downloadFileUrl(res.data);
                  toast.current.show({
                    severity: "success",
                    summary: "Status",
                    detail: "Floorplans Zip Exported Successfully",
                    life: 3000,
                  });
                  setApiLoading(false);
                } else {
                  toast.current.show({
                    severity: "warn",
                    summary: "Status",
                    detail: res.response.data,
                    life: 3000,
                  });
                  setApiLoading(false);
                }
              })
              .catch((e) => {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: e,
                  life: 3000,
                });
                setApiLoading(false);
              });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Status",
              detail: "No Floorplans To Export",
              life: 3000,
            });
          }
          break;
        case "drawingDownload":
          if (project.drawingsCount > 0) {
            setApiLoading(true);
            downloadDrawingZip(project.id)
              .then((res) => {
                if (res.status === 200) {
                  downloadFileUrl(res.data);
                  toast.current.show({
                    severity: "success",
                    summary: "Status",
                    detail: "Drawings Zip Exported Successfully",
                    life: 3000,
                  });
                  setApiLoading(false);
                } else {
                  toast.current.show({
                    severity: "warn",
                    summary: "Status",
                    detail: res.response.data,
                    life: 3000,
                  });
                  setApiLoading(false);
                }
              })
              .catch((e) => {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: e,
                  life: 3000,
                });
                setApiLoading(false);
              });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Status",
              detail: "No Drawings To Export",
              life: 3000,
            });
          }
          break;
        case "grayBackgroundDownload":
          if (project.grayBackgroundCount > 0) {
            setApiLoading(true);
            downloadGrayBackgroundZip(project.id)
              .then((res) => {
                if (res.status === 200) {
                  downloadFileUrl(res.data);
                  toast.current.show({
                    severity: "success",
                    summary: "Status",
                    detail: "Gray Background Zip Exported Successfully",
                    life: 3000,
                  });
                  setApiLoading(false);
                } else {
                  toast.current.show({
                    severity: "warn",
                    summary: "Status",
                    detail: res.response.data,
                    life: 3000,
                  });
                  setApiLoading(false);
                }
              })
              .catch((e) => {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: e,
                  life: 3000,
                });
                setApiLoading(false);
              });
          } else {
            toast.current.show({
              severity: "warn",
              summary: "Status",
              detail: "No Gray Background To Export",
              life: 3000,
            });
          }
          break;
        default:
          setApiLoading(false);
          break;
      }
    }
  };

  const onClick = (name, check) => {
    setModelCheck(check);
    dialogFuncMap[`${name}`](true);
    setFileList([]);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    // fetchProject(project.id);
    // if (getFileView === "images") {
    //   getAllPictures("images");
    // } else if (getFileView === "drawing") {
    //   getAllPictures("drawing");
    // }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const handleChange = ({ fileList }) => {
    const fileMap = {};
    fileList.forEach((file) => (fileMap[file.name] = file));
    setFileList(Object.values(fileMap));
  };

  const beforeUpload = (file) => {
    if (project && project.id) {
      switch (modelCheck) {
        case "photos":
          {
            setApiLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            imageUploadApi(project.id, formData)
              .then((res) => {
                if (res.status === 200) {
                  getAllPictures("images");
                  fetchProject(project.id);
                  setApiLoading(false);
                } else {
                  toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: res.response.data,
                    life: 3000,
                  });
                  setApiLoading(false);
                }
              })
              .catch((e) => {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: e,
                  life: 3000,
                });
                setApiLoading(false);
              });
          }
          break;

        case "drawing":
          {
            setApiLoading(true);
            const formData = new FormData();
            formData.append("file", file);
            drawingUploadApi(project.id, formData)
              .then((res) => {
                if (res.status === 200) {
                  getAllPictures("drawings");
                  fetchProject(project.id);
                  getDrawingStatus(project.id)
                  setApiLoading(false);
                } else {
                  toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: res.response.data,
                    life: 3000,
                  });
                  setApiLoading(false);
                }
              })
              .catch((e) => {
                toast.current.show({
                  severity: "error",
                  summary: "Status",
                  detail: e,
                  life: 3000,
                });
                setApiLoading(false);
              });
          }
          break;

        default:
          setApiLoading(false);
          break;
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
    return false;
  };

  const deleteExcelFile = () => {
    if (project && project.id) {
      setApiLoading(true);
      deleteUploadExcel(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Excel File Deleted Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };

  const deletePdfFile = () => {
    if (project && project.id) {
      setApiLoading(true);
      deleteUploadPdf(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "PDF File Deleted Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };
  const deleteAllFloor = (type) => {
    if (project && project.id) {
      setApiLoading(true);
      deleteAllFloorPlans(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(project.id);
            getAllPictures(type);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "FloorPlans Deleted Successfully",
              life: 3000,
            });
            setGetFileView(null);
            setVisibleRight(false);
            setActiveIndex(0);
            setImageHeader(null);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };
  const deletePictures = (type, fileIndex, project) => {
    let fileName;
    switch (type) {
      case "images":
        fileName = allImages[fileIndex].fileName;
        setApiLoading(true);
        deleteUploadedImage({
          fileName: fileName,
          projectId: `${project.id}`,
        })
          .then((res) => {
            if (res.status === 200) {
              getAllPictures(type);
              fetchProject(project.id);
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "Floorplan Deleted Successfully",
                life: 3000,
              });
              setApiLoading(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
        return false;
      case "drawings":
        setApiLoading(true);
        fileName = allDrawings[fileIndex].fileName;
        deleteUploadedDrawing({
          fileName: fileName,
          projectId: `${project.id}`,
        })
          .then((res) => {
            if (res.status === 200) {
              getAllPictures(type);
              fetchProject(project.id);
              getDrawingStatus(project.id)
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "Drawing Deleted Successfully",
                life: 3000,
              });
              setApiLoading(true);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
        return false;
      case "grayBackground":
        setApiLoading(true);
        fileName = allGrayBackground[fileIndex].fileName;
        deleteGrayBackground({
          fileName: fileName,
          projectId: `${project.id}`,
        })
          .then((res) => {
            if (res.status === 200) {
              getAllPictures(type);
              fetchProject(project.id);
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "Gray Background Deleted Successfully",
                life: 3000,
              });
              setApiLoading(true);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
        return false;
      default:
        setApiLoading(false);
        return false;
    }
  };

  const getDataSource = (key) => {
    switch (key) {
      case "images":
        return allImages;
      case "drawings":
        return allDrawings;
      case "grayBackground":
        return allGrayBackground;
      default:
        return [];
    }
  };

  const previewPdf = () => {
    if (project && project.id) {
      setApiLoading(true);
      previewPdfFile(project.id)
        .then((res) => {
          if (res.status === 200) {
            setGeneratedPdfPreview(res.data);
            setPdfViewerVisible(true);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project PDF Imported Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const generateExcel = () => {
    if (project && project.id) {
      setApiLoading(true);
      generateExcelApi(project.id)
        .then((res) => {
          if (res.status === 200) {
            downloadFileUrl(res.data);
            // downloadedFile(res, res.headers.filename);
            // setGeneratedPdfPreview(res);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Excel Imported Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const generatePdf = () => {
    if (project && project.id) {
      setApiLoading(true);
      regeneratePfd(project.id)
        .then((res) => {
          if (res.status === 200) {
            setGeneratedPdfPreview(res.data);
            setPdfViewerVisible(true);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project PDF Imported Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleSubmitProject = (record) => {
    if (project && project.id) {
      setApiLoading(true);
      submitProject(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(record.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Submitted For Quality Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleConvertGrayBackground = () => {
    if (project && project.id) {
      convertGrayBackground(project.id)
        .then((res) => {
          console.log("res" + JSON.stringify(res))
          if (res.status === 200 && res.data == true) {
            fetchProject(project.id);
            getAllPictures("grayBackground", project.id)
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Convert gray background successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: "Convert gray background failed!",
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleConvertGrayBackgroundCheck = () => {
    if (project && project.id) {
      setApiLoading(true);
      convertGrayBackgroundCheck(project.id)
        .then((res) => {
          console.log("res" + JSON.stringify(res))
          if (res.status === 200 && res.data == true) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Convert images to gray background is processing",
              life: 3000,
            });
            handleConvertGrayBackground();
            setApiLoading(false);
            setDisableConvertImage(true);
          } else {
            fetchProject(project.id);
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: "Convert images to gray background failed!",
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

   const handleConvertImageToExcelCheck = () => {
    if (project && project.id) {
      setApiLoading(true);
      convertImageToExcelCheck(project.id)
        .then((res) => {
          console.log("res" + JSON.stringify(res))
          if (res.status === 200 && res.data == true) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Convert images to excel is processing",
              life: 3000,
            });
            handleConvertImageToExcel();
            setApiLoading(false);
            setDisableConvertExcel(true);
          } else {
            fetchProject(project.id);
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: "Convert images to excel failed!",
              life: 3000,
            });            
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleConvertImageToExcel = () => {
    if (project && project.id) {
      convertImageToExcel(project.id)
        .then((res) => {
          console.log("res" + JSON.stringify(res))
          if (res.status === 200 && res.data == true) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Convert images to excel successfully",
              life: 3000,
            });
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: "Convert images to excel failed!",
              life: 3000,
            });
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const exportProjectData = () => {
    if ((project.excelFileName || project.pdfFileName) && project.id) {
      setApiLoading(true);
      exportProject(project.id)
        .then((res) => {
          if (res.status === 200) {
            downloadedFile(res, decodeURIComponent(res.headers.filename));
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Exported Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Excel File Present For This Project",
        life: 3000,
      });
    }
  };

  const handleManagerAcceptProject = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      managerAcceptProject(record.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(record.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Assigned Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  

  const handleMakeChangesProject = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      makeChangesProject(record.id)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Make Changes Successfully",
              life: 3000,
            });
            fetchProject(record.id);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const customerAcceptProject = (record, isAssignToMe) => {
    if (record && record.id) {
      setApiLoading(true);
      acceptedProject(record.id, isAssignToMe)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(record.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Status Change To Accepted Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const customerChangesRequestedProject = (record) => {
    if (record && record.id) {
      setApiLoading(true);
      changesRequestedProject(record.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(record.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail:
                "Project Status Change To Adjustments Required Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const managerDeliverProject = (project) => {
    if (project && project.id) {
      setApiLoading(true);
      deliveredProjects(project.id)
        .then((res) => {
          if (res.status === 200) {
            fetchProject(project.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Submitted For Quality Check Successfully",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };

  const handleCopyProject = (project) => {
    if (project && project.id) {
      setApiLoading(true);
      copyProject(project.id)
        .then((res) => {
          if (res.status === 200) {
            redirectToProjectId(res.data.id);
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Copy Successfully!",
              life: 3000,
            });
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Status",
        detail: "No Selected Project",
        life: 3000,
      });
    }
  };


  if (redirect) {
    return <Redirect to={`/${selectedProjectId}/projectEdit`} />;
  }

  const content = (record) => {
    if (record && record.id) {
      return (
        <React.Fragment>
          {/* {floorPlannerProjectId ? (
            <div>
              <Button
                style={{ width: "120px" }}
                shape="round"
                className="p-button-outlined p-button-text text-left"
                label="Export with custom variables"
                onClick={() => setShowExportParameters(true)}
              />
            </div>
          ) : (
            ""
          )} */}

          <div className="flex flex-column align-items-start gap-2">
            <div>
              <Button
                shape="round"
                style={{ width: "120px" }}
                className="p-button-outlined p-button-text text-left"
                label="Export Project"
                disabled={!record.excelFileName && !record.pdfFileName}
                onClick={(e) => {
                  e.stopPropagation();
                  exportProjectData();
                }}
              />
            </div>

            {conditionForProjectToFinalCheckForQualityCheck(
              record,
              permissions
            ) ? (
              <div>
                <Button
                  style={{ width: "120px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Ready for Quality Check"
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDialog({
                      message:
                        "Are you sure you want to submit the project for Quality Check?",
                      header: "Confirmation",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => handleSubmitProject(record),
                    });
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {/*            {conditionToManagerAcceptProject(record, currentUser) ? (
              <React.Fragment>
                <div>
                  <Button
                    style={{ width: "120px" }}
                    shape="round"
                    className="p-button-outlined p-button-text text-left"
                    label="Assign to me"
                    onClick={(e) => {
                      e.stopPropagation();
                      confirmDialog({
                        message: "Are you sure you want to proceed?",
                        header: "Confirmation",
                        icon: "pi pi-exclamation-triangle",
                        accept: () => handleManagerAcceptProject(record),
                      });
                    }}
                  />
                </div>
              </React.Fragment>
            ) : (
              ""
            )}*/}

            {conditionToAssignProjectCoodinator(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label={
                    project?.manager?.id
                      ? "Re-Assign Project Final Checker"
                      : "Assign Project Final Checker"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    // setProject(record);
                    setApiLoading(true);
                    setVisibleManagerDialog(true);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {conditionToProjectAssignToContractor(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Assign to Contractor"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setProject(project);
                    setApiLoading(true);
                    setVisibleContractorDialog(true);
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {conditionToProjectReAssignToContractor(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Re-Assign Contractor"
                  onClick={(e) => {
                    e.stopPropagation();
                    // setProject(record);
                    setApiLoading(true);
                    setVisibleContractorDialog(true);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {conditionToProjectAssignToQualityChecker(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label={
                    project?.qualityChecker?.id
                      ? "Re-Assign Quality Checker"
                      : "Assign Quality Checker"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    // setProject(record);
                    setApiLoading(true);
                    setVisibleQualityCheckerDialog(true);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {conditionToProjectFinalCheckForCustomerReview(
              record,
              permissions
            ) ? (
                <div>
                  <Button
                    style={{ width: "140px" }}
                    shape="round"
                    className="p-button-outlined p-button-text text-left"
                    label="Ready for Final Check"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (record.projectStatus == QUALITY_CHECK) {
                        setVisibleFeedbackDialog(true);
                      } else {
                        if (
                          record.qualityChecker == null &&
                          record.employee.id != currentUser.id
                        ) {
                          confirmDialog({
                            message:
                              "Do you want to assign yourself to Quality Checker?",
                            header: "Confirmation",
                            icon: "pi pi-exclamation-triangle",
                            accept: () => handleFinalCheckProject(record, true),
                            reject: () => handleFinalCheckProject(record, false),
                          });
                        } else {
                          confirmDialog({
                        message: "Are you sure you want to proceed?",
                            header: "Confirmation",
                            icon: "pi pi-exclamation-triangle",
                            accept: () => handleFinalCheckProject(record, false),
                          });
                        }
                      }
                    }
                    }
                />
              </div>
            ) : (
              ""
            )}

             {conditionToReturnToFinalCheck(
              record,
              permissions
            ) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Return to final check"
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDialog({
                      message: "Are you sure you want to proceed?",
                      header: "Confirmation",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => handleReturnToFinalCheck(record),
                    });
                  }}
                />
              </div>
            ) : (
              ""
            )} 

            {conditionToProjectForAdjustmentRequired(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Customer request changes"
                  onClick={(e) => {
                    e.stopPropagation();
                    setApiLoading(true);
                    setVisibleCustomerRequestChangeDialog(true);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {conditionToProjectForMakeChanges(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "140px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Make Changes"
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDialog({
                      message: "Are you sure you want to proceed?",
                      header: "Confirmation",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => handleMakeChangesProject(record),
                    });
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {conditionToProjectForAccept(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "120px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Accept"
                  onClick={(e) => {
                    e.stopPropagation();
                    setVisibleFeedbackDialog(true);
               }}
                />
              </div>
            ) : (
              ""
            )}

            {conditionToProjectForDelivered(record, permissions) ? (
              <div>
                <Button
                  style={{ width: "120px" }}
                  shape="round"
                  className="p-button-outlined p-button-text text-left"
                  label="Delivered"
                  onClick={(e) => {
                    e.stopPropagation();
                    confirmDialog({
                      message: "Are you sure you want to proceed?",
                      header: "Confirmation",
                      icon: "pi pi-exclamation-triangle",
                      accept: () => managerDeliverProject(record),
                    });
                  }}
                />
              </div>
            ) : (
              ""
            )}
            {conditionToCopyProject(record, permissions) ? (
          <div>
            <Button
              style={{ width: "120px" }}
              shape="round"
              className="p-button-outlined p-button-text text-left"
              label="Copy Project"
              onClick={(e) => {
                e.stopPropagation();
                confirmDialog({
                  message: "Are you sure you want to copy this project?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => handleCopyProject(record),
                });
              }}
            />
          </div>
        ) : (
          ""
        )}
          </div>
        </React.Fragment>
      );
    }
  };

  const changeVisibleNewProjectDialogState = (bool) => {
    toast.current.show({
      severity: "success",
      summary: "Status",
      detail: "Project Updated Successfully",
      life: 3000,
    });
    setApiLoading(false);
    fetchProject(project.id);
    setProjectEditDialouge(bool);
  };

  const changeVisibleContractorDialogState = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleContractorDialog(bool);
  };

  const changeVisibleImageDialogState = (bool) => {
    fetchProject(project.id);
    setVisibleChangeImageNameDialog(bool);
  };

  const changeVisibleQualityCheckerDialogState = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleQualityCheckerDialog(bool);
  };

  const changeVisibleManagerDialogState = (bool) => {
    setApiLoading(false);
    fetchProject(project.id);
    setVisibleManagerDialog(bool);
  };

  const afterProjectImport = () => {
    fetchProject(project.id);
    setShowExportParameters(false);
  };

  const createProjectonnFloorPlanner = () => {
    if (projectName)
      createProjectOnFloorPlanner(project.id, projectName)
        .then((response) => {
          setFloorPlannerProjectId(parseInt(response.data));
          message.success("Successfully created project on floor planner");
        })
        .catch((error) =>
          message.success("Error creating project on floor planner")
        );
  };
  const exportProjectonnFloorPlanner = () => {
    exportProjectOnFloorPlanner(floorPlannerProjectId, project?.id).then(
      (response) => {
        if (response.status === 200)
          message.success("Project exported successfully");
      }
    );
  };

  const openProjectonnFloorPlanner = () => {
    if (floorPlannerProjectId)
      window.open(
        "https://floorplanner.com/projects/" + floorPlannerProjectId + "/editor"
      );
  };

  const redirectToProjectId = (e) => {
    setSelectedProjectId(e);
    setRedirect(true)
  }

  const checkProjectStatusTile = (record) => {
    switch (record?.projectStatus) {
      case SUBMITTED:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="info"
              value="Submitted"
            ></Tag>
          </React.Fragment>
        );
      case IN_PROGRESS:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              value="In Progress"
            ></Tag>
          </React.Fragment>
        );
      case FINAL_CHECK:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="warning"
              value="Final Check"
            ></Tag>
          </React.Fragment>
        );
      case MAKE_CHANGES:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="danger"
              value="Make Changes"
            ></Tag>
          </React.Fragment>
        );
      case ACCEPTED:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="success"
              value="Accepted"
            ></Tag>
          </React.Fragment>
        );
      case QUALITY_CHECK:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="warning"
              value="Quality Check"
            ></Tag>
          </React.Fragment>
        );
      case ASSIGNED:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="info"
              value="Assigned"
            ></Tag>
          </React.Fragment>
        );

        case ASSIGN:
          return (
            <React.Fragment>
              <Tag
                style={{ width: "125px" }}
                className="mr-2 text-lg"
                severity="info"
                value="Assign"
              ></Tag>
            </React.Fragment>
          );
      case ADJUSTMENTS_REQUESTED:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="danger"
              value="Adjustments Requested"
            ></Tag>
          </React.Fragment>
        );
      case DELIVERED:
        return (
          <React.Fragment>
            <Tag
              style={{ width: "125px" }}
              className="mr-2 text-lg"
              severity="success"
              value="Delivered"
            ></Tag>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Spin spinning={apiLoading}>
          <div>
            <Toolbar
              className="mb-4"
              left={() => {
                return (
                  <React.Fragment>
                    <div className="flex align-items-center gap-2">
                      <div>
                        <div className="flex align-items-center">
                          <div>
                            {
                              previousProject > -1 ?
                                <Button
                                  tooltip="Previous"
                                  tooltipOptions={{ position: "top" }}
                                  icon={
                                    <i className="pi pi-arrow-left text-2xl"></i>
                                  }
                                  className="p-button-secondary p-button-text"
                                  onClick={() => {
                                    setSelectedProjectId(previousProject);
                                    setRedirect(true);
                                  }
                                  }
                                /> : ""
                            }
                          </div>
                          <div>
                            {
                              nextProject > -1 ?
                                <Button
                                  tooltip="Next"
                                  tooltipOptions={{ position: "top" }}
                                  icon={
                                    <i className="pi pi-arrow-right text-2xl"></i>
                                  }
                                  className="p-button-secondary p-button-text"
                                  onClick={() => {
                                    setSelectedProjectId(nextProject);
                                    setRedirect(true);
                                  }
                                  }
                                /> : ""
                            }
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className="text-4xl">
                          {projectName ? projectName : ""}
                        </span>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
              right={() => {
                return (
                  <React.Fragment>
                    <div className="mx-2">
                      {reworkProjectOptions.length > 0 ?
                        (<Select
                          options={reworkProjectOptions}
                          dropdownStyle={{ zIndex: 9999, minWidth: "170px" }}
                          style={{minWidth: "170px"}}
                          isSearchable={false}
                          placeholder={"Change rework project"}
                          onChange={(e) => {redirectToProjectId(e)}}
                        />) : ("")
                      }

                    </div>
                    <div className="flex gap-2">
                      {
                        conditionFPApi(permissions, project) ? (
                          floorPlannerProjectId ? (
                            <>
                              {permissions.find(p => p.name === RECONNECT_FP) ?
                                <Button
                                  type="button"
                                  label=  {"Connected to: " + project.floorPlannerProjectId + " - Reconnect"}
                                  style={{ backgroundColor: "#037eea" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setVisibleAddFPDialog(true);
                                  }}
                                /> : ("")}
                              <Button
                                type="button"
                                label="Open Project in Floor Planner"
                                style={{ backgroundColor: "#037eea" }}
                                onClick={() => {
                                  openProjectonnFloorPlanner();
                                }}
                              />
    
                              <Button
                                type="button"
                                label="Import Floor planner"
                                style={{ backgroundColor: "#037eea" }}
                                onClick={(e) => {
                                  if(Object.keys(allImages).length > 0){
                                    confirmPopup({
                                      target: e.currentTarget,
                                      message: "This project has already files in the floorplan section, are you sure this project is not yet already created?",
                                      icon: "pi pi-info-circle",
                                      acceptClassName: "p-button-danger",
                                      accept: () => setShowExportParameters(true),
                                    });
                                  }else{
                                    setShowExportParameters(true)
                                  }
                                
                                }}
                              />
                            </>
                          ) : (
                            <>
                                <Button
                                  type="button"
                                  label="Connect in Floor Planner"
                                  style={{ backgroundColor: "#037eea" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setVisibleAddFPDialog(true);
                                  }}
                                />

                                <Button
                                  type="button"
                                  label="Create Project in Floor Planner"
                                  style={{ backgroundColor: "#037eea" }}
                                  onClick={(e) => {
                                    confirmPopup({
                                      target: e.currentTarget,
                                      message: "Are you sure this project is not already created?",
                                      icon: "pi pi-info-circle",
                                      acceptClassName: "p-button-danger",
                                      accept: () => createProjectonnFloorPlanner(),
                                    });
                                  }}
                                />
                              </>

                          )
                        ) : ("")
                      }
                      

                      {permissions.map((permission) => {
                        if (permission.name === EDIT_PROJECT) {
                          return (
                            <Button
                              type="button"
                              label="Edit Project"
                              style={{ backgroundColor: "#037eea" }}
                              onClick={() => {
                                setProjectEditDialouge(true);
                              }}
                            />
                          );
                        }
                      })}
                      <div>
                        <Popover
                          placement="bottomLeft"
                          content={() => content(project)}
                        >
                          <Button
                            type="button"
                            icon="pi pi-ellipsis-v"
                            aria-haspopup
                            aria-controls="overlay_panel"
                            className="select-product-button"
                            style={{ backgroundColor: "#037eea" }}
                          />
                        </Popover>
                        {/*  */}
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            ></Toolbar>
          </div>

          
          <div className="flex p-2">
            <div className="col-9">
              <div
                className="card"
                style={{
                  maxHeight: "100%",
                  minHeight: "100%",
                }}
              >
                <div className="grid">
                  <div className="col-8">
                    <div className="card p-fluid m-0">
                      <div className="field grid">
                        {conditionShowCustomerProjectDetail(permissions) ?
                          <><label htmlFor="customer" className="col-3 md:mb-0">
                            Customer:
                          </label>
                            <div className="col-10 md:col-8">
                              <label id="customer" type="text" className="input">
                                {project?.company?.name}
                              </label>
                            </div>
                            <Divider layout="horizontal">
                              <b></b>
                            </Divider></> : ""}
                        <label htmlFor="service" className="col-3 md:mb-0">
                          Service:
                        </label>
                        <div className="col-10 md:col-8">
                          <label id="service" type="text" className="input">
                            Floorplanner NEN2580 plans
                          </label>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <h5 className="font-bold">Attributes</h5>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="houseType"
                          className="col-3 mb-2   md:mb-0"
                        >
                          House Type:
                        </label>
                        <div className="col-8 md:col-2">
                          <label id="houseType" type="text" className="input">
                            {project && project.houseType
                              ? project.houseType
                              : ""}
                          </label>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="style"
                          className="col-3 mb-2 md:col-3 md:mb-0"
                        >
                          Style:
                        </label>
                        <div className="col-10 md:col-4">
                          <label id="style" type="text" className="input">
                            Schematic Plans
                          </label>
                        </div>
                        <div className="col-10 md:col-4">
                          <label
                            htmlFor="houseType"
                            className="col-3 mb-2   md:mb-0"
                          />
                        </div>
                      </div>
                      <Divider layout="horizontal" className="divider">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="option"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Option:
                        </label>
                        <div className="col-10 md:col-4">
                          <label id="option" type="text" className="input">
                            {project && project.projectOption
                              ? project.projectOption
                              : ""}
                          </label>
                        </div>
                        <div className="col-10 md:col-4">
                          <label
                            htmlFor="option"
                            className="col-3 mb-2   md:mb-0"
                          />
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="option"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          NDD:
                        </label>
                        <div className="col-10 md:col-4">
                          {project?.ndd === "Yes" ? (
                            <label
                              id="option"
                              type="text"
                              className="input"
                              style={{ border: "2px solid red" }}
                            >
                              {project?.ndd}
                            </label>
                          ) : (
                            <label id="option" type="text" className="input">
                              {project?.ndd}
                            </label>
                          )}
                        </div>
                        <div className="col-10 md:col-4">
                          <label
                            htmlFor="option"
                            className="col-3 mb-2   md:mb-0"
                          />
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="option"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Furnished:
                        </label>
                        <div className="col-10 md:col-4">
                          <label id="option" type="text" className="input">
                            {project && project.furnishedType
                              ? project.furnishedType
                              : ""}
                          </label>
                        </div>
                        <div className="col-10 md:col-4">
                          <label
                            htmlFor="option"
                            className="col-3 mb-2   md:mb-0"
                          />
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="measureType"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Measurement Type:
                        </label>
                        <div className="col-10 md:col-4">
                          <label id="measureType" type="text" className="input">
                            {project && project.measurementType
                              ? project.measurementType
                              : ""}
                          </label>
                        </div>
                        <div className="col-10 md:col-4">
                          <label
                            htmlFor="measureType"
                            className="col-3 mb-2   md:mb-0"
                          />
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="measureDate"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Measurement Date:
                        </label>
                        <div className="col-10 md:col-4">
                          <label id="measureDate" type="text" className="input">
                            {project && project.measurementDate
                              ? project.measurementDate
                              : ""}
                          </label>
                        </div>
                        <div className="col-10 md:col-4">
                          <label
                            htmlFor="measureDate"
                            className="col-3 mb-2   md:mb-0"
                          />
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>

                      <h5 className="font-bold">Dates</h5>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="eta"
                          className="col-5 mb-1 md:col-5 md:mb-0"
                        >
                          ETA:
                        </label>
                        <div className="col-7 md:col-7">
                          <label id="eta" type="text" className="input">
                            {/* {project && project.eta ? project.eta : ""} */}
                            {etaDateDisplay(currentUser, project)}
                          </label>
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>
                      <h5 className="font-bold">People</h5>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="owner"
                          className="col-5 mb-1 md:col-5 md:mb-0"
                        >
                          Owner:
                        </label>
                        <div className="col-7 md:col-7">
                          <div className="label-created-date">
                            <label id="owner" type="text" className="input">
                              {project &&
                              project.customer &&
                              project.customer.name
                                ? project.customer.name
                                : ""}
                            </label>
                            <label
                              id="projectcoordinator"
                              type="text"
                              className="date-input"
                            >
                              {project?.assignedToOwnerDate
                                ? "Created on: " +
                                  moment(project.assignedToOwnerDate).format(
                                    "MM-DD"
                                  ) +
                                  " at " +
                                  moment(project.assignedToOwnerDate).format(
                                    "LT"
                                  )
                                : ""}
                            </label>
                          </div>
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>

                      <div className="field grid">
                        <label
                          htmlFor="contractor"
                          className="col-5 mb-1 md:col-5 md:mb-0"
                        >
                          Contractor:
                        </label>
                        <div className="col-7 md:col-7 ">
                          <div className="label-created-date">
                            <label
                              id="contractor"
                              type="text"
                              className="input"
                            >
                              {project &&
                              project.employee &&
                              project.employee.name
                                ? project.employee.name
                                : ""}
                            </label>
                            <label
                              id="projectcoordinator"
                              type="text"
                              className="date-input"
                            >
                              {project?.assignedToContractorDate
                                ? "Assigned on: " +
                                  moment(
                                    project.assignedToContractorDate
                                  ).format("MM-DD") +
                                  " at " +
                                  moment(
                                    project.assignedToContractorDate
                                  ).format("LT")
                                : ""}
                            </label>
                          </div>
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>

                      <div className="field grid" style={project?.parentId != null ? {opacity: "0.5"} : {}}>
                        <label
                          htmlFor="qualityCheck"
                          className="col-5 mb-1 md:col-5 md:mb-0"
                        >
                          Quality Check:
                        </label>
                        <div className="col-7 md:col-7">
                          <div className="label-created-date">
                            <label
                              id="qualityCheck"
                              type="text"
                              className="input"
                            >
                              {project &&
                              project.qualityChecker &&
                              project.qualityChecker.name
                                ? project.qualityChecker.name
                                : "not assigned yet"}
                            </label>
                            <label
                              id="projectcoordinator"
                              type="text"
                              className="date-input"
                            >
                              {project?.assignedToQualityCheckDate
                                ? "Assigned on: " +
                                  moment(
                                    project.assignedToQualityCheckDate
                                  ).format("MM-DD") +
                                  " at " +
                                  moment(
                                    project.assignedToQualityCheckDate
                                  ).format("LT")
                                : ""}
                            </label>
                          </div>
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>

                      <div className="field grid">
                        <label
                          htmlFor="projectcoordinator"
                          className="col-5 mb-1 md:col-5 md:mb-0"
                        >
                          Project Final Checker:
                        </label>
                        <div className="col-7 md:col-7">
                          <div className="label-created-date">
                            <label
                              id="projectcoordinator"
                              type="text"
                              className="input"
                            >
                              {project &&
                              project.manager &&
                              project.manager.name
                                ? project.manager.name
                                : "not assigned yet"}
                            </label>
                            <label
                              id="projectcoordinator"
                              type="text"
                              className="date-input"
                            >
                              {project?.assignedToCoordinatorDate
                                ? "Assigned on: " +
                                  moment(
                                    project.assignedToCoordinatorDate
                                  ).format("MM-DD") +
                                  " at " +
                                  moment(
                                    project.assignedToCoordinatorDate
                                  ).format("LT")
                                : ""}
                            </label>
                          </div>
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>

                      {conditionShowReworkProjectDetail(project, permissions)?
                      <div className="field grid">
                      <label
                        htmlFor="rewordType"
                        className="col-5 mb-1 md:col-5 md:mb-0"
                      >
                        Rework:
                      </label>

                      <div className="col-7 md:col-7">
                        <label id="eta" type="text" className="input">
                          {project.adjustment}
                        </label>
                      </div>

                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                    </div> : ""}
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="card p-fluid m-0">
                      <h5 className="font-bold">Agency</h5>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="agency"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Name:
                        </label>
                        <div className="col-10 md:col-9">
                          <label id="agency" type="text" className="input">
                            {project && project.agency
                              ? project.agency.name
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="agencyAddress"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Address:
                        </label>
                        <div className="col-10 md:col-9">
                          <label
                            id="agencyAddress"
                            type="text"
                            className="input"
                          >
                            {project && project.agency
                              ? project.agency.address
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="agencyAddress"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Postal Code:
                        </label>
                        <div className="col-10 md:col-9">
                          <label
                            id="agencyAddress"
                            type="text"
                            className="input"
                          >
                            {project && project.agency
                              ? project.agency.postCode
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="agency"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Agency Format:
                        </label>
                        <div className="col-10 md:col-9">
                          <label id="agency" type="text" className="input">
                            {project && project.agency
                              ? project.agency.agencyFormat.name
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="agency"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Format:
                        </label>
                        <div className="col-10 md:col-9">
                          <label id="agency" type="text" className="input">
                            {project && project.agency
                              ? project.agency.format
                              : ""}
                          </label>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      {/* <h5>Attributes</h5> */}
                      <h5 className="font-bold">Project</h5>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label
                          htmlFor="projectName"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Name:
                        </label>
                        <div className="col-10 md:col-9">
                          <label id="projectName" type="text" className="input">
                            {project && project.product ? project.product : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="projectAddress"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Address:
                        </label>
                        <div className="col-10 md:col-9">
                          <label
                            id="projectAddress"
                            type="text"
                            className="input"
                          >
                            {project
                              ? `${project.street ? project.street : ""} ${
                                  project.number ? project.number : ""
                                } ${project.city ? project.city : ""}`
                              : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="projectAddress"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Postal Code:
                        </label>
                        <div className="col-10 md:col-9">
                          <label
                            id="projectAddress"
                            type="text"
                            className="input"
                          >
                            {project ? project.postalCode : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="report"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Report:
                        </label>
                        <div className="col-10 md:col-9">
                          <label
                            id="report"
                            type="text"
                            className="input"
                          >
                            {project ? project.report : ""}
                          </label>
                        </div>
                      </div>
                      <div className="field grid">
                        <label
                          htmlFor="projectAddress"
                          className="col-12 mb-2 md:col-3 md:mb-0"
                        >
                          Project Status:
                        </label>
                        <div className="col-10 md:col-9">
                          <label
                            id="projectAddress"
                            type="text"
                            className="input"
                          >
                            {checkProjectStatusTile(project)}
                          </label>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <h5 className="font-bold">Agency Standard</h5>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid justify-content-center">
                        {agencyImage && agencyImage.data ? (
                          <Image
                            src={URL.createObjectURL(agencyImage.data)}
                            alt="Image"
                            width="150"
                            preview
                          />
                        ) : (
                          <h6 style={{ textAlign: "center" }}>
                            No Preview Available
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="card p-fluid ">
                      <div className="field grid">
                        <label htmlFor="service" className="col-3 md:mb-0">
                          <b> Attachments</b>
                        </label>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label htmlFor="houseType" className="col-3">
                          <b>Floorplans:</b>
                        </label>
                        <label htmlFor="service" className="col-3">
                          {project && project.floorPlansCount
                            ? project.floorPlansCount > 0
                              ? project.floorPlansCount > 1
                                ? project.floorPlansCount + " Files"
                                : project.floorPlansCount + " File"
                              : "None"
                            : "None"}
                        </label>
                        <label htmlFor="status" className="col-1">
                          {project &&
                            project?.import2d === 0 &&
                            project?.import3d === 0 &&
                            project?.importBackground === 0 ? (
                            <React.Fragment></React.Fragment>
                          ) : project?.import2d === 1 ||
                            project?.import3d === 1 ||
                            project?.importBackground === 1 ? (
                            <React.Fragment>
                              <img width={25}
                                src={inProgress}
                                alt="In Progress"
                              />
                            </React.Fragment>
                          ) : project?.import2d === 2 &&
                            project?.import3d === 2 &&
                            project?.importBackground === 2 ? (
                            <React.Fragment>
                              <img width={25}
                                src={ready}
                                alt="Ready"
                              />
                            </React.Fragment>
                          ) : null}
                        </label>

                        <div
                          className="col-5 md:col-4"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title="View Images">
                            <Button
                              icon="pi pi-search"
                              className="p-button-rounded p-button-info"
                              aria-label="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (Object.keys(allImages).length > 0) {
                                  setGetFileView("images");
                                  setVisibleRight(true);
                                } else if (project.floorPlansCount > 0) {
                                  toast.current.show({
                                    severity: "info",
                                    summary: "Status",
                                    detail:
                                      "Loading floor plans for this project. Please wait...",
                                    life: 5000,
                                  });
                                } else {
                                  toast.current.show({
                                    severity: "warn",
                                    summary: "Status",
                                    detail: "No Floorplans for this project",
                                    life: 3000,
                                  });
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Download Images">
                            <Button
                              icon="pi pi-download"
                              className="p-button-rounded p-button-success"
                              aria-label="Download"
                              disabled={!project?.changeable}
                              onClick={(e) => {
                                e.stopPropagation();
                                onDownloadZip("photosDownload");
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Upload Images">
                            <Button
                              icon="pi pi-upload"
                              className="p-button-rounded p-button-secondary"
                              aria-label="Upload"
                              disabled={!project?.changeable}
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick("displayResponsive", "photos");
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label htmlFor="houseType" className="col-3">
                          <b>Drawing:</b>
                        </label>

                        <label htmlFor="service" className="col-3 md:mb-0">
                          {project && project.drawingsCount
                            ? project.drawingsCount > 0
                              ? project.drawingsCount > 1
                                ? project.drawingsCount + " Files"
                                : project.drawingsCount + " File"
                              : "None"
                            : "None"}
                        </label>
                        <label htmlFor="drawingStatus" className="col-1 md:mb-0">
                          {
                            drawingStatus != null && drawingStatus === "PROCESSING" ? (
                              <React.Fragment>
                                <img width={25}
                                  src={inProgress}
                                  alt="In Progress"
                                />
                              </React.Fragment>
                            ) : drawingStatus != null && drawingStatus === "DONE"
                              ? (
                                <React.Fragment>
                                  <img width={25}
                                    src={ready}
                                    alt="Ready"
                                  />
                                </React.Fragment>
                              ) : null}
                        </label>
                        <div
                          className="col-5 md:col-4"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title="View Drawings">
                            <Button
                              icon="pi pi-search"
                              className="p-button-rounded p-button-info"
                              aria-label="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (Object.values(allDrawings).length > 0) {
                                  setGetFileView("drawings");
                                  setVisibleRight(true);
                                } else if (!project?.drawingsCount > 0) {
                                  toast.current.show({
                                    severity: "info",
                                    summary: "Status",
                                    detail:
                                      "Loading drawings for this project. Please wait...",
                                    life: 5000,
                                  });
                                } else {
                                  toast.current.show({
                                    severity: "warn",
                                    summary: "Status",
                                    detail: "No Drawings for this project",
                                    life: 3000,
                                  });
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Download Drawings">
                            <Button
                              icon="pi pi-download"
                              className="p-button-rounded  p-button-success"
                              aria-label="Download"
                              disabled={!project?.changeable}
                              onClick={(e) => {
                                e.stopPropagation();
                                onDownloadZip("drawingDownload");
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Upload Drawings">
                            <Button
                              icon="pi pi-upload"
                              className="p-button-rounded p-button-secondary"
                              aria-label="Upload"
                              disabled={!project?.changeable}
                              onClick={(e) => {
                                e.stopPropagation();
                                onClick("displayResponsive", "drawing");
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label htmlFor="houseType" className="col-3">
                          <b>External Measure Report:</b>
                        </label>

                        <label
                          htmlFor="service"
                          className="col-4 md:mb-0 truncate"
                        >
                          <Tooltip
                            title={
                              project && project.id && project.pdfFileName
                                ? project.pdfFileName
                                : ""
                            }
                          >
                            <p>
                              {project && project.id && project.pdfFileName
                                ? project.pdfFileName
                                : ""}
                            </p>
                          </Tooltip>
                        </label>
                        <div
                          className="col-5 md:col-4"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title="Delete PDF">
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-info"
                              aria-label="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmDialog({
                                  message: "Are you sure you want to proceed?",
                                  header: "Confirmation",
                                  icon: "pi pi-exclamation-triangle",
                                  accept: () => deletePdfFile(),
                                });
                              }}
                              disabled={project?.pdfFileName ? false : true}
                            />
                          </Tooltip>
                          <Tooltip title="Preview PDF">
                            <Button
                              disabled={project?.pdfFileName ? false : true}
                              icon="pi pi-search"
                              className="p-button-rounded  p-button-success"
                              aria-label="Download"
                              onClick={(e) => {
                                e.stopPropagation();
                                previewPdf();
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Upload PDF">
                            <Upload
                              accept=".pdf"
                              showUploadList={false}
                              beforeUpload={uploadPdfMedia}
                              disabled={project?.pdfFileName ? true : false}
                            >
                              <Button
                                disabled={project?.pdfFileName ? true : false}
                                icon="pi pi-upload"
                                className="p-button-rounded p-button-secondary"
                                aria-label="Upload"
                              />
                            </Upload>
                          </Tooltip>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                      <div className="field grid">
                        <label htmlFor="houseType" className="col-3">
                          <b>Excel:</b>
                        </label>

                        <label
                          htmlFor="service"
                          className="col-3 md:mb-0 truncate"
                        >
                          <Tooltip
                            title={
                              project && project.id && project.excelFileName
                                ? project.excelFileName
                                : ""
                            }
                          >
                            <p>
                              {project && project.id && project.excelFileName
                                ? project.excelFileName
                                : ""}
                            </p>
                          </Tooltip>
                        </label>
                        <label htmlFor="status" className="col-1">
                          {project &&
                            project?.convertExcelStatus === 3 ? (
                            (
                              <React.Fragment>
                                <img width={20}
                                  src={error}
                                  alt="Error"
                                />
                              </React.Fragment>
                            )) : project?.convertExcelStatus === 1 ? (
                              <React.Fragment>
                                <img width={25}
                                  src={inProgress}
                                  alt="In Progress"
                                />
                              </React.Fragment>
                            ) : project?.convertExcelStatus === 2
                            ? (
                              <React.Fragment>
                                <img width={25}
                                  src={ready}
                                  alt="Ready"
                                />
                              </React.Fragment>
                            ) : null}
                        </label>
                        <div
                          className="col-5 md:col-5"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title="Delete Excel">
                            <Button
                              icon="pi pi-trash"
                              className="p-button-rounded p-button-info"
                              aria-label="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                                confirmDialog({
                                  message: "Are you sure you want to proceed?",
                                  header: "Confirmation",
                                  icon: "pi pi-exclamation-triangle",
                                  accept: () => deleteExcelFile(),
                                });
                              }}
                              disabled={project?.excelFileName ? false : true}
                            />
                          </Tooltip>
                          <Tooltip title="Generate PDF">
                            <Button
                              disabled={
                                project?.excelFileName
                                  ? project?.pdfFileName
                                    ? true
                                    : false
                                  : true
                              }
                              icon="pi pi-file-pdf"
                              className="p-button-rounded  p-button-success"
                              aria-label="Download"
                              onClick={(e) => {
                                e.stopPropagation();
                                generatePdf();
                              }}
                            />
                          </Tooltip>
                          {project?.excelFileName ? (
                            <Tooltip title="Download Excel">
                              <Button
                                icon="pi pi-download"
                                className="p-button-rounded  p-button-success"
                                aria-label="Download"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  generateExcel();
                                }}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title="Upload Excel">
                              <Upload
                                accept=".xlsx, .xls"
                                showUploadList={false}
                                beforeUpload={uploadExcelMedia}
                                disabled={project?.excelFileName ? true : false}
                              >
                                <Button
                                  disabled={
                                    project?.excelFileName ? true : false
                                  }
                                  icon="pi pi-upload"
                                  className="p-button-rounded p-button-secondary"
                                  aria-label="Upload"
                                />
                              </Upload>
                            </Tooltip>
                          )}
                            <Tooltip title="Convert Images to Excel">
                            <Button
                              icon="pi pi-cloud-download"
                              className="p-button-rounded p-button-secondary"
                              aria-label="Upload"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(disableConvertExcel && project?.convertExcelStatus == 1){
                                  toast.current.show({
                                    severity: "warn",
                                    summary: "Status",
                                    detail: "Convert images to excel is processing!",
                                    life: 3000,
                                  });
                                }else{
                                  if(allImages.length === 0){
                                    toast.current.show({
                                      severity: "warn",
                                      summary: "Status",
                                      detail: "No Floorplans for this project",
                                      life: 3000,
                                    });
                                  }else{
                                    confirmDialog({
                                      message:
                                        "Are you sure you want to convert images to excel?",
                                      header: "Confirmation",
                                      icon: "pi pi-exclamation-triangle",
                                      accept: () => handleConvertImageToExcelCheck(),
                                    }); 
                                  }                                 
                                }
                            
                              }}
                            />
                          </Tooltip>
                        </div>
                        <Divider layout="horizontal">
                          <b></b>
                        </Divider>
                      </div>
                      <div className="field grid">
                        <label htmlFor="houseType" className="col-3">
                          <b>Gray Background:</b>
                        </label>

                        <label htmlFor="service" className="col-3 md:mb-0">
                          {project && project.grayBackgroundCount
                            ? project.grayBackgroundCount > 0
                              ? project.grayBackgroundCount > 1
                                ? project.grayBackgroundCount + " Files"
                                : project.grayBackgroundCount + " File"
                              : "None"
                            : "None"}
                        </label>
                        <label htmlFor="status" className="col-1">
                          {project &&
                            project?.convertImageStatus === 3 ? (
                            (
                              <React.Fragment>
                                <img width={20}
                                  src={error}
                                  alt="Error"
                                />
                              </React.Fragment>
                            )) : project?.convertImageStatus === 1 ? (
                              <React.Fragment>
                                <img width={25}
                                  src={inProgress}
                                  alt="In Progress"
                                />
                              </React.Fragment>
                            ) : project?.convertImageStatus === 2
                            ? (
                              <React.Fragment>
                                <img width={25}
                                  src={ready}
                                  alt="Ready"
                                />
                              </React.Fragment>
                            ) : null}
                        </label>
                        <div
                          className="col-5 md:col-4"
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title="View Gray Background">
                            <Button
                              icon="pi pi-search"
                              className="p-button-rounded p-button-info"
                              aria-label="Search"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (Object.values(allGrayBackground).length > 0) {
                                  setGetFileView("grayBackground");
                                  setVisibleRight(true);
                                } else if (project?.grayBackgroundCount > 0) {
                                  toast.current.show({
                                    severity: "info",
                                    summary: "Status",
                                    detail:
                                      "Loading Gray Background for this project. Please wait...",
                                    life: 5000,
                                  });
                                } else {
                                  toast.current.show({
                                    severity: "warn",
                                    summary: "Status",
                                    detail: "No Gray Background for this project",
                                    life: 3000,
                                  });
                                }
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Download Gray Background">
                            <Button
                              icon="pi pi-download"
                              className="p-button-rounded  p-button-success"
                              aria-label="Download"
                              onClick={(e) => {
                                e.stopPropagation();
                                onDownloadZip("grayBackgroundDownload");
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Convert Gray Background" >
                            <Button
                              icon="pi pi-cloud-download"
                              className="p-button-rounded p-button-secondary"
                              aria-label="Upload"
                              onClick={(e) => {
                                e.stopPropagation();
                                if(allImages.length == 0){
                                  toast.current.show({
                                    severity: "warn",
                                    summary: "Status",
                                    detail: "No Floorplans for this project",
                                    life: 3000,
                                  });
                                }else{
                                  if(disableConvertImage && project?.convertImageStatus == 1){
                                    toast.current.show({
                                      severity: "warn",
                                      summary: "Status",
                                      detail: "Convert gray background is processing!",
                                      life: 3000,
                                    });
                                  }else{
                                    handleConvertGrayBackgroundCheck();
                                  }
                                }
                              }}
                            />
                          </Tooltip>
                        </div>
                      </div>
                      <Divider layout="horizontal">
                        <b></b>
                      </Divider>
                    </div>
                    {project?.projectData != undefined ? <>
                      <Divider layout="horizontal" className="mb-3 mt-3">
                      </Divider>
                      <div className="card p-fluid ">
                        <div className="field grid">
                          <label htmlFor="service" className="col-12 md:mb-0"></label>
                          <b>Data from calendar</b>
                        </div>
                        <Divider layout="horizontal" className="mb-3 mt-3">
                        </Divider>

                        <div style={{ whiteSpace: 'pre-wrap', fontSize: 'small'}} dangerouslySetInnerHTML={{ __html: project?.projectData }}>

                        </div>
                      </div> </> : ""
                    }
                    <div>
                      { currentUser?.userType !== USER_TYPE_OWNER && (feedbackForEmployee != "" || (project?.privateNoteForEmployee && project?.privateNoteForEmployee !="")) ?
                        <div className="card p-fluid ">
                        <div className="field grid">
                          <label htmlFor="service" className="col-12 md:mb-0">
                            <b> Feedback for Contractor</b>
                          </label>
                        </div>
                        {feedbackForEmployee != "" ? <>
                            <Divider layout="horizontal" className="mb-3 mt-3">
                            </Divider>
                            <div className="field grid">
                              <label htmlFor="" className="col-3">
                                Feedback:
                              </label>
                              <label htmlFor="" className="col-9 md:mb-0">
                                {feedbackForEmployee}
                              </label>
                            </div>
                          </>
                          : ("")}

                          {
                            project?.otherFeedbackForEmployee && project?.otherFeedbackForEmployee != "" ? <>
                              <Divider layout="horizontal" className="mb-3 mt-3">
                              </Divider>
                              <div className="field grid">
                                <label htmlFor="" className="col-3">
                                  Other feedback:
                                </label>
                                <label htmlFor="" className="col-9 md:mb-0">
                                  {project?.otherFeedbackForEmployee}
                                </label>
                              </div>
                            </> : ("")
                          }

                        {project?.privateNoteForEmployee && project?.privateNoteForEmployee != "" ? <>
                          <Divider layout="horizontal" className="mb-3 mt-3">
                          </Divider>
                          <div className="field grid">
                            <label htmlFor="" className="col-3">
                              Private note:
                            </label>
                            <label className="col-9">
                              {project.privateNoteForEmployee}
                            </label>
                          </div>
                        </> : ("")}
                      </div> : ("")
                      }
                    </div>
                   
                    
                    {currentUser?.userType !== USER_TYPE_OWNER ?
                      <div className="card p-fluid ">
                        <div className="field grid">
                          <label htmlFor="service" className="col-12 md:mb-0">
                            <b>Feedback</b>
                          </label>
                        </div>
                        <Divider layout="horizontal" className="mb-3 mt-3">
                        </Divider>
                        <CommentComponent height="23rem" projectComments={projectFeedbacks} feedback="true" project={project} fetchProject={() => { fetchProject(project.id) }} toast={toast} permissions={permissions}></CommentComponent>
                      </div> : ("")}
                  </div>

                  <div className="col-4">
                    <div
                      className="card p-fluid mb-3"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      <div className="field grid">
                        <h5 className="font-bold col-7 mt-3 pb-0">Add photo URL Link</h5>
                        <Button
                          type="button"
                          className="p-button-rounded p-button-success  col-4"
                          icon="pi pi-plus"
                          onClick={(e) => {
                            e.stopPropagation();
                            setVisibleAddDropboxLinkDialog(true);
                          }}
                        />
                      </div>
                      <Divider layout="horizontal" className="mt-2 mb-2">
                        <b></b>
                      </Divider>
                      {project?.dropboxPictureLink ? <>
                        <div className="field grid">
                          <p className="col-12">Pictures URL link: </p>
                        </div>
                        <Linkify>
                          {project?.dropboxPictureLink}
                        </Linkify></> : ("")}

                      {project?.e57DropboxLink ?
                        <><Divider layout="horizontal" className="mt-2 mb-2">
                          <b></b>
                        </Divider>
                          <div className="field grid">
                            <p className="col-12">E57 URL link:</p>
                          </div>
                          <Linkify>
                            {project?.e57DropboxLink}
                          </Linkify></> : ("")}
                    </div>
                    <div
                      className="card p-fluid mb-3"
                      style={{ overflowWrap: "anywhere" }}
                    >
                      <div className="field grid">
                        <h5 className="font-bold col-7 mt-3 pb-0">Company Info</h5>
                      </div>
                      {project?.company.url ? <>
                        <Divider layout="horizontal" className="mt-2 mb-2">
                          <b></b>
                        </Divider>
                        <div className="field grid">
                          <p className="col-12">URL link: </p>
                        </div>
                        <Linkify>
                          {project?.company?.url}
                        </Linkify></> : ("")}
                      {
                        companyFiles?.length > 0 ? <>
                          <Divider layout="horizontal" className="mt-2 mb-2">
                            <b></b>
                          </Divider>

                          <div className="field grid">
                            <p className=" col-12 mt-3 pb-0">Company Files: </p>
                            {
                              companyFiles?.map((item, index) => (
                                <>
                                  <p className="col-7 mt-1">
                                    {item.url.split("/").pop()}
                                  </p>
                                  <Button
                                    icon="pi pi-paperclip font-bold text-xl"
                                    className="p-button-rounded p-button-secondary p-button-text"
                                    aria-label="Upload"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      downloadFileUrl(item.url)
                                    }}
                                  />
                                </>
                              ))
                            }
                          </div>
                        </> : ""
                      }

                      {project?.company?.copyRight ?
                        <><Divider layout="horizontal" className="mt-2 mb-2">
                          <b></b>
                        </Divider>
                          <div className="field grid">
                            <p className="col-12">Copyright:</p>
                          </div>
                          <Linkify>
                            {project?.company?.copyRight}
                          </Linkify></> : ("")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="flex justify-content-between"></div>
              <div
                className="card"
                style={{
                  maxHeight: "80%",
                  minHeight: "80%",
                }}
              >
                <h5 className="font-bold">Comments</h5>
                <CommentComponent height="53rem" projectComments={projectComments} feedback="false" project={project} fetchProject={() => { fetchProject(project.id) }} toast={toast} permissions={permissions}></CommentComponent>

              </div>
            </div>
          </div>
        </Spin>
      </div>
      <Dialog
        header={
          modelCheck === "drawing" ? "Upload Drawings" : "Upload Floor Plans"
        }
        visible={displayResponsive}
        onHide={() => {
          onHide("displayResponsive");
        }}
        style={{ width: "50vw" }}
      >
        <Spin spinning={apiLoading}>
          <div>
            <Upload
              accept=".png, .jpg, .jpeg, .pdf, .fml, .doc, .docx, .xls, .xlsx, .dwg, .zip"
              listType="picture-card"
              fileList={fileList}
              multiple
              onChange={handleChange}
              beforeUpload={beforeUpload}
              showUploadList={{ showRemoveIcon: false, showPreviewIcon: false }}
            >
              {fileList.length >= 8 ? uploadButton : uploadButton}
            </Upload>
          </div>
        </Spin>
      </Dialog>
      <Dialog
        className="min-h-full max-h-full min-w-full max-w-full"
        header="Files Detail"
        visible={visibleRight}
        footer={null}
        onHide={() => {
          setGetFileView(null);
          setVisibleRight(false);
          setActiveIndex(0);
          setImageHeader(null);
        }}
      >
        <Spin spinning={apiLoading}>
          <div className="flex gap-4 pt-3">
            <div className="card" style={{ width: "25%" }}>
              <div className="flex flex-column gap-2">
                {getFileView === "images" ? (
                  <div>
                    <Button
                      type="button"
                      label="Delete All"
                      style={{ backgroundColor: "#037eea", float: "right" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmPopup({
                          target: e.currentTarget,
                          message: "Do you want to delete All Floor Plans?",
                          icon: "pi pi-info-circle",
                          acceptClassName: "p-button-danger",
                          accept: () => deleteAllFloor(getFileView),
                        });
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <ListBox
                    options={Object.entries(getDataSource(getFileView))}
                    listClassName="overflow-y-auto"
                    listStyle={{ height: "70vh" }}
                    filter
                    optionLabel={(e) => e.fileName}
                    itemTemplate={(option) => {
                      return (
                        <React.Fragment>
                          <Tooltip title={moment(option[1].createdOn).format("LLL")} zIndex={9999}>

                            <div className="flex justify-content-between align-items-center">
                              <div>
                                {option[1].fileName.split("/").pop()}
                              </div>
                              <div>
                                <Button
                                  icon="pi pi-pencil"
                                  className="p-button-rounded p-button-text"
                                  aria-label="Cancel"
                                  style={{ zIndex: 9999 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setVisibleChangeImageNameDialog(true);
                                    setImageData(option[1]);
                                  }}
                                />
                                <Button
                                  icon="pi pi-times"
                                  className="p-button-rounded p-button-danger p-button-text"
                                  aria-label="Cancel"
                                  style={{ zIndex: 9999 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    confirmPopup({
                                      target: e.currentTarget,
                                      message:
                                        "Do you want to delete this record?",
                                      icon: "pi pi-info-circle",
                                      acceptClassName: "p-button-danger",
                                      accept: () =>
                                        deletePictures(
                                          getFileView,
                                          option[0],
                                          project
                                        ),
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </Tooltip>
                        </React.Fragment>
                      );
                    }}
                    onChange={(e) => {
                      const imgsList = Object.entries(
                        getDataSource(getFileView)
                      );
                      for (let index = 0; index < imgsList.length; index++) {
                        const element = imgsList[index];
                        if (element[1] === e.value[1]) {
                          setActiveIndex(index);
                          setImageHeader(e.value[1].fileName.split("/").pop());
                        }
                      }
                    }}
                  />

                </div>
              </div>
            </div>
            <div className="card" style={{ width: "75%", height: "65%" }}>
              <Galleria
                header={
                  <div style={{ paddingBottom: "10px" }}>
                    <h4>{imageHeader}</h4>
                  </div>
                }
                value={Object.entries(getDataSource(getFileView))}
                circular
                showItemNavigators
                item={itemTemplateGalleria}
                responsiveOptions={responsiveOptions}
                showThumbnails={false}
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
              />
            </div>
          </div>
        </Spin>
      </Dialog>
      <AssignToContractorDialog
        visible={visibleContractorDialog}
        setVisible={changeVisibleContractorDialogState}
        project={project}
      />
      <ChangeImageNameDialog
        visible={visibleChangeImageNameDialog}
        setVisible={changeVisibleImageDialogState}
        image={imageData}
      />
      <AssigntoqualityCheckerDialog
        visible={visibleQualityCheckerDialog}
        setVisible={changeVisibleQualityCheckerDialogState}
        project={project}
      />
      <AssignToManagerDialog
        visible={visibleManagerDialog}
        setVisible={changeVisibleManagerDialogState}
        project={project}
      />
      <CustomerRequestChangeDialog
        visible={visibleCustomerRequestChangeDialog}
        setVisible={changeVisibleCustomerRequestChangeDialogState}
        project={project}
      />
       <FeedbackDialog
        visible={visibleFeedbackDialog}
        setVisible={changeVisibleFeedbackDialogState}
        project={project}
        currentUser={currentUser}
      />

      <AddDropboxLinkDialog
        visible={visibleAddDropboxLinkDialog}
        setVisible={changeVisibleAddDropboxLinkDialog}
        project={project}
        currentUser={currentUser}
      />

      <AddFloorPlannerIdDialog
        visible={visibleAddFPDialog}
        setVisible={changeVisibleAddFPDialog}
        project={project}
      />

      <Dialog
        className="min-h-full max-h-full min-w-full"
        header="Project Edit"
        visible={projectEditDialouge}
        onHide={() => {
          setProjectEditDialouge(false);
        }}
      >
        <UpdateProjectForm
          project={project}
          permissions={permissions}
          setVisible={changeVisibleNewProjectDialogState}
        />
      </Dialog>
      <Dialog
        style={{ width: "35vw", height: "30vw" }}
        header="Set Export Parameters"
        visible={showExportParameters}
        onHide={() => {
          setShowExportParameters(false);
        }}
      >
        <ExportProjectParams
          projectImport={afterProjectImport}
          floorPlannerProjectId={floorPlannerProjectId}
          projectId={project?.id}
        />
      </Dialog>
      <Dialog
        className="min-h-full max-h-full max-w-full min-w-full"
        header={
          generatedPdfPreview
            ? generatedPdfPreview.split('/').pop()
            : "PDF Preview"
        }
        footer={null}
        visible={pdfViewerVisible}
        onHide={() => {
          setPdfViewerVisible(false);
          setGeneratedPdfPreview(null);
        }}
      >
        {generatedPdfPreview && generatedPdfPreview !== null ? (
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              width: "100%",
              paddingTop: "56.25%",
            }}
          >
            <iframe
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
              src={generatedPdfPreview}
            />
          </div>
        ) : (
          ""
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default ProjectEdit;
