import { DatePicker, Form, Select } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import React, { useRef, useState } from "react";
import "./style.scss";
import {
    usersStatistic
} from "../../../apis/ProjectInvoiceApi";

import { getUsersByRoles } from "../../../apis/SettingApis";

import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { useEffect } from "react";

const { Option } = Select;
const Statistic = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const [form] = Form.useForm();
    const toast = useRef(null);
    const [uniqueUsers, setUniqueUsers] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const fetchUsers = () => {
        setApiLoading(true);
        getUsersByRoles("all")
            .then((res) => {
                if (res.status === 200) {
                    let users = [...new Set(res.data.map(item => item.username))];
                    users.sort();
                    users = users.map(user => ({ label: user, value: user }));
                    users.unshift({ label: 'All Members', value: 'all' });
                    setUniqueUsers(users);
                    setApiLoading(false);
                } else {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: res.response.data,
                        life: 3000,
                    });
                    setApiLoading(false);
                }
            })
            .catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const submit = () => {
        form.validateFields().then((values) => {
            props.setLoading(true);
            let newValues = {
                startDate: `${moment(values.startDate).format("DD-MM-yyyy hh:mm a")}`,
                endDate: `${moment(values.endDate).format("DD-MM-yyyy hh:mm a")}`,
                users: values.users ? values.users?.join(",") : "all",
                format: values.format
            };
            usersStatistic(newValues)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("statistic data: ", JSON.stringify(res));
                        let data = transformData(res.data);
                        setFilteredData(data);
                        props.setLoading(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: res.response.data,
                            life: 3000,
                        });
                        props.setLoading(false);
                    }
                })
                .catch((e) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    props.setLoading(false);
                });
        });
    };

    const getChildClass = (index) => {
        switch (index) {
            case 0: return "bg-green";
            case 1: return "bg-orange";
            case 2: return "bg-yellow";
            case 3: return "bg-blue";
            default: return ''
        }
    }

    /**
     * Transform from list data to list data can use in data table
     * @param {*} inputData 
     * @returns 
     */
    const transformData = (inputData) => {
        let result = []
        let index = 0;
        for (let dataByUser of inputData) {
            result.push({ user: dataByUser[0][0].user, className: "bg-header-user" })
            for (let dataByTime of dataByUser) {
                if (form.getFieldsValue().format == "PER_DAY" || form.getFieldsValue().format == "PER_WEEK") {
                    result.push({ user: dataByTime[0].date, className: "bg-header-time" })
                }

                let className = getChildClass(index)
                for (let index = 0; index < dataByTime.length; index++) {
                    let dataByHouseType = dataByTime[index];
                    if (index == dataByTime.length - 1 && dataByTime.length > 1) {
                        result.push({ ...dataByHouseType, user: dataByHouseType.houseType, className: className + " border-style" })
                        continue;
                    }
                    result.push({ ...dataByHouseType, user: dataByHouseType.houseType, className: className })
                }
                if (dataByTime.length > 1) {
                    result.push(calTotalObject(dataByTime, className))
                }
                index++;
                if (index == 4) {
                    index = 0
                }
            }
        }

        console.log("Transform Data" + JSON.stringify(result))
        return result;
    }

    /**
     * Call total from house type A,B,C,D
     * @param {*} data 
     * @param {*} className 
     * @returns 
     */
    const calTotalObject = (data, className) => {
        if (typeof data !== 'object' || data === null) {
            return data;
        }
        const totalObject = Object.fromEntries(Object.keys(data[0]).map(key => [key, 0]));
        totalObject["feedbackAsEmployee"] = Object.fromEntries(Object.keys(data[0]["feedbackAsEmployee"]).map(key => [key, 0]))
        totalObject["feedbackAsQualityChecker"] = Object.fromEntries(Object.keys(data[0]["feedbackAsQualityChecker"]).map(key => [key, 0]))
        totalObject["feedbackAsManager"] = Object.fromEntries(Object.keys(data[0]["feedbackAsManager"]).map(key => [key, 0]))

        data.forEach(obj => {
            for (const key in obj) {
                if (key != "user") {
                    if (key == "feedbackAsEmployee" || key == "feedbackAsQualityChecker" || key == "feedbackAsManager") {
                        for (const childKey in obj[key]) {
                            totalObject[key][childKey] += obj[key][childKey];
                        }
                        continue;
                    }
                    totalObject[key] += obj[key];
                }
            }
        });

        return { ...totalObject, user: "Total", className: className + " total-style" };
    }

    const headerGroup = <ColumnGroup>
        <Row>
            <Column header="Team member" rowSpan={2} style={{ minWidth: "120px" }} />
            <Column header="Num Projects" colSpan={3} />
            <Column header="Num Comments" colSpan={2} />
            <Column header="Feedback as Contractor" colSpan={6} />
            <Column header="Feedback as Measure Guy" colSpan={7} />
            <Column header="Feedback as Quality Checker" colSpan={6} />
        </Row>

        <Row>
            <Column header="Contractor" />
            <Column header="Quality Checker" />
            <Column header="Final Checker" />
            <Column header="Contractor" />
            <Column header="Final Checker" />

            <Column header="Good" />
            <Column header="M2 not correct" />
            <Column header="h<1,50m" />
            <Column header="Parts missing" />
            <Column header="NEN rules wrong" />
            <Column header="Other" />

            <Column header="Good" />
            <Column header="Pictures Missing" />
            <Column header="Extra info missing" />
            <Column header="Scan corrupt" />
            <Column header="Wrong project details" />
            <Column header="Other" />

            <Column header="Good" />
            <Column header="M2 not correct" />
            <Column header="h<1,50m missing" />
            <Column header="Table in report wrong" />
            <Column header="NEN rules wrong" />
            <Column header="Other" />
        </Row>
    </ColumnGroup>;

    const getRowClassName = (rowData) => {
        return rowData.className;
    };
    return (
        <React.Fragment>
            <div>
                <Toast ref={toast} />
                <div className="card" style={{ height: "72vh" }}>
                    <div className="grid" style={{ height: "70vh" }}>
                        <div className="col-3 flex align-items-center justify-content-center">
                            <div>
                                <Form form={form}
                                    size="middle"
                                    className="custom-form-design"
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 14 }}
                                    onFinish={submit}
                                >
                                    <Form.Item
                                        name="startDate"
                                        label="Start Date"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: "Start Date is required.",
                                            },
                                        ]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                    <Form.Item
                                        name="endDate"
                                        label="End Date"
                                        required
                                        rules={[
                                            {
                                                required: true,
                                                message: "End Date is required.",
                                            },
                                        ]}
                                    >
                                        <DatePicker />
                                    </Form.Item>
                                    {uniqueUsers.length > 0 ?
                                        (<Form.Item name="users" label={uniqueUsers.length > 0 ? "Select member" : ""}>
                                            <Select
                                                defaultValue={"all"}
                                                isClearable
                                                style={{ width: "150px" }}
                                                options={uniqueUsers}
                                                mode={"multiple"}
                                            >
                                            </Select>
                                        </Form.Item>)
                                        : ("")
                                    }
                                    <Form.Item name="format" label="Format">
                                        <Select
                                            defaultValue={"TOTAL"}
                                            isClearable
                                            style={{ width: "150px" }}
                                        >
                                            <Option value="TOTAL">Total</Option>
                                            <Option value="PER_DAY">Per day</Option>
                                            <Option value="PER_WEEK">Per week</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item style={{ alignItems: "center" }}>
                                        <Button type="submit" style={{ float: "right" }}>
                                            Generate
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                        <div className="col-1">
                            <Divider layout="vertical"></Divider>
                        </div>
                        <div className="col-8">
                            <Toolbar
                                className="mb-4"
                                left={() => {
                                    return (
                                        <React.Fragment>
                                            <div>
                                                <h3 className="m-0">User Statistic Data</h3>
                                            </div>
                                        </React.Fragment>
                                    );
                                }}
                            ></Toolbar>
                            <div className="treetable-responsive-demo">
                                <DataTable
                                    value={filteredData}
                                    dataKey="id"
                                    responsiveLayout="scroll"
                                    selectionMode="single"
                                    className="overflow-y-auto"
                                    style={{ maxHeight: "50vh" }}
                                    headerColumnGroup={headerGroup}
                                    size={"small"}
                                    rowClassName={getRowClassName}
                                >
                                    <Column field="user" className="header-user">
                                    </Column>

                                    <Column field="projectsAsEmployee" >
                                    </Column>
                                    <Column field="projectsAsQualityChecker" >
                                    </Column>
                                    <Column field="projectsAsManager" >
                                    </Column>

                                    <Column field="commentsAsEmployee" >
                                    </Column>
                                    <Column field="commentsAsQualityChecker" >
                                    </Column>

                                    <Column field="feedbackAsEmployee.GOOD" >
                                    </Column>
                                    <Column field="feedbackAsEmployee.M2_NOT_CORRECT" >
                                    </Column>
                                    <Column field="feedbackAsEmployee.H_LESS_THAN_150CM" >
                                    </Column>
                                    <Column field="feedbackAsEmployee.PARTS_MISSING" >
                                    </Column>
                                    <Column field="feedbackAsEmployee.NEN_RULES_WRONG" >
                                    </Column>
                                    <Column field="feedbackAsEmployee.OTHER" >
                                    </Column>

                                    <Column field="feedbackAsManager.GOOD">
                                    </Column>
                                    <Column field="feedbackAsManager.PICTURES_MISSING">
                                    </Column>
                                    <Column field="feedbackAsManager.EXTRA_INFORMATION_MISSING">
                                    </Column>
                                    <Column field="feedbackAsManager.SCAN_CORRUPT">
                                    </Column>
                                    <Column field="feedbackAsManager.WRONG_PROJECT_DETAILS">
                                    </Column>
                                    <Column field="feedbackAsManager.OTHER">
                                    </Column>

                                    <Column field="feedbackAsQualityChecker.GOOD">
                                    </Column>
                                    <Column field="feedbackAsQualityChecker.M2_NOT_CORRECT">
                                    </Column>
                                    <Column field="feedbackAsQualityChecker.H_LESS_THAN_150CM_MISSING">
                                    </Column>
                                    <Column field="feedbackAsQualityChecker.TABLE_IN_REPORT_WRONG">
                                    </Column>
                                    <Column field="feedbackAsQualityChecker.TABLE_IN_REPORT_WRONG">
                                    </Column>
                                    <Column field="feedbackAsQualityChecker.OTHER">
                                    </Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Statistic
