import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const projectInvoice = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/projects/invoice", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const projectInvoiceDownload = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/projects/download/invoice", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "blob",
  });
};

export const usersInvoice = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/users/invoice", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const usersInvoiceDownload = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/users/download/invoice", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "blob",
  });
};

export const usersStatistic = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/projects/statistic", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};
