import { Button, Form, Radio, Switch, message } from "antd";
import React, { useState } from "react";
import {
  exportBackgroundProjectOnFloorPlanner,
  exportProjectOnFloorPlanner,
} from "../../../../apis/floorPlannerApis";
import "./params2dForm.scss";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";

const BackgroundParams = (props) => {
  const [fileFormat, setFileFormat] = useState("jpg");
  const [includeFML, setIncludeFML] = useState(false);
  const [form] = Form.useForm();
  const toast = useRef(null);

  const onFileFormatChange = (e) => {
    setFileFormat(e.target.value);
  };

  const exportProjectonnFloorPlanner = () => {
    exportProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
  };

  const onFinish = (values) => {
    exportBackgroundProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId,
      includeFML
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
  };

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const onSwitchChange = (checked) => {
    setIncludeFML(checked);
  };

  return (
    <React.Fragment>
      <Form form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Form.Item label="Include FML" value={includeFML}>
          <Switch className="radio-btns" onChange={onSwitchChange} />
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              marginTop: "10rem",
              width: "80%",
              marginLeft: "28%",
            }}
            type="primary"
            onClick={(e) => {
              form.validateFields().then(() => {
                confirmDialog({
                  message: "Are you sure you want to import?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => onFinish(form.getFieldsValue()),
                });
              }).catch(errorInfo => {
                toast.current.show({
                  severity: "info",
                  summary: "Status",
                  detail: errorInfo,
                  life: 5000,
                });
              });
            }
            }  
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Button
        type="primary"
        style={{
          width: "57%",
          marginLeft: "20%",
        }}
        onClick={(e) => {
          confirmDialog({
            message: "Are you sure you want to import?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => exportProjectonnFloorPlanner(),
          });
        }
        }
      >
        Import total project
      </Button>
    </React.Fragment>
  );
};

export default BackgroundParams;
