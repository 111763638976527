import React, { useEffect, useRef, useState } from "react";

const Config3D = () => {
    const iframeStyle = {
        width: "100%",
        height: "100%",
        border: "none",
        minHeight: "700px"
    };

    return (
        <iframe style={iframeStyle} src="https://file-processing.floorio.nl/" title="description"></iframe>
    );
};

export default Config3D;