import { LockOutlined } from "@ant-design/icons";
import { Form, Input, message, Spin } from "antd";
import classNames from "classnames";
import { Avatar } from "primereact/avatar";
import { Button, Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { changePassword } from "../../apis/SettingApis";
import { checkUserRole } from "../../utils/constants";
import topLogo from "./../../resources/logo.png";

export const AppTopbar = (props) => {
  const [visiblePasswordDialog, setVisiblePasswordDialog] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [form] = Form.useForm();
  const histroy = useHistory();
  const toast = useRef(null);
  const op = useRef(null);

  const onFinish = (values) => {
    form.validateFields().then((values) => {
      setApiLoading(true);
      values["username"] = currentUser.username;
      changePassword(values)
        .then((res) => {
          if (res.status === 200) {
            setVisiblePasswordDialog(false);
            setApiLoading(false);
            props.handleSignOut();
            message.info(
              "Password changed successfully. Login again with your new password"
            );
            histroy.push("/");
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    });
  };

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="layout-topbar">
        <Link to="/dashboard" className="layout-topbar-logo">
          <img src={topLogo} height="50" alt="logo" />
          {/* <span>Floor Planner</span> */}
        </Link>

        <button
          type="button"
          className="p-link  layout-menu-button layout-topbar-button"
          onClick={props.onToggleMenuClick}
        >
          <i className="pi pi-bars" />
        </button>

        {/* <button
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={props.onMobileTopbarMenuClick}
      >
        <i className="pi pi-ellipsis-v" />
      </button> */}

        <ul
          className={classNames(
            "align-items-center layout-topbar-menu lg:flex origin-top"
            // {
            //   "layout-topbar-menu-mobile-active": props.mobileTopbarMenuActive,
            // }
          )}
        >
          <li>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                marginTop: "-3",
              }}
            >
              <div className="mr-2">
                {currentUser && currentUser.name ? currentUser.name : ""}
              </div>
              <small className="mr-2">{currentUser && currentUser.userType ? currentUser.userType : ""}</small>
            </div>
          </li>
          <li>
            <Avatar
              label={currentUser?.name?.charAt(0).toUpperCase()}
              className="mr-2"
              size="large"
              shape="circle"
              onClick={(e) => op.current.toggle(e)}
              aria-haspopup
              aria-controls="overlay_panel"
              style={{
                backgroundColor: checkUserRole(currentUser),
                color: "#ffffff",
              }}
            />

            <OverlayPanel ref={op} className="overlaypanel-demo">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Button
                  icon="pi pi-user-edit"
                  label="Change Password"
                  className="p-button-secondary p-button-text"
                  onClick={(e) => {
                    setVisiblePasswordDialog(true);
                  }}
                ></Button>
                <Button
                  icon="pi pi-sign-out"
                  label="Logout"
                  className="p-button-secondary p-button-text"
                  onClick={(e) => {
                    props.handleSignOut();
                    e.preventDefault();
                  }}
                ></Button>
              </div>
            </OverlayPanel>
          </li>
        </ul>
      </div>
      <Dialog
        header="Change Password"
        visible={visiblePasswordDialog}
        modal
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "75vw" }}
        onHide={() => {
          form.resetFields();
          setVisiblePasswordDialog(false);
        }}
      >
        <Spin spinning={apiLoading}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <div>
              <Form
                labelAlign="right"
                name="change_password"
                className="login-form"
                onFinish={onFinish}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                form={form}
              >
                <Form.Item
                  style={{ textAlignLast: "auto" }}
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Please input password",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    // maxLength={60}
                    // style={{ marginLeft: 54 }}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  ></Input.Password>
                </Form.Item>
                <Form.Item
                  style={{ textAlignLast: "auto" }}
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          "The two passwords that you entered do not match!"
                        );
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    // maxLength={60}
                    // style={{ marginLeft: 54 }}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                  ></Input.Password>
                </Form.Item>
                <PrimeBtn
                  style={{
                    backgroundColor: "#037eea",
                    borderColor: "#037eea",
                    float: "right",
                  }}
                  type="submit"
                  label="Save Changes"
                />
              </Form>
            </div>
          </div>
        </Spin>
      </Dialog>
    </React.Fragment>
  );
};
