import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllTilesCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/getAllTilesCount/", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getTileData = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/getTileData/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const exportAllProject = (tileName, startIdx, endIdx, packId) => {
  const queryParams = new URLSearchParams({
    startIdx: startIdx,
    endIdx: endIdx,
    packId: packId,
  }).toString();
  const url = `${APP_CONTEXT_URL}/v1/downAllProject/${tileName}?${queryParams}`;

  return axios.get(url, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "blob",
  });
};
