import axios from "axios";
// import React from 'react';
// import Message from './../components/ui-kit/message';
import { setLoading, resetLoading } from "./../actions/app";
import { resetEntities } from "./../actions/data";
import { dispatch } from "./../store/index";
import { message } from "antd";
import { getAuthToken, setAuthToken, clearAuth } from "./../utils/authToken";
import { setSessionIdentifier } from "./../utils/sessionIdentifier";
import doSessionManagement from "./../utils/sessionManager";
import { APP_CONTEXT_URL } from "./../utils/constants";

/**
 *  APP API Service
 */
const request = async (options) => {
  let {
    url,
    method,
    data,
    context = "app",
    loadingText = "Loading..",
  } = options;
  try {
    dispatch(setLoading({ text: loadingText, status: true }, context));

    if (url === APP_CONTEXT_URL + "/v1/login") {
      let bodyFormData = new FormData();
      bodyFormData.set("username", data["username"]);
      bodyFormData.set("password", data["password"]);
      const response = await axios({
        method: method,
        url: url,
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        credentials: "include",
      });
      if (response.status === 200) {
        setAuthToken(response.data);
        url = APP_CONTEXT_URL + "/v1/user";
        setSessionIdentifier(true);
        doSessionManagement(response.data);
      } else if (response.response.status === 401) {
        let ex = {
          response: {
            message: "Invalid username or password, please try again",
          },
        };
        throw ex;
      } else {
        let errorMessage =
          response.response.data && response.response.data.message
            ? response.response.data.message
            : response.response.statusText;
        let ex = {
          response: {
            message: "Error " + response.response.status + " " + errorMessage,
          },
        };
        throw ex;
      }
    }
    const response = await axios({
      method: method,
      url: url,
      data: data,
      headers: {
        Authorization: getAuthToken(),
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
      },
      credentials: "include",
    });
    if (
      response.status !== 200 &&
      response.response &&
      (response.response.status === 401 ||
        response.response.status === 404 ||
        response.response.status === 409 ||
        response.response.status === 500)
    ) {
      let errorMessage = response.response.data.message
        ? response.response.data.message
        : response.response.statusText;
      let ex = {
        response: {
          message: "Error " + response.response.status + " " + errorMessage,
        },
      };
      clearAuth();
      dispatch(resetEntities());
      window.appHistory && window.appHistory.push("/");
      if (url === APP_CONTEXT_URL + "/v1/relogin") {
        //logout
        clearAuth();
        dispatch(resetEntities());
        window.appHistory && window.appHistory.push("/");
      }
      throw ex;
    }
    if (url === APP_CONTEXT_URL + "/v1/relogin" && response.status === 200) {
      setAuthToken(response.headers["authorization"]);
      return response;
    }
    return response.data;
  } catch (e) {
    const { data = {} } = e && e.response;
    // let { message = e.message } = data;
    // if (!message) message = e.response.message;
    // message.success({message})
    // return e;
    let { messages = e.messages } = data;
    if (!messages) messages = e.response.message;
    message.error(messages);
    return e;
  } finally {
    dispatch(resetLoading(context));
  }
};

export default request;
