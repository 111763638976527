import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Spin,
  InputNumber,
  Radio,
  Space,
} from "antd";
import moment from "moment";
import { Button as PrimeBtn } from "primereact/button";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { getAllAgencies } from "../../../apis/AgencyApis";
import { updateProject } from "../../../apis/ProjectApis";
import { Toast } from "primereact/toast";
import AddAgencyDialog from "../../agency/dialog/addagencydialog";
import informationImage from "../../../resources/information.png";
import { Dialog } from "primereact/dialog";
import { WONING_MEDIA_ID, conditionForUpdateAdjustment } from "../../../utils/constants";

const UpdateProjectForm = (props) => {
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState(null);

  const [product, setProduct] = useState(false);
  const [visibleAgencyDialog, setVisibleAgencyDialog] = useState(false);
  const [isfurnished, setIsFurnished] = useState(false);
  const [informationDialog, setInformationDialog] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [form] = Form.useForm();
  const toast = useRef(null);
  const { Option } = Select;

  const loadAllAgencies = () => {
    setApiLoading(true);
    getAllAgencies()
      .then((res) => {
        if (res.status === 200) {
          setAgencies(res.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  };

  useEffect(() => {
    loadAllAgencies();
    if (props?.project) {
      setProduct(props.project.product);
      setIsFurnished(props.project.furnishedType);
      form.setFieldsValue({ agency: props.project.agency.id });
      form.setFieldsValue({ measurementType: props.project.measurementType });
      form.setFieldsValue({ houseType: props.project.houseType });
      form.setFieldsValue({ product: props.project.product });
      form.setFieldsValue({ city: props.project.city });
      form.setFieldsValue({ street: props.project.street });
      form.setFieldsValue({ number: props.project.number });
      form.setFieldsValue({ postalCode: props.project.postalCode });
      form.setFieldsValue({ ndd: props.project.ndd });
      form.setFieldsValue({ furnishedType: props.project.furnishedType });
      form.setFieldsValue({ projectOption: props.project.projectOption });
      form.setFieldsValue({
        measurementDate: moment(props.project.measurementDate, "YYYY-MM-DD"),
      });
      if(props.project.reportMeasurementDate){
        form.setFieldsValue({
          reportMeasurementDate: moment(props.project.reportMeasurementDate, "YYYY-MM-DD"),
        });
      }
      form.setFieldsValue({
        adjustmentDate: props.project.adjustmentDate
          ? moment(props.project.adjustmentDate, "YYYY-MM-DD")
          : "",
      });
      form.setFieldsValue({ adjustmentRate: props.project.adjustmentRate });
      form.setFieldsValue({ adjustment: props.project.adjustment });
      form.setFieldsValue({ adjustmentMadeBy: props.project.adjustmentMadeBy });
      form.setFieldsValue({ report: props.project.report });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setShowReport(props.project?.company?.id === WONING_MEDIA_ID ? false: true)
  }, [props.project]);

  const agencyOptions = (agencies || []).map((r) => (
    <Option key={r.id} value={r.id}>
      {r.name}
    </Option>
  ));

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setApiLoading(true);
      const { agency, measurementDate, reportMeasurementDate, ...otherValues } = values;
      const projectValues = {
        id: props.project && props.project.id ? props.project.id : null,
        measurementDate: moment(measurementDate).format("YYYY-MM-DD"),
        reportMeasurementDate: moment(reportMeasurementDate).format("YYYY-MM-DD"),
        agency: { id: agency },
        report: values.report != null ? values.report : props.project.report,
        ...otherValues,
      };
      updateProject(projectValues)
        .then((res) => {
          if (res.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Status",
              detail: "Project Edit Successfully",
              life: 3000,
            });
            setApiLoading(false);
            props.setVisible(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    });
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    // return current && current < moment().endOf("day");
    // can not select saturday and sunday
    return moment(current).day() === 0 || moment(current).day() === 6;
  };

  const changeVisibleAgencyDialog = (bool) => {
    setSelectedAgency(false);
    setApiLoading(false);
    if (bool === false) {
      loadAllAgencies();
    }
    setVisibleAgencyDialog(bool);
  };

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <Spin spinning={apiLoading}>
        <div
          style={{ display: "flex", marginTop: "30px", marginBottom: "20px" }}
        >
          <Form
            form={form}
            size="middle"
            className="custom-form-design"
            onFinish={handleSubmit}
            wrapperCol={{ span: 10 }}
            labelCol={{ span: 10 }}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  name="product"
                  label="Product"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Product is required.",
                    },
                  ]}
                >
                  <Select
                    onChange={(e) => {
                      form.setFieldsValue({
                        houseType: null,
                        furnishedType: null,
                      });
                      setProduct(e);
                    }}
                    style={{ width: 150 }}
                    placeholder="Select"
                    dropdownStyle={{ zIndex: "9999" }}
                  >
                   
                    {
                      agency?.format !== "Autocad" || agency == null ?
                        <Option value="BOG">BOG</Option> : ""
                    }

                    <Option value="House">House</Option>
                  </Select>
                </Form.Item>
                {showReport ?
                  <Form.Item
                    name="report"
                    label="Report"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please Enter Report",
                      },
                    ]}
                  >
                    <Select
                      style={{ width: 150 }}
                      placeholder="Select"
                      dropdownStyle={{ zIndex: "9999" }}
                    >
                      <Option value="Yes">Yes</Option>
                      <Option value="No">No</Option>
                    </Select>
                  </Form.Item> : ("")}
                <Form.Item
                  name="report"
                  label="Report"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Report",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    placeholder="Select"
                    dropdownStyle={{ zIndex: "9999" }}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="city"
                  label="City"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter City",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="street"
                  label="Street"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Street",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="number"
                  label="Number"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Number",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="postalCode"
                  label="Post Code"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Enter Post Code",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Row>
                  <Col span={20}>
                    <Form.Item
                      name="agency"
                      label="Agency"
                      wrapperCol={{ span: 11 }}
                      labelCol={{ span: 12 }}
                      rules={[
                        {
                          required: true,
                          message: "Please select agency from list",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        dropdownStyle={{ zIndex: "9999" }}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                          let findAgency = agencies.find(t => t.id == e);
                          setAgency(findAgency);
                          if (findAgency.format === "Autocad" && product === "BOG") {
                            form.setFieldsValue({ product: null });
                          }
                        }}
                      >
                        {agencyOptions}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <PrimeBtn
                      className="p-button-rounded my-auto ml-1"
                      type="button"
                      icon="pi pi-plus"
                      style={{
                        backgroundColor: "#037eea",
                        borderColor: "#037eea",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setApiLoading(true);
                        setVisibleAgencyDialog(true);
                      }}
                    />
                  </Col>
                </Row>
                {conditionForUpdateAdjustment(props.permissions) ?
                  <>
                    <Form.Item name="adjustmentRate" label="Adjusted Price">
                      <InputNumber />
                    </Form.Item>
                    <Form.Item name="adjustment" label="Adjustment">
                      <Radio.Group defaultValue={"None"}>
                        <Radio value="Small">Small</Radio>
                        <Radio value="Big">Big</Radio>
                        <Radio value="Remake">Remake</Radio>
                        <Radio value="Without Cost">Without Cost</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </> : ""}

              </Col>
              <Col span={12}>
                <Form.Item label="NDD">
                  <Space>
                    <Form.Item
                      name="ndd"
                      required
                      rules={[
                        {
                          required: true,
                          message: "NDD is required.",
                        },
                      ]}
                    >
                      <Select
                        style={{ width: 150 }}
                        placeholder="Select"
                        dropdownStyle={{ zIndex: "9999" }}
                      >
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                    <div
                      value="Yes"
                      style={{
                        marginLeft: "10px",
                        fontSize: "large",
                        color: "blue",
                      }}
                      onClick={() => setInformationDialog(true)}
                      class="pi pi-info-circle"
                    />
                  </Space>
                </Form.Item>
                <Form.Item
                  name="furnishedType"
                  label="Is Furnished"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please specify isFurnished?",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    dropdownStyle={{ zIndex: "9999" }}
                    onChange={(e) => {
                      form.setFieldsValue({ houseType: null });
                      setIsFurnished(e);
                    }}
                    placeholder="Select"
                  >
                    <Option
                      disabled={product === "BOG" ? true : false}
                      value="Yes"
                    >
                      Yes
                    </Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="houseType"
                  label="House Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select House Type",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    dropdownStyle={{ zIndex: "9999" }}
                    placeholder="Select"
                  >
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                    <Option value="C">C</Option>
                    <Option
                      disabled={product === "BOG" ? true : false}
                      value="D"
                    >
                      D
                    </Option>
                    <Option
                      disabled={
                        product === "BOG"
                          ? true
                          : isfurnished === "Yes" && product === "House"
                          ? true
                          : false
                      }
                      value="E"
                    >
                      E
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="projectOption"
                  label="Options"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Project Option",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    placeholder="Select"
                    dropdownStyle={{ zIndex: "9999" }}
                  >
                    <Option value="2D">2D</Option>
                    <Option value="3D">3D</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="measurementType"
                  label="Measurement Type"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Measurement Type",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 150 }}
                    placeholder="Select"
                    dropdownStyle={{ zIndex: "9999" }}
                  >
                    <Option value="A">A</Option>
                    <Option value="B">B</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  name="measurementDate"
                  label="Measurement Date"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please Select Measurement Date",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    popupStyle={{ zIndex: "9999" }}
                  />
                </Form.Item>
                <Form.Item
                  name="reportMeasurementDate"
                  label="Report Measurement Date"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Report Measurement Date",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    popupStyle={{ zIndex: "9999" }}
                  />
                </Form.Item>
                <Form.Item name="adjustmentDate" label="Adjustment Date">
                  <DatePicker
                    disabledDate={disabledDate}
                    popupStyle={{ zIndex: "9999" }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimeBtn
                style={{
                  backgroundColor: "#037eea",
                  borderColor: "#037eea",
                  float: "right",
                  marginRight: "1rem",
                }}
                type="submit"
                label="Submit"
              />
            </div>
          </Form>
        </div>
      </Spin>

      <AddAgencyDialog
        visible={visibleAgencyDialog}
        setVisible={changeVisibleAgencyDialog}
        agency={selectedAgency ? selectedAgency : null}
      />
      <Dialog
        header="Information"
        style={{ width: "40vw" }}
        visible={informationDialog}
        onHide={() => setInformationDialog(false)}
      >
        <img style={{ width: "30vw" }} src={informationImage} />
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateProjectForm;
