import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Spin } from "antd";
import ProjectsInvoice from "./projectsinvoice";
import UsersInvoice from "./usersinvoice";
import { useSelector } from "react-redux";
import {
  GENERATES_PROJECTS_INVOICE,
  GENERATES_USERS_INVOICE,
  SHOW_STATISTIC
} from "../../../utils/constants";
import Statistic from "./statistic";

const Invoice = () => {
  const [apiLoading, setApiLoading] = useState(false);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const [tabs, setTabs] = useState([]);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const identifyTabs = (permissions) => {
    const newTabsArray = [];
    permissions.forEach((permission) => {
      if (permission.name === GENERATES_PROJECTS_INVOICE) {
        newTabsArray.push({
          title: "Projects Invoice",
          content: (
            <ProjectsInvoice
              loading={apiLoading}
              setLoading={(e) => {
                setApiLoading(e);
              }}
            />
          ),
        });
      }
      if (permission.name === GENERATES_USERS_INVOICE) {
        newTabsArray.push({
          title: "Users Invoice",
          content: (
            <UsersInvoice
              loading={apiLoading}
              setLoading={(e) => {
                setApiLoading(e);
              }}
            />
          ),
        });
      }
      if (permission.name === SHOW_STATISTIC) {
        newTabsArray.push({
          title: "Users Statistic",
          content: (
            <Statistic
              loading={apiLoading}
              setLoading={(e) => {
                setApiLoading(e);
              }}
            />
          ),
        });
      }
    });
    setTabs(newTabsArray);
  };

  useEffect(() => {
    if (permissions.length) {
      identifyTabs(permissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  return (
    <React.Fragment>
      <div>
        <Spin spinning={apiLoading}>
          <TabView>
            {tabs.map((tab) => {
              return (
                <TabPanel key={tab.title} header={tab.title}>
                  {tab.content}
                </TabPanel>
              );
            })}
          </TabView>
        </Spin>
      </div>
    </React.Fragment>
  );
};
export default Invoice;
