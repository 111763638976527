import React from "react";

import logo from "./../../resources/WONING MEDIA LOGO.png";

export const AppFooter = (props) => {
  return (
    <div className="layout-footer">
      <img src={logo} alt="logo" height="20" className="mr-2" />
      {/* by */}
      <span className="font-medium ml-2 mt-1">© Copyright 2024. floorio.nl</span>
    </div>
  );
};
