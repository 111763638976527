import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllAgencies = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/agencies", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addNewAgency = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/agencies", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteSpecificAgency = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/agencies/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getFormatFiles = async (id) => {
  return await axios.post(
    APP_CONTEXT_URL + "/v1/getFormatImage",
    { name: id },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
      responseType: "blob",
    }
  );
};

// agency formats apis
export const getAllAgenciesFormats = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/getAllAgencyFormats", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addNewAgencyFormat = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addAgencyFormats", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};
