import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Spin, Upload, Select } from "antd";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { addNewAgencyFormat } from "../../../apis/AgencyApis";
import { getAllCompanies } from "../../../apis/CompanyApis";
import { useSelector } from "react-redux";
import { USER_TYPE_OWNER } from "../../../utils/constants";

const AddAgencyformatDialog = (props) => {
  const [apiLoading, setApiLoading] = useState(false);
  const [uploadDisable, setUploadDisable] = useState(true);
  const [agencyFormat] = Form.useForm();
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [companyOptions, setCompanyOptions] = useState([]);
  const toast = useRef(null);

  useEffect(() => {
    fetchCompanies();
  }, []);

  const dropdownStyles = {
    zIndex: 10000,
    minWidth: "270px"
  };

  const fetchCompanies = () => {
    setApiLoading(true);
    getAllCompanies()
      .then((res) => {
        if (res.status === 200) {
          let options = (res.data).map((r) => (
            {value: r.id, label: r.name}
          ))
          setCompanyOptions(options);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
          setApiLoading(false);
        });
  }

  const beforeUpload = (file) => {
    setApiLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append(
      "agencyFormat",
      JSON.stringify({name: agencyFormat.getFieldsValue().name})
    );
    formData.append(
      "companyId",
      JSON.stringify(currentUser.userType === USER_TYPE_OWNER ? currentUser.company.id : agencyFormat.getFieldsValue().companyId)
    );
    addNewAgencyFormat(formData).then((res) => {
      if (res.status === 200) {
        toast.current.show({
          severity: "success",
          summary: "Status",
          detail: "Agency Format added Successfully",
          life: 3000,
        });
        agencyFormat.resetFields();
        props.setVisible(false);
        setApiLoading(false);
      }else {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: res.response.data,
          life: 3000,
        });
        setApiLoading(false);
      }
    }).catch(() => {
      toast.current.show({
        severity: "error",
        summary: "Status",
        life: 3000,
      });
      setApiLoading(false);
    });;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <Dialog
          header="Add AgencyFormat"
          visible={props.visible}
          modal
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw" }}
          onHide={() => {
            agencyFormat.resetFields();
            setUploadDisable(true);
            props.setVisible(false);
          }}
        >
          <Spin spinning={apiLoading}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <div>
                <Form
                  form={agencyFormat}
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 18 }}
                  size="middle"
                  className="custom-form-design"
                  //   onFinish={handleSubmit}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Name is required.",
                      },
                    ]}
                    name="name"
                    label="Name"
                  >
                    <Input
                      onChange={(e) => {
                        if (
                          e.target.value.length >= 0 &&
                          e.target.value !== ""
                        ) {
                          setUploadDisable(false);
                        } else {
                          setUploadDisable(true);
                        }
                      }}
                    />
                  </Form.Item>
                  {currentUser.userType !== "Customer" && (
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Customer is required.",
                          },
                        ]}
                        name="companyId"
                        label="Customer"
                    >
                      <Select
                        style={{ minWidth: "270px" }}
                        mode={"single"}
                        dropdownStyle={dropdownStyles}
                        placeholder="Select Customer"
                        options={companyOptions}
                      >
                      </Select>
                    </Form.Item>
                    )}
                  <Form.Item style={{marginLeft:"87px"}}>
                  <Upload
                    disabled={uploadDisable}
                    accept=".png, .jpg, .jpeg"
                    listType="picture-card"
                    // fileList={fileList}
                    // onChange={handleChange}
                    beforeUpload={beforeUpload}
                    showUploadList={false}
                  >
                    {uploadButton}
                  </Upload>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Spin>
        </Dialog>
      </div>
    </React.Fragment>
  );
};
export default AddAgencyformatDialog;
