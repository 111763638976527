import { Button, Form, Radio, Switch, message } from "antd";
import React, { useState } from "react";
import {
  export2DProjectOnFloorPlanner,
  exportProjectOnFloorPlanner,
} from "../../../../apis/floorPlannerApis";
import "./params2dForm.scss";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";

const Params2dForm = (props) => {
  const [fileFormat, setFileFormat] = useState("jpg");
  const [includeFML, setIncludeFML] = useState(false);
  const [form] = Form.useForm();
  const toast = useRef(null);

  const onFileFormatChange = (e) => {
    setFileFormat(e.target.value);
  };

  const onFinish = (values) => {
    var exportParams = {
      format: values.format,
      scale: values.scale,
      orientation: values.orientation,
      includeFML: includeFML,
    };

    export2DProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId,
      exportParams
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
  };

  const exportProjectonnFloorPlanner = () => {
    exportProjectOnFloorPlanner(
      props.floorPlannerProjectId,
      props.projectId
    ).then((response) => {
      if (response.status === 200) {
        props.projectImport();
        message.success("Project imported successfully");
      }
    });
  };

  const layout = {
    labelCol: {
      span: 7,
    },
    wrapperCol: {
      span: 17,
    },
  };

  const validateMessages = {
    required: "${label} is required!",
  };

  const onSwitchChange = (checked) => {
    setIncludeFML(checked);
  };

  return (
    <React.Fragment>
      <Form form={form}
        {...layout}
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        {/* <div className="style-change">
          <Form.Item
            className="format-label"
            name="format"
            label="Format"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group
              className="radio-btns"
              onChange={(e) => onFileFormatChange(e)}
              value={fileFormat}
            >
              <Radio.Button value="jpg">jpg</Radio.Button>
              <Radio.Button value="png">png</Radio.Button>
              <Radio.Button value="pdf">pdf</Radio.Button>
              <Radio.Button value="dxf">dxf</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <div className="style-change">
          <Form.Item
            className="scale-label"
            name="scale"
            label="Scale"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group
              className="radio-btns"
              onChange={(e) => onFileFormatChange(e)}
              value={fileFormat}
            >
              <Radio.Button value="false">Same Scale</Radio.Button>
              <Radio.Button value="true">Scale to fit</Radio.Button>
              {fileFormat === "pdf" ? (
                <>
                  <Radio.Button value="1:50">1:50</Radio.Button>
                  <Radio.Button value="1:100">1:100</Radio.Button>
                  <Radio.Button value="1:200">1:200</Radio.Button>
                  <Radio.Button value="1:500">1:500</Radio.Button>
                </>
              ) : (
                ""
              )}
            </Radio.Group>
          </Form.Item>
        </div> */}

        <div className="style-change">
          <Form.Item
            className="orientation-label"
            name="orientation"
            label="Orientation"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group className="radio-btns">
              <Radio.Button value="landscape">Landscape</Radio.Button>
              <Radio.Button value="portrait">Portrait</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
        <Form.Item label="Include FML" value={includeFML}>
          <Switch className="radio-btns" onChange={onSwitchChange} />
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              marginTop: "5rem",
              width: "80%",
              marginLeft: "28%",
            }}
            type="primary"
            onClick={(e) => {
              form.validateFields().then(() => {
                confirmDialog({
                  message: "Are you sure you want to import?",
                  header: "Confirmation",
                  icon: "pi pi-exclamation-triangle",
                  accept: () => onFinish(form.getFieldsValue()),
                });
              }).catch(errorInfo => {
                toast.current.show({
                  severity: "info",
                  summary: "Status",
                  detail: errorInfo,
                  life: 5000,
                });
              });
            }
            }  
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Button
        type="primary"
        style={{
          width: "57%",
          marginLeft: "20%",
        }}
        onClick={(e) => {
          confirmDialog({
            message: "Are you sure you want to import?",
            header: "Confirmation",
            icon: "pi pi-exclamation-triangle",
            accept: () => exportProjectonnFloorPlanner(),
          });
        }
        }      >
        Import total project
      </Button>
    </React.Fragment>
  );
};

export default Params2dForm;
