import React from "react";
import { Form, Select, Tabs } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import Params2dForm from "../form/exportParamsForms/params2dForm";
import Params3dForm from "../form/exportParamsForms/params3dForm";
import BackgroundParams from "../form/exportParamsForms/backgroundForm";

const exportProjectParams = (props) => {
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" centered tabBarStyle={{ height: "100%" }}>
        <TabPane tab={<span>2D </span>} key="1">
          <Params2dForm
            projectImport={props.projectImport}
            floorPlannerProjectId={props.floorPlannerProjectId}
            projectId={props.projectId}
          />
        </TabPane>
        <TabPane tab={<span>3D </span>} key="2">
          <Params3dForm
            projectImport={props.projectImport}
            floorPlannerProjectId={props.floorPlannerProjectId}
            projectId={props.projectId}
          />
        </TabPane>
        <TabPane tab={<span>Background </span>} key="3">
          <BackgroundParams
            projectImport={props.projectImport}
            floorPlannerProjectId={props.floorPlannerProjectId}
            projectId={props.projectId}
          />
        </TabPane>
      </Tabs>
    </React.Fragment>
  );
};
export default exportProjectParams;
