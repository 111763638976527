import React, { useEffect, useRef, useState } from "react";
import { Divider } from "primereact/divider";
import { Column } from "primereact/column";
import { Form, Select } from "antd";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import {
  getAllProjectInvoices,
} from "../../apis/AdminApis";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { useSelector } from "react-redux";
import { getAllCompanies } from "../../apis/CompanyApis";
import UpdateProjectInvoiceDialog from "./dialog/UpdateProjectInvoiceDialog";

const InvoiceConfig = (props) => {
  const [form] = Form.useForm();
  const [apiLoading, setApiLoading] = useState(false);
  const [projectInvoices, setProjectInvoices] = useState([]);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [projectInvoice, setProjectInvoice] = useState([]);
  const [visibleProjectInvoiceDialog, setVisibleProjectInvoiceDialog] = useState(false);
  const toast = useRef(null);
  const [companyId, setCompanyId] = useState(null);
  const dropdownStyles = {
    zIndex: 10000,
    minWidth: "270px"
  };
  useEffect(() => {
    fetchCompanies();
  }, [])

  const changeVisibleProjectInvoiceDialog = () => {
    setVisibleProjectInvoiceDialog(false);
    getProjectInvoices(companyId);
  }

  const fetchCompanies = () => {
    setApiLoading(true);
    getAllCompanies()
      .then((res) => {
        if (res.status === 200) {
          let options = (res.data).map((r) => (
            { value: r.id, label: r.name }
          ))
          setCompanyOptions(options);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  }
  const getProjectInvoices = (companyId) => {
    getAllProjectInvoices(companyId)
      .then((res) => {
        if (res.status === 200) {
          setProjectInvoices(res.data);
          props.setLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          props.setLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        props.setLoading(false);
      });
  }
  const submit = () => {
    form.validateFields().then((values) => {
      props.setLoading(true);
      setCompanyId(values.companyId);
      getProjectInvoices(values.companyId);
    });
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <div className="card" style={{ height: "72vh" }}>
          <div className="grid" style={{ height: "70vh" }}>
            <div className="col-3 flex align-items-center justify-content-center">
              <div>
                <div>
                  <Form
                    form={form}
                    size="middle"
                    className="custom-form-design"
                    onFinish={submit}
                    labelCol={{ span: 12 }}
                    wrapperCol={{ span: 17 }}
                  >
                    {currentUser.userType !== "Customer" && (
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Customer is required.",
                          },
                        ]}
                        name="companyId"
                        label="Customer"
                      >
                        <Select
                          mode={"single"}
                          dropdownStyle={dropdownStyles}
                          placeholder="Select Customer"
                          style={{ width: 100 }}
                          options={companyOptions}>
                        </Select>
                      </Form.Item>
                    )}
                    <Button type="submit" style={{ float: "right" }}>
                      Submit
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-1">
              <Divider layout="vertical"></Divider>
            </div>
            <div className="col-8">
              <Toolbar
                className="mb-4"
                left={() => {
                  return (
                    <React.Fragment>
                      <div>
                        <h3 className="m-0">Project Invoice Config</h3>
                      </div>
                    </React.Fragment>
                  );
                }}
                right={() => {
                  return (
                    <React.Fragment>
                    </React.Fragment>
                  );
                }}
              ></Toolbar>
              <DataTable
                value={projectInvoices}
                dataKey="id"
                responsiveLayout="scroll"
                selectionMode="single"
                className="overflow-y-auto"
                style={{ maxHeight: "50vh" }}
              >
                <Column field="product" header="Product"></Column>
                <Column field="type" header="Type"></Column>
                <Column field="ndd" header="Ndd"></Column>
                <Column field="isAutocad" header="Autocad"></Column>
                <Column field="price" header="Price"></Column>
                <Column field="report" header="Report"></Column>
                <Column header="Action" body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex justify-content-center">
                        {
                          <div>
                            <Button
                              style={{
                                backgroundColor: "#037EEA",
                                borderColor: "#037EEA",
                              }}
                              icon={"pi pi-pencil"}
                              label="Edit"
                              onClick={(event) => {
                                event.preventDefault();
                                setProjectInvoice(record);
                                setVisibleProjectInvoiceDialog(true)
                              }}
                            />
                          </div>

                        }
                      </div>
                    </React.Fragment>
                  );
                }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
      <UpdateProjectInvoiceDialog
        projectInvoice={projectInvoice}
        visible={visibleProjectInvoiceDialog}
        setVisible={changeVisibleProjectInvoiceDialog}
      />
    </React.Fragment>
  );


};

export default InvoiceConfig;
