import React, { useEffect, useRef, useState } from "react";
import { Divider } from "primereact/divider";
import { Column } from "primereact/column";
import { Form, DatePicker, Select } from "antd";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import {
  projectInvoice,
  projectInvoiceDownload,
} from "../../../apis/ProjectInvoiceApi";
import { Toast } from "primereact/toast";
import moment from "moment";
import { Toolbar } from "primereact/toolbar";
import { downloadedFile } from "../../../functions/functions";
import { useSelector } from "react-redux";
import { GENERATES_PROJECTS_INVOICE } from "../../../utils/constants";
import { getAllCompanies } from "../../../apis/CompanyApis";

const ProjectsInvoice = (props) => {
  const [form] = Form.useForm();
  const [invoiceData, setInvoiceData] = useState([]);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);

  const toast = useRef(null);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const dropdownStyles = {
    zIndex: 10000,
    minWidth: "270px"
  };
  useEffect(() => {
    fetchCompanies();
  }, [])

  const fetchCompanies = () => {
    getAllCompanies()
      .then((res) => {
        if (res.status === 200) {
          let options = (res.data).map((r) => (
            { value: r.id, label: r.name }
          ))
          setCompanyOptions(options);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
      });
  }


  const submit = () => {
    form.validateFields().then((values) => {
      props.setLoading(true);
      let newValues = {
        startDate: `${moment(values.startdate).format("DD-MM-yyyy hh:mm a")}`,
        endDate: `${moment(values.enddate).format("DD-MM-yyyy hh:mm a")}`,
        companyId: currentUser.userType === "Customer" ? currentUser.company.id : values.companyId
      };
      projectInvoice(newValues)
        .then((res) => {
          if (res.status === 200) {
            setInvoiceData(res.data);
            props.setLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            props.setLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          props.setLoading(false);
        });
    });
  };

  const exportData = () => {
    form.validateFields().then((values) => {
      if (invoiceData.length) {
        props.setLoading(true);
        let newValues = {
          startDate: `${moment(values.startdate).format("DD-MM-yyyy hh:mm a")}`,
          endDate: `${moment(values.enddate).format("DD-MM-yyyy hh:mm a")}`,
          companyId: values.companyId
        };
        projectInvoiceDownload(newValues)
          .then(async (res) => {
            if (res.status === 200) {
              downloadedFile(res, "Invoice data.xlsx");
              setInvoiceData([]);
              form.resetFields();
              props.setLoading(false);
            } else {
              const text = await new Response(res.response.data).text();
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: text,
                life: 3000,
              });
              props.setLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            props.setLoading(false);
          });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Status",
          detail: "No data to generate invoice from.",
          life: 3000,
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <div className="card" style={{ height: "72vh" }}>
          <div className="grid" style={{ height: "70vh" }}>
            <div className="col-3 flex align-items-center justify-content-center">
              <div>
                <div>
                  <Form
                    form={form}
                    size="middle"
                    className="custom-form-design"
                    onFinish={submit}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Form.Item
                      name="startdate"
                      label="Start Date"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Start Date is required.",
                        },
                      ]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      name="enddate"
                      label="End Date"
                      required
                      rules={[
                        {
                          required: true,
                          message: "End Date is required.",
                        },
                      ]}
                    >
                      <DatePicker />
                    </Form.Item>
                    {currentUser.userType !== "Customer" && (
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "Customer is required.",
                          },
                        ]}
                        name="companyId"
                        label="Customer"
                      >
                        <Select
                          mode={"single"}
                          dropdownStyle={dropdownStyles}
                          placeholder="Select Customer"
                          options={companyOptions}
                        >
                        </Select>
                      </Form.Item>
                    )}
                    <Button type="submit" style={{ float: "right" }}>
                      Generate Invoice
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-1">
              <Divider layout="vertical"></Divider>
            </div>
            <div className="col-8">
              <Toolbar
                className="mb-4"
                left={() => {
                  return (
                    <React.Fragment>
                      <div>
                        <h3 className="m-0">Invoice Data</h3>
                      </div>
                    </React.Fragment>
                  );
                }}
                right={() => {
                  return (
                    <React.Fragment>
                      <div>
                        {permissions.map((permission) => {
                          return permission.name ===
                            GENERATES_PROJECTS_INVOICE ? (
                            <Button
                              style={{ backgroundColor: "#037eea" }}
                              icon="pi pi-file-excel"
                              label="Generate Excel"
                              onClick={(e) => {
                                e.stopPropagation();
                                exportData();
                              }}
                            />
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              ></Toolbar>
              <DataTable
                value={invoiceData}
                dataKey="id"
                responsiveLayout="scroll"
                selectionMode="single"
                className="overflow-y-auto"
                style={{ maxHeight: "50vh" }}
              >
                <Column
                  field="owner"
                  header="Owner"
                  body={(record) => {
                    return record.owner.name;
                  }}
                ></Column>
                <Column field="projectNumber" header="Project Number"></Column>
                <Column field="address" header="Address"></Column>
                <Column field="startDate" header="Start Date"></Column>
                <Column field="product" header="Product"></Column>
                <Column field="report" header="Report"></Column>
                <Column field="type" header="Type"></Column>
                <Column field="goPerFloor" header="Go Per Floor"></Column>
                <Column field="goTotal" header="Go Total"></Column>
                <Column field="ndd" header="NDD"></Column>
                <Column field="isAutocad" header="Is Autocad" />
                <Column field="adjustmentType" header="Adjustment Type"></Column>
                <Column field="price" header="Price"></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectsInvoice;
