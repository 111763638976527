export const downloadedPdf = (response, fileName) => {
  //Create a Blob from the PDF Stream
  // var fileName = response.headers['content-disposition'].split('filename=')[1];
  const file = new Blob([response.data], { type: "application/pdf" });
  // var link = document.createElement("a");
  // link.href = url;
  // link.setAttribute("download", fileName);
  // link.click();
  const fileURL = URL.createObjectURL(file);
  //Open the URL on new Window
  window.open(fileURL);
};

export const downloadedFile = (response, fileName) => {
  //Create a Blob from the PDF Stream
  // var fileName = response.headers['content-disposition'].split('filename=')[1];
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
  // const fileURL = URL.createObjectURL(url);
  // //Open the URL on new Window
  // window.open(fileURL);
};

export const downloadFileUrl = (fileUrl) => {
  var link = document.createElement("a");
  link.href = fileUrl;
  link.download = fileUrl.split("/").pop().split("?")[0];
  link.click();
};

export const checkToken = (history) => {
  if (
    (!localStorage.getItem("user") || localStorage.getItem("user") === {}) &&
    !localStorage.getItem("__Secure-floorplanner_token")
  ) {
    history.push("/");
  }
};
