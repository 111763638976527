import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllProjectInvoices = (companyId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/admin/project/invoice/" + companyId , {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getAllUserInvoices  = (companyId) => {
    return axios.get(APP_CONTEXT_URL + "/v1/admin/user/invoice/" + companyId , {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    });
  };
  export const getAllAdjustmentRates = (companyId) => {
    return axios.get(APP_CONTEXT_URL + "/v1/admin/adjustmentRate/" + companyId , {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    });
  };
  
export const updateProjectInvoice = (projectInvoice) => {
  return axios.put(APP_CONTEXT_URL + "/v1/admin/project/invoice", projectInvoice, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateUserInvoice = (userInvoice) => {
    return axios.put(APP_CONTEXT_URL + "/v1/admin/user/invoice", userInvoice, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    });
  };

  export const updateAdjustmentRate = (adjustmentRate) => {
    return axios.put(APP_CONTEXT_URL + "/v1/admin/adjustmentRate", adjustmentRate, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
    });
  };
  
