import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const regeneratePfd = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/download/report/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
      responseType: "json",
    }
  );
};


export const generateExcelApi = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/download/Excel/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__Secure-floorplanner_token"),
      },
      responseType: "json",
    }
  );
};
