import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllCompanies = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/companies", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addNewCompany = (companyName) => {
  return axios.post(APP_CONTEXT_URL + "/v1/company", companyName, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateCompany = (company) => {
  return axios.put(APP_CONTEXT_URL + "/v1/company", company, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteSpecificCompany = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/companies/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const uploadCompanyFiles = (id, formData) => {
  return axios.post(APP_CONTEXT_URL + "/v1/company/uploadCompanyFiles/" + id, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getCompanyFiles = (id, fileType) => {
  const queryParams = new URLSearchParams({
    fileType: fileType
  }).toString();
  return axios.get(APP_CONTEXT_URL + "/v1/company/getCompanyFiles/" + id + `?${queryParams}`, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteCompanyFile = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/deleteCompanyFile", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const addReportConfig = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addReportConfig", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateOrderReportConfig = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateOrderReportConfig", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getReportConfigs = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/company/getReportConfigs" , {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};