import { Button, Form, Input, Select } from "antd";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { updateImageName } from "../../../apis/ProjectApis";

const ChangeImageNameDialog = (props) => {
  const [form] = Form.useForm();
  const { Option } = Select;
  const toast = useRef(null);

  useEffect(() => {
    form.setFieldsValue({ fileName: props.image.fileName != undefined ? props.image.fileName.split("/").pop() : props.image.fileName});
  }, [props]);

  const onFinish = (values) => {
    var oldName = props.image.fileName;
    var image = props.image;
    //check path have timestamp
    let timeStamp = oldName.split("/").length == 2 ? oldName.split("/")[0] : "";
    image.fileName = timeStamp != "" ? timeStamp + "/" + values.fileName : values.fileName;
    image.projectId = { id: image.projectId };
    image.fileName = image.fileName.trim();
    updateImageName(image.id, image.fileName)
      .then((res) => {
        if (res.status === 200) {
          // toast.current.show({
          //   severity: "success",
          //   summary: "Status",
          //   detail: `Image Name Updated successfully`,
          //   life: 3000,
          // });
          props.setVisible(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
      });
  };

  return (
    <React.Fragment>
      <Dialog
        header="Change Image Name"
        visible={props.visible}
        style={{ width: "30vw" }}
        onHide={() => props.setVisible(false)}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item label="Image Name" name="fileName">
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Dialog>
    </React.Fragment>
  );
};

export default ChangeImageNameDialog;
