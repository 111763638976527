import { DatePicker, Form } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  usersInvoice,
  usersInvoiceDownload,
} from "../../../apis/ProjectInvoiceApi";
import { downloadedFile } from "../../../functions/functions";
import { GENERATES_USERS_INVOICE } from "../../../utils/constants";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";

const UsersInvoice = (props) => {
  const [invoiceData, setInvoiceData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [form] = Form.useForm();
  const toast = useRef(null);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const submit = () => {
    form.validateFields().then((values) => {
      props.setLoading(true);
      let newValues = {
        startDate: `${moment(values.startdate).format("DD-MM-yyyy hh:mm a")}`,
        endDate: `${moment(values.enddate).format("DD-MM-yyyy hh:mm a")}`,
      };
      usersInvoice(newValues)
        .then((res) => {
          if (res.status === 200) {
            let newData = [];
            res.data.forEach((element) => {
              for (
                let index = 0;
                index < element.invoiceDataList.length;
                index++
              ) {
                let newObj = {
                  ...element.invoiceDataList[index],
                  user: element.user,
                };
                newData.push(newObj);
              }
            });
            setInvoiceData(newData.sort((a, b) => a.user.name - b.user.name));
            props.setLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            props.setLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          props.setLoading(false);
        });
    });
  };

  const exportData = () => {
    form.validateFields().then((values) => {
      if (invoiceData.length) {
        props.setLoading(true);
        let newValues = {
          startDate: `${moment(values.startdate).format("DD-MM-yyyy hh:mm a")}`,
          endDate: `${moment(values.enddate).format("DD-MM-yyyy hh:mm a")}`,
        };
        usersInvoiceDownload(newValues)
          .then(async (res) => {
            if (res.status === 200) {
              downloadedFile(res, res.headers.filename);
              setInvoiceData([]);
              form.resetFields();
              props.setLoading(false);
            } else {
              const text = await new Response(res.response.data).text();
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: text,
                life: 3000,
              });
              props.setLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            props.setLoading(false);
          });
      } else {
        toast.current.show({
          severity: "warn",
          summary: "Status",
          detail: "No data to generate invoice from.",
          life: 3000,
        });
      }
    });
  };

  const invoiceStyle = {
    backgroundColor: "#e1f8dc"
  }

  const feedbackStyle = {
    backgroundColor: "#f5fcf3"
  }
  
  const headerStyle = {
    // backgroundColor: "#acddde"
  }

  const headerGroup = <ColumnGroup>
    <Row>
      <Column header="Project Invoice" colSpan={11} />
      <Column header="Feedback for Quality Checker" colSpan={6} />
    </Row>
    <Row>
      <Column header="Owner" style={headerStyle}></Column>
      <Column header="Project Number"style={headerStyle}></Column>
      <Column header="Name"style={headerStyle}></Column>
      <Column header="Role"style={headerStyle}></Column>
      <Column header="Address"style={headerStyle}></Column>
      <Column header="Start Date"style={headerStyle}></Column>
      <Column header="Product"style={headerStyle}></Column>
      <Column header="Type"style={headerStyle}></Column>
      <Column header="NDD"style={headerStyle}></Column>
      <Column header="Is Autocad"style={headerStyle}></Column>
      <Column header="Adjustment Type"style={headerStyle}></Column>
      <Column header="Price"style={headerStyle}></Column>
      <Column header="Good"style={headerStyle}></Column>
      <Column header="M2 not correct"style={headerStyle}></Column>
      <Column header="h1<1,50m missing"style={headerStyle}></Column>
      <Column header="Table in report wrong"style={headerStyle}></Column>
      <Column header="NEN rules wrong"style={headerStyle}></Column>
      <Column header="Other Note" style={{minWidth: "150px"}}></Column>

    </Row>
  </ColumnGroup>;

  const generateFeedbackColumn = (field) => (
    <Column
      field={field}
      style={feedbackStyle}
      body={(rowData) => (
        rowData.feedbackForQualityChecker[field] === 1 ? (
          <i className="pi pi-check" style={{ color: "#30a14e" }} />
        ) : (
          rowData[field]
        )
      )}
    />
  );

  return (
    <React.Fragment>
      <div>
        <Toast ref={toast} />
        <div className="card" style={{ height: "72vh" }}>
          <div className="grid" style={{ height: "70vh" }}>
            <div className="col-3 flex align-items-center justify-content-center">
              <div>
                <div>
                  <Form
                    form={form}
                    size="middle"
                    className="custom-form-design"
                    onFinish={submit}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 14 }}
                  >
                    <Form.Item
                      name="startdate"
                      label="Start Date"
                      required
                      rules={[
                        {
                          required: true,
                          message: "Start Date is required.",
                        },
                      ]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      name="enddate"
                      label="End Date"
                      required
                      rules={[
                        {
                          required: true,
                          message: "End Date is required.",
                        },
                      ]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Button type="submit" style={{ float: "right" }}>
                      Generate Invoice
                    </Button>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-1">
              <Divider layout="vertical"></Divider>
            </div>
            <div className="col-8">
              <Toolbar
                className="mb-4"
                left={() => {
                  return (
                    <React.Fragment>
                      <div>
                        <h3 className="m-0">Invoice Data</h3>
                      </div>
                    </React.Fragment>
                  );
                }}
                right={() => {
                  return (
                    <React.Fragment>
                      <div>
                        {permissions.map((permission) => {
                          return permission.name === GENERATES_USERS_INVOICE ? (
                            <Button
                              style={{ backgroundColor: "#037eea" }}
                              icon="pi pi-file-excel"
                              label="Generate File"
                              onClick={(e) => {
                                e.stopPropagation();
                                exportData();
                              }}
                            />
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              ></Toolbar>
              <div className="treetable-responsive-demo">
                <DataTable
                  value={invoiceData}
                  dataKey="id"
                  responsiveLayout="scroll"
                  selectionMode="single"
                  className="overflow-y-auto"
                  style={{ maxHeight: "50vh" }}
                  headerColumnGroup={headerGroup}
                >
                  <Column
                    style={invoiceStyle}
                    field="owner"
                    header="Owner"
                    body={(record) => {
                      return record?.owner?.name;
                    }}
                  ></Column>
                  <Column
                    field="projectNumber"
                    header="Project Number"
                    style={invoiceStyle}
                  ></Column>
                  <Column
                    field="user"
                    header="Name"
                    body={(record) => {
                      return record.user.name;
                    }}
                    style={invoiceStyle}
                  ></Column>
                  <Column field="role" header="Role" style={invoiceStyle}></Column>
                  <Column field="address" header="Address" style={invoiceStyle}></Column>
                  <Column field="startDate" header="Start Date" style={invoiceStyle}></Column>
                  <Column field="product" header="Product" style={invoiceStyle}></Column>
                  <Column field="type" header="Type" style={invoiceStyle}></Column>
                  <Column field="ndd" header="NDD" style={invoiceStyle}></Column>
                  <Column field="isAutocad" header="Is Autocad" style={invoiceStyle}></Column>
                  <Column field="adjustmentType" header="Adjustment Type" style={invoiceStyle}></Column>
                  <Column field="price" header="Price" style={invoiceStyle}></Column>

                  {generateFeedbackColumn("GOOD")}
                  {generateFeedbackColumn("M2_NOT_CORRECT")}
                  {generateFeedbackColumn("H_LESS_THAN_150CM_MISSING")}
                  {generateFeedbackColumn("TABLE_IN_REPORT_WRONG")}
                  {generateFeedbackColumn("NEN_RULES_WRONG")}
                  {generateFeedbackColumn("otherFeedbackForQualityChecker", "Other")}
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UsersInvoice;
