import { Form, Spin, Input, Select } from "antd";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import {
    updateAdjustmentRate,
} from "../../../apis/AdminApis";

const UpdateAdjustmentRateDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();
    const { Option } = Select;

    useEffect(() => {
        form.setFieldsValue({ ...props.adjustmentRate })
    });

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            values.id = props.adjustmentRate.id;
            values.company = { id: props.adjustmentRate.company.id };
            setApiLoading(true);
            updateAdjustmentRate(values).then((res) => {
                if (res.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Update Rework successfully",
                        life: 3000
                    })

                    setApiLoading(false);
                    props.setVisible(false);
                } else {
                    toast.current.show({
                        severity: "warn",
                        summary: "Status",
                        detail: "Update Rework failed!",
                        life: 3000
                    })
                    setApiLoading(false);
                    props.setVisible(false);
                }
            }).catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
        })
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                style={{ width: "480px" }}
                header={"Update Rework Rate Config"}
                visible={props.visible}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item required={true}
                                    name="type" label="Type"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select House Type",
                                        },
                                    ]}
                                >
                                    <Select style={{ width: "270px" }} dropdownStyle={{ zIndex: "9999" }} placeholder="Select">
                                        <Option value="Small">Small</Option>
                                        <Option value="Big">Big</Option>
                                        <Option value="Remake">Remake</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item required={true}
                                    name="adjustmentFor" label="Adjustment For"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "270px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="price" label="Price"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "270px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>

            </Dialog>
        </React.Fragment>
    )
}

export default UpdateAdjustmentRateDialog
