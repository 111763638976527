import { Form, Spin, Input } from "antd";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import {
    optionEmployeeFeedbacks,
    optionManagerFeedbacks,
    optionQualityCheckerFeedbacks,
    FINAL_CHECK
} from "../../../utils/constants";
import {
    updateProjectFeedback,
    finalCheckProject
} from "../../../apis/ProjectApis";
import CustomSelectFeedback from "./CustomSelectFeedback";

const FeedbackDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();
    const [showPrivateNoteEmployee, setShowPrivateNoteEmployee] = useState(false);
    const [otherFeedbackForEmployee, setOtherFeedbackForEmployee] = useState("");
    const [otherFeedbackForManager, setOtherFeedbackForManager] = useState("");
    const [otherFeedbackForQualityChecker, setOtherFeedbackForQualityChecker] = useState("");
    const project = props.project;
    const [initFeedbackForEmployee, setInitFeedbackForEmployee] = useState([]);
    const [initFeedbackForManager, setInitFeedbackForManager] = useState([]);
    const [initFeedbackForQualityChecker, setInitFeedbackForQualityChecker] = useState([]);

    const optionToShowPrivateNote = ["NEN_RULES_WRONG", "M2_NOT_CORRECT", "H_LESS_THAN_150CM", "PARTS_MISSING"];

    useEffect(() => {
        if (project) {
            setInitFeedbackForEmployee(project?.feedbackForEmployee?.split(","));
            setInitFeedbackForQualityChecker(project?.feedbackForQualityChecker?.split(","));
            setInitFeedbackForManager(project?.feedbackForManager?.split(","));

            form.setFieldsValue({ feedbackForEmployee: initFeedbackForEmployee });
            form.setFieldsValue({ feedbackForManager: initFeedbackForManager });
            form.setFieldsValue({ feedbackForQualityChecker: initFeedbackForQualityChecker });
            form.setFieldsValue({ privateNoteForEmployee: project?.privateNoteForEmployee });
            setOtherFeedbackForEmployee(project?.otherFeedbackForEmployee);
            setOtherFeedbackForManager(project?.otherFeedbackForManager);
            setOtherFeedbackForQualityChecker(project?.otherFeedbackForForQualityChecker);
            if((project?.privateNoteForEmployee !== "" && project?.privateNoteForEmployee !== null) || initFeedbackForEmployee?.some(element => optionToShowPrivateNote?.includes(element))){
                setShowPrivateNoteEmployee(true)
            }
        }
    }, [project]);

    const handleFinalCheckProject = (record, isAssignToMe) => {
        if (record && record.id) {
            setApiLoading(true);
            finalCheckProject(record.id, isAssignToMe)
                .then((res) => {
                    if (res.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Status",
                            detail: "Project Status Change To Final Check Successfully",
                            life: 3000,
                        });
                        props.setVisible(false)
                        setApiLoading(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Status",
                            detail: res.response.data,
                            life: 3000,
                        });
                        setApiLoading(false);
                    }
                })
                .catch((e) => {
                    toast.current.show({
                        severity: "error",
                        summary: "Status",
                        detail: e,
                        life: 3000,
                    });
                    setApiLoading(false);
                });
        } else {
            toast.current.show({
                severity: "warn",
                summary: "Status",
                detail: "No Selected Project",
                life: 3000,
            });
        }
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            setApiLoading(true);
            console.log("Note: " + otherFeedbackForEmployee)
            const projectValues = {
                id: project.id,
                feedbackForEmployee: values.feedbackForEmployee.toString(),
                feedbackForManager: values.feedbackForManager?.toString(),
                feedbackForQualityChecker: values.feedbackForQualityChecker?.toString(),
                otherFeedbackForEmployee: otherFeedbackForEmployee,
                otherFeedbackForManager: otherFeedbackForManager,
                otherFeedbackForQualityChecker: otherFeedbackForQualityChecker,
                privateNoteForEmployee: values.privateNoteForEmployee?.toString(),
            };
            console.log("Project values: " + JSON.stringify(projectValues))
            updateProjectFeedback(projectValues).then((res) => {
                console.log("Response from feedback api:" + JSON.stringify(res))
                if (res.status === 200 && res.data === true) {
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Update feedback successfully",
                        life: 3000
                    })
                    if (project?.projectStatus != FINAL_CHECK) {
                        if (
                            project.qualityChecker === null &&
                            project.employee.id !== props.currentUser.id
                        ) {
                            confirmDialog({
                                message:
                                    "Do you want to assign yourself to Quality Checker?",
                                header: "Confirmation",
                                icon: "pi pi-exclamation-triangle",
                                accept: () => handleFinalCheckProject(project, true),
                                reject: () => handleFinalCheckProject(project, false),
                            });
                        } else {
                            confirmDialog({
                                message:
                                    "Are you sure you want to submit the project for Final Check?",
                                header: "Confirmation",
                                icon: "pi pi-exclamation-triangle",
                                accept: () => handleFinalCheckProject(project, false),
                            });
                        }
                    }
                    setApiLoading(false);
                    props.setVisible(false);
                } else {
                    toast.current.show({
                        severity: "warn",
                        summary: "Status",
                        detail: "Update feedback failed!",
                        life: 3000
                    })
                    setApiLoading(false);
                    props.setVisible(false);
                }
            }).catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
        })
    }
    const onChange = (selectedValues) => {
        if (selectedValues.some(element => optionToShowPrivateNote.includes(element))) {
            setShowPrivateNoteEmployee(true);
        } else {
            setShowPrivateNoteEmployee(false);
        }
    }
    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                style={{ width: "480px" }}
                header={"Feedback"}
                visible={props.visible}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item
                                    required={true}
                                    name="feedbackForEmployee" label="Contractor"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select Feedback for Contractor",
                                        },
                                    ]}
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 25 }}
                                    labelAlign={"left"}
                                >
                                    <CustomSelectFeedback
                                        selectedValues={initFeedbackForEmployee}
                                        options={optionEmployeeFeedbacks}
                                        otherFeedback={otherFeedbackForEmployee}
                                        onChange={onChange}
                                        setOtherFeedback={setOtherFeedbackForEmployee}></CustomSelectFeedback>
                                </Form.Item>

                                <Form.Item
                                    required={true}
                                    name="feedbackForManager" label="Measure Guy:"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select Feedback for Measure Guy",
                                        },
                                    ]}
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 25 }}
                                    labelAlign={"left"}
                                >
                                    <CustomSelectFeedback
                                        selectedValues={initFeedbackForManager}
                                        options={optionManagerFeedbacks}
                                        otherFeedback={otherFeedbackForManager}
                                        onChange={() => { }} 
                                        setOtherFeedback={setOtherFeedbackForManager}>
                                    </CustomSelectFeedback>
                                </Form.Item>
                                {project?.projectStatus === FINAL_CHECK ? <Form.Item
                                    required={true}
                                    name="feedbackForQualityChecker" label="Quality Checker:"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please Select Feedback for Quality Checker",
                                        },
                                    ]}
                                    labelCol={{ span: 9 }}
                                    wrapperCol={{ span: 25 }}
                                    labelAlign={"left"}
                                >
                                    <CustomSelectFeedback
                                        selectedValues={initFeedbackForQualityChecker}    
                                        options={optionQualityCheckerFeedbacks}
                                        otherFeedback={otherFeedbackForQualityChecker}
                                        onChange={() => { }} setOtherFeedback={setOtherFeedbackForQualityChecker}>
                                        </CustomSelectFeedback>
                                </Form.Item> : ("")}
                                {showPrivateNoteEmployee ? <Form.Item required={false}
                                    name="privateNoteForEmployee" label="Private Note Contractor"
                                    rules={[
                                        {
                                            required: false,
                                            message: "Please Select Feedback for Contractor",
                                        },
                                    ]}
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "270px" }}
                                        placeholder="Write your private note"
                                        value={project?.privateNoteForEmployee}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item> : ("")}
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>

            </Dialog>
        </React.Fragment>
    )
}

export default FeedbackDialog
