import {
  SET_LOADING,
  RESET_LOADING,
  SET_CURRENT_USER,
} from "./../utils/constants";

/** APP Initial State Values */
export const initialState = {
  loading: { app: { text: "Loading...", status: false } },
  messages: [],
  currentUser: null,
};

const defaultContext = `app`;

const app = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: {
          ...state.loading,
          [payload.context || defaultContext]: { ...payload },
        },
      };

    case RESET_LOADING:
      const { context: resetContext } = payload || {};

      return {
        ...state,
        loading: {
          ...state.loading,
          [resetContext || defaultContext]: {
            status: false,
            text: "Loading...",
          },
        },
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: { ...payload },
      };

    default:
      return state;
  }
};

export default app;
