import { Form, Spin, Input, Select, Checkbox } from "antd";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import {
    updateCompany
} from "../../../apis/CompanyApis";

const UpdateCompanyDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({
        reportImage2D: false,
        reportImage3D: false,
        reportImage3D_2: false,
      })
    const { Option } = Select;

    useEffect(() => {
        form.setFieldsValue({ ...props.company })
        setFormData({
             reportImage2D: props.company?.reportImage2D,
             reportImage3D: props.company?.reportImage3D,
             reportImage3D_2: props.company?.reportImage3D_2,
        });
    }, [props]);

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            values.id = props.company.id;
            values.reportImage2D = formData.reportImage2D;
            values.reportImage3D = formData.reportImage3D;
            values.reportImage3D_2 = formData.reportImage3D_2;

            setApiLoading(true);
            updateCompany(values).then((res) => {
                if (res.status === 200) {
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Update company successfully",
                        life: 3000
                    })

                    setApiLoading(false);
                    props.setVisible(false);
                } else {
                    toast.current.show({
                        severity: "warn",
                        summary: "Status",
                        detail: "Update company failed!",
                        life: 3000
                    })
                    setApiLoading(false);
                    props.setVisible(false);
                }
            }).catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });
        })
    }

    const handleCheckboxChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.checked,
        });
      };

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                style={{ width: "680px" }}
                header={"Update company"}
                visible={props.visible}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item required={true}
                                    name="name" label="Name"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="email" label="Company Email"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={false}
                                    name="url" label="Url links"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={false}
                                    name="copyRight" label="Copyright"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="firstPageTextMarginTop" label="First Page Text Margin op"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="sFirstPageTextColor" label="Color of text on first page"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="sHeaderTitleBulletPointColor" label="Color of headers/titles and bulletpoints"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="sTableBorderColor" label="Color of table border"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                <Form.Item required={true}
                                    name="sTableTextInBorderColor" label="Color of table text in border"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Input
                                        style={{ width: "400px" }}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                </Form.Item>
                                {
                                    props.company?.id != 0 ?
                                        <Form.Item
                                            name="reportType"
                                            label="Report Type"
                                            labelCol={{ span: 9 }}
                                            labelAlign={"left"}
                                            required
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please select report type?",
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ width: 400 }}
                                                placeholder="Select"
                                                dropdownStyle={{ zIndex: 10000 }}
                                            >
                                                <Option
                                                    value="FORMAT_1"
                                                >
                                                    Format 1 (wm)
                                                </Option>
                                                <Option value="FORMAT_2">Format 2 (new customer/floorio)</Option>
                                            </Select>
                                        </Form.Item> : ""
                                }

                                <Form.Item required={false}
                                    name="Plans included" label="Plans Included"
                                    labelCol={{ span: 9 }}
                                    labelAlign={"left"}>
                                    <Checkbox
                                        name="reportImage2D"
                                        onChange={handleCheckboxChange}
                                        checked={formData.reportImage2D}
                                    >
                                        2D
                                    </Checkbox>
                                    <Checkbox
                                        name="reportImage3D"
                                        onChange={handleCheckboxChange}
                                        checked={formData.reportImage3D}
                                    >
                                        3D _1
                                    </Checkbox>
                                    <Checkbox
                                        name="reportImage3D_2"
                                        onChange={handleCheckboxChange}
                                        checked={formData.reportImage3D_2}
                                    >
                                        3D _2
                                    </Checkbox>

                                </Form.Item>

                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>

            </Dialog>
        </React.Fragment>
    )
}

export default UpdateCompanyDialog
