import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./components/app";
import { Provider as StateProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { initAxiosInterceptors } from "./utils/axios-interceptors";

initAxiosInterceptors();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <Router>
    <StateProvider key={4321} store={store}>
      <App />
    </StateProvider>
  </Router>
);
