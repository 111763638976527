import { Form, Spin, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Button as PrimeBtn } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";

import {
    updateDropboxLink
} from "../../../apis/ProjectApis";

const AddDropboxLinkDialog = (props) => {
    const [apiLoading, setApiLoading] = useState(false);
    const toast = useRef(null);
    const [form] = Form.useForm();
    const project = props.project;
    const [disableSubmit, setDisableSubmit] = useState(false);

    useEffect(() => {
        if (project) {
            form.setFieldsValue({ dropboxPictureLink: project?.dropboxPictureLink });
            form.setFieldsValue({ e57DropboxLink: project?.e57DropboxLink });
            submitButtonDisabled();
        }
    }, [project]);


    const handleSubmit = () => {
        form.validateFields().then((values) => {
            setApiLoading(true);
            const projectValues = {
                id: project.id,
                dropboxPictureLink: values.dropboxPictureLink?.toString(),
                e57DropboxLink: values.e57DropboxLink?.toString(),
            };
            console.log("Project values: " + JSON.stringify(projectValues))

            updateDropboxLink(projectValues).then((res) => {
                console.log("Response from update dropbox api:" + JSON.stringify(res))
                if (res.status === 200 && res.data === true) {
                    toast.current.show({
                        severity: "success",
                        summary: "Status",
                        detail: "Update dropbox link successfully",
                        life: 3000
                    })

                    setApiLoading(false);
                    props.setVisible(false);
                } else {
                    toast.current.show({
                        severity: "warn",
                        summary: "Status",
                        detail: "Update dropbox link failed!",
                        life: 3000
                    })
                    setApiLoading(false);
                    props.setVisible(false);
                }
            }).catch((e) => {
                toast.current.show({
                    severity: "error",
                    summary: "Status",
                    detail: e,
                    life: 3000,
                });
                setApiLoading(false);
            });

        })
    }

    const submitButtonDisabled = () => {
        const dropboxPictureLink = form.getFieldValue('dropboxPictureLink');
        const e57DropboxLink = form.getFieldValue('e57DropboxLink');
        
        // Disable the submit button if both text areas are empty
        setDisableSubmit(!dropboxPictureLink && !e57DropboxLink);
      };
    
    const onChange = (e)=> {
        if(e.target.value){
            setDisableSubmit(false);
        }else{
            setDisableSubmit(true);
        }
    }

    return (
        <React.Fragment>
            <Toast ref={toast} />
            <Dialog
                header={"Upload URL link"}
                visible={props.visible}
                style={{minWidth:"420px"}}
                onHide={() => { props.setVisible(false) }}
            >
                <Spin spinning={apiLoading} >
                    <React.Fragment>
                        <div className='flex justify-content-between align-items-center'>
                            <Form form={form} onFinish={handleSubmit}>
                                <Form.Item
                                    required={false}
                                    name="dropboxPictureLink" label="Pictures url Link"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 15 }}
                                    labelAlign={"left"}
                                >
                                    <TextArea placeholder="Enter link" style={{minWidth:"200px"}} onChange={onChange}>
                                    </TextArea>
                                </Form.Item>
                                <Form.Item
                                    required={false}
                                    name="e57DropboxLink" label="E57 url Link"
                                    labelCol={{ span: 12 }}
                                    wrapperCol={{ span: 15 }}
                                    labelAlign={"left"}
                                >
                                    <TextArea placeholder="Enter link" style={{minWidth:"200px"}}>
                                    </TextArea>
                                </Form.Item>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <PrimeBtn
                                        style={{
                                            backgroundColor: "#037eea",
                                            borderColor: "#037eea",
                                            float: "right",
                                            marginRight: "1rem",
                                        }}
                                        type="submit"
                                        label="Submit"
                                        disabled={disableSubmit}
                                    />
                                </div>
                            </Form>
                        </div>
                    </React.Fragment>
                </Spin>

            </Dialog>
        </React.Fragment>
    )
}

export default AddDropboxLinkDialog
