import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./ui-kit/loader/index";
import { clearAuth } from "../utils/authToken";
import { resetEntities } from "./../actions/data";
import { saveUserData } from "../actions/user";
import { AppTopbar } from "./navbar/AppTopBar";
import { AppProvider } from "../contexts/app";
import { useLocation, Route, useHistory, Switch } from "react-router-dom";
import "./style.scss";
import { CSSTransition } from "react-transition-group";
import NewProject from "./project/newproject/newproject";
import ProjectDetail from "./project/detail/projectDetails";
import Dashboard from "./dashboard/dashboard";
import { AppMenu } from "./navbar/AppMenu";
import { AppFooter } from "./navbar/AppFooter";
import PrimeReact from "primereact/api";
import classNames from "classnames";
import { Tooltip } from "antd";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../assets/layout/flags/flags.css";
import "../assets/layout/layout.scss";
import ProjectEdit from "./project/edit/projectEdit";
import Users from "./settings/user/user";
import Roles from "./settings/role/role";
import Agency from "./agency/agency";
import {
  DASHBOARD,
  GENERATES_INVOICE_MENUITEM,
  NEW_PROJECT,
  VIEW_AGENCY_MENUITEM,
  VIEW_PROJECT,
  VIEW_USER,
  ADMIN_EDIT,
  PAGE_3D_EDIT
} from "../utils/constants";
import Invoice from "./project/invoice/invoice";
import Admin from "./admin/admin";
import Config3D from "./3D/3D";

const Home = (props) => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [language, setLanguage] = useState("en");
  const { loading = {}, currentUser = {} } = useSelector(({ app }) => app);
  const location = useLocation();
  const { app: apploading } = loading;
  const history = useHistory();
  const dispatch = new useDispatch();
  const copyTooltipRef = useRef();

  document.documentElement.style.fontSize = "11px";

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const url = new URL(window.location);
    if (url.href.includes("/projectDetail?id=")) {
      const id = url.searchParams.get("id");
      history.push({
        pathname: `${id}/projectEdit`,
        state: {
          id: id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location]);

  const handleSignOut = (e) => {
    props.onLogout();
    history.push("/");
    localStorage.clear();
    dispatch(resetEntities());
    clearAuth();
    dispatch(saveUserData(null));
  };

  useEffect(() => {
    if (history.location.pathname === "/login") {
      history.push("/");
      handleSignOut();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (props.defaultView && location.pathname === "/" && currentUser) {
      history.push(props.defaultView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  // useEffect(() => {
  //   copyTooltipRef &&
  //     copyTooltipRef.current &&
  //     copyTooltipRef.current.updateTargetEvents();
  // }, [location]);

  // const onInputStyleChange = (inputStyle) => {
  //   setInputStyle(inputStyle);
  // };

  // const onRipple = (e) => {
  //   PrimeReact.ripple = e.value;
  //   setRipple(e.value);
  // };

  // const onLayoutModeChange = (mode) => {
  //   setLayoutMode(mode);
  // };

  // const onColorModeChange = (mode) => {
  //   setLayoutColorMode(mode);
  // };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  // const onSidebarClick = () => {
  //   menuClick = true;
  // };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = (permissions) => {
    const menuItems = [];
    permissions.forEach((permission) => {
      if (permission.name === DASHBOARD) {
        menuItems.push({
          key: "1",
          label: "Dashboard",
          icon: "pi pi-fw pi-home",
          to: "/dashboard",
        });
      }
      if (permission.name === NEW_PROJECT) {
        menuItems.push({
          key: "2",
          label: "New Project",
          icon: "pi pi-fw pi-building",
          to: "/newproject",
        });
      }
      if (permission.name === VIEW_PROJECT) {
        menuItems.push({
          key: "3",
          label: "Projects",
          icon: "pi pi-fw pi-list",
          to: "/projectDetail",
        });
      }
      if (permission.name === VIEW_AGENCY_MENUITEM) {
        menuItems.push({
          key: "4",
          label: "Agency",
          icon: "pi pi-users",
          to: "/agency",
        });
      }
      if (permission.name === GENERATES_INVOICE_MENUITEM) {
        menuItems.push({
          key: "5",
          label: "Invoice",
          icon: "pi pi-file-excel",
          to: "/invoice",
        });
      }
      if (permission.name === VIEW_USER) {
        menuItems.push({
          key: "6",
          label: "Users",
          icon: "pi pi-users",
          to: "/users",
        });
      }

      if (permission.name === ADMIN_EDIT) {
        menuItems.push({
          key: "7",
          label: "Admin",
          icon: "pi pi-user-edit",
          to: "/admin",
        });
      }

      if (permission.name === PAGE_3D_EDIT) {
        menuItems.push({
          key: "7",
          label: "3D",
          icon: "pi pi-images",
          to: "/3D",
        });
      }
    });
    return menuItems.sort((item1, item2) => item1.key - item2.key);
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  return (
    <AppProvider value={{ language, setLanguage }}>
      <main className="floorplanner-app">
        <div className="content">
          <div className={wrapperClass} onClick={onWrapperClick}>
            {apploading && apploading.status && (
              <Loader text={apploading.text} />
            )}
            <Suspense fallback={<Loader />}>
              <Tooltip
                ref={copyTooltipRef}
                target=".block-action-copy"
                position="bottom"
                content="Copied to clipboard"
                event="focus"
              />

              <AppTopbar
                handleSignOut={handleSignOut}
                onToggleMenuClick={onToggleMenuClick}
                layoutColorMode={layoutColorMode}
                mobileTopbarMenuActive={mobileTopbarMenuActive}
                onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
              />

              <div className="layout-sidebar">
                <AppMenu
                  model={menu(permissions)}
                  onMenuItemClick={onMenuItemClick}
                  layoutColorMode={layoutColorMode}
                />
              </div>
              <div className="layout-main-container">
                <div className="layout-main">
                  <Route
                    component={(props) => {
                      window.appHistory = props.history;
                    }}
                  />

                  <Switch>
                    {permissions.map((permission) => {
                      return permission.name === DASHBOARD ? (
                        <Route
                          key="1"
                          exact
                          path="/dashboard"
                          component={(props) => <Dashboard {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === NEW_PROJECT ? (
                        <Route
                          key="2"
                          exact
                          path="/newproject"
                          component={(props) => <NewProject {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}
                    <Route
                      key="3"
                      path="/:id/projectEdit"
                      component={(props) => <ProjectEdit {...props} />}
                    />
                    <Route
                      key="4"
                      exact
                      path="/projectDetail"
                      component={(props) => <ProjectDetail {...props} />}
                    />

                    {permissions.map((permission) => {
                      return permission.name === VIEW_AGENCY_MENUITEM ? (
                        <Route
                          key="6"
                          exact
                          path="/agency"
                          component={(props) => <Agency {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === VIEW_USER ? (
                        <Route
                          key="7"
                          exact
                          path="/users"
                          component={(props) => <Users {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === ADMIN_EDIT ? (
                        <Route
                          key="8"
                          exact
                          path="/admin"
                          component={(props) => <Admin {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === PAGE_3D_EDIT ? (
                        <Route
                          key="8"
                          exact
                          path="/3D"
                          component={(props) => <Config3D {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === VIEW_USER ? (
                        <Route
                          key="9"
                          exact
                          path="/roles"
                          component={(props) => <Roles {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === GENERATES_INVOICE_MENUITEM ? (
                        <Route
                          key="9"
                          exact
                          path="/invoice"
                          component={(props) => <Invoice {...props} />}
                        />
                      ) : (
                        ""
                      );
                    })}
                  </Switch>
                </div>

                <AppFooter layoutColorMode={layoutColorMode} />
              </div>
              {/* <AppConfig
              rippleEffect={ripple}
              onRippleEffect={onRipple}
              inputStyle={inputStyle}
              onInputStyleChange={onInputStyleChange}
              layoutMode={layoutMode}
              onLayoutModeChange={onLayoutModeChange}
              layoutColorMode={layoutColorMode}
              onColorModeChange={onColorModeChange}
            /> */}
              <CSSTransition
                classNames="layout-mask"
                timeout={{ enter: 200, exit: 200 }}
                in={mobileMenuActive}
                unmountOnExit
              >
                <div className="layout-mask p-component-overlay"></div>
              </CSSTransition>
            </Suspense>
          </div>
        </div>
      </main>
    </AppProvider>
  );
};
export default Home;
