import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import "./style.scss";
import {
  getRoles,
  addUser,
  editUser,
  getUsersCount,
  getAllUser,
} from "../../../apis/SettingApis";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import {
  Form,
  Input,
  Spin,
  Switch,
  Button as AntButton,
  Select,
  Tag,
} from "antd";
import {
  ADD_COMPANY,
  ADD_USER,
  ALPHABETICAL_INPUTS,
  UPDATE_USER,
  USERS_TYPE,
  VIEW_ROLE,
} from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Paginator } from "primereact/paginator";
import { getAllCompanies } from "../../../apis/CompanyApis";
import AddCompanyDialog from "./dialog/AddCompanyDialog";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUserDialog, setNewUserDialog] = useState(false);
  const [required, setRequired] = useState(false);
  const [editCheck, setEditCheck] = useState(false);
  const [userEnable, setUserEnable] = useState(0);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [basicFirst, setBasicFirst] = useState(0);
  const [totalRows, setTotalRows] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState();
  const [companies, setCompanies] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [showSelectCustomer, setShowSelectCustomer] = useState(false);
  const [visibleNewCompanyDialog, setVisibleNewCompanyDialog] = useState(false);

  const { currentUser = {} } = useSelector(({ app }) => app);
  const [form] = Form.useForm();
  const dt = useRef(null);
  const toast = useRef(null);
  const { Option } = Select;
  const history = useHistory();

  useEffect(() => {
    fetchCompanies();
  }, [])

  const changeVisibleNewCompanyDialogState = (bool) => {
    fetchCompanies();
    setVisibleNewCompanyDialog(bool);
  };

  const fetchCompanies = () => {
    setApiLoading(true);
    getAllCompanies()
      .then((res) => {
        if (res.status === 200) {
          setCompanies(res.data);
          let options = (res.data).map((r) => (
            { value: r.id, label: r.name }
          ))
          setCompanyOptions(options);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: res.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
  }

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const fetchUserCount = () => {
    if (pageNumber >= 0 && totalRows >= 10) {
      setApiLoading(true);
      getUsersCount()
        .then((res) => {
          if (res.status === 200) {
            setTotalRecords(res.data);
            fetchUsers(pageNumber, totalRows);
            setApiLoading(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: res.response.data,
              life: 3000,
            });
            setApiLoading(false);
          }
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: e,
            life: 3000,
          });
          setApiLoading(false);
        });
    }
  };

  const fetchUsers = (current, pageSize) => {
    setApiLoading(true);
    getAllUser(current, pageSize)
      .then((response) => {
        if (response.status === 200) {
          setUsers(response.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: response.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
    return false;
  };

  const fetchRoles = () => {
    setApiLoading(true);
    getRoles()
      .then((response) => {
        if (response.status === 200) {
          setRolesOptions(response.data);
          setApiLoading(false);
        } else {
          toast.current.show({
            severity: "error",
            summary: "Status",
            detail: response.response.data,
            life: 3000,
          });
          setApiLoading(false);
        }
      })
      .catch((e) => {
        toast.current.show({
          severity: "error",
          summary: "Status",
          detail: e,
          life: 3000,
        });
        setApiLoading(false);
      });
    return false;
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchUserCount();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pageNumber, totalRows]);

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: "right",
    name: "user-form",
  };

  const onBasicPageChange = (event) => {
    setApiLoading(true);
    setBasicFirst(event.first);
    setPageNumber(event.page);
    setTotalRows(event.rows);
    setApiLoading(false);
  };

  const handleSubmit = (value) => {
    form.validateFields().then((values) => {
      setApiLoading(true);
      let company = companies.filter((c) => c.id === values.companyId);
      values.enabled = userEnable;
      values.id = form.getFieldValue("id") ? form.getFieldValue("id") : null;
      values.roles = values.roles.map((rId) => ({ id: rId }));
      if(values.userType === "Customer"){
        values.company = company[0];
      }else{
        values.company = null;
      }
      if (editCheck) {
        editUser({ ...values })
          .then((res) => {
            if (res.status === 200) {
              setNewUserDialog(false);
              setRequired(false);
              setEditCheck(false);
              form.resetFields();
              fetchUsers(pageNumber, totalRows);
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "User Updated Succesfully",
                life: 3000,
              });
              setApiLoading(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
      } else {
        addUser({ ...values })
          .then((res) => {
            if (res.status === 200) {
              setNewUserDialog(false);
              setRequired(false);
              form.resetFields();
              fetchUsers(pageNumber, totalRows);
              setEditCheck(false);
              toast.current.show({
                severity: "success",
                summary: "Status",
                detail: "New User Added Successfully",
                life: 3000,
              });
              setApiLoading(false);
            } else {
              toast.current.show({
                severity: "error",
                summary: "Status",
                detail: res.response.data,
                life: 3000,
              });
              setApiLoading(false);
            }
          })
          .catch((e) => {
            toast.current.show({
              severity: "error",
              summary: "Status",
              detail: e,
              life: 3000,
            });
            setApiLoading(false);
          });
      }
    });
  };

  const handleUserTypeChange = (value) => {
    setShowSelectCustomer(value === 'Customer');
  };

  const onChange = (checked) => {
    checked === false ? setUserEnable(0) : setUserEnable(1);
  };

  return (
    <React.Fragment>
      <div className="user-custom-style">
        <Toast ref={toast} />

        <div>
          <Spin spinning={apiLoading}>
            <Toolbar
              className="mb-4"
              left={() => {
                return (
                  <React.Fragment>
                    <div>
                      <h3 className="m-0">Users</h3>
                    </div>
                  </React.Fragment>
                );
              }}
              right={() => {
                return (
                  <React.Fragment>
                    {permissions.map((permission) => {
                      return permission.name === VIEW_ROLE ? (
                        <Button
                          label="View Roles"
                          icon="pi pi-plus"
                          className="p-button-success mr-2"
                          style={{
                            backgroundColor: "#037EEA",
                            borderColor: "#037EEA",
                          }}
                          onClick={() => history.push("/roles")}
                        />
                      ) : (
                        ""
                      );
                    })}
                    {permissions.map((permission) => {
                      return permission.name === ADD_USER ? (
                        <Button
                          label="New"
                          icon="pi pi-plus"
                          className="p-button-success mr-2"
                          style={{
                            backgroundColor: "#037EEA",
                            borderColor: "#037EEA",
                          }}
                          onClick={() => {
                            setEditCheck(false);
                            setNewUserDialog(true);
                          }}
                        />
                      ) : (
                        ""
                      );
                    })}
                    {permissions.map((permission) => {
                      return permission.name === ADD_COMPANY ? (
                        <Button
                          label="Add Company"
                          icon="pi pi-plus"
                          className="p-button-success mr-2"
                          style={{
                            backgroundColor: "#037EEA",
                            borderColor: "#037EEA",
                          }}
                          onClick={(e) =>{
                            e.stopPropagation();
                            setVisibleNewCompanyDialog(true);
                          }}
                        />
                      ) : (
                        ""
                      );
                    })}
                  </React.Fragment>
                );
              }}
            ></Toolbar>
            <DataTable
              ref={dt}
              value={users}
              selection={selectedUser}
              onSelectionChange={(e) => setSelectedUser(e.value)}
              dataKey="id"
              responsiveLayout="scroll"
            >
              <Column field="id" header="ID" />
              <Column field="username" header="Username" />
              <Column field="name" header="Name" />
              <Column field="email" header="Email" />
              <Column field="userType" header="User Type" />
              <Column
                field="roles"
                header="Role"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex justify-content-center flex-wrap gap-1">
                        {record.roles.map((role) => {
                          return (
                            <div>
                              <Tag
                                color="blue"
                                key={role.id}
                                style={{ margin: "0.2rem" }}
                              >
                                {role.name ? role.name : role.name}
                              </Tag>
                            </div>
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
              <Column field="company.name" header="Customer" />
              <Column
                field="creationDate"
                header="Created On"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <span>
                        {record.creationDate
                          ? moment(record.creationDate).format("MM/DD/yyyy")
                          : ""}
                      </span>
                      {/* <br />
                      <span>
                        {record.creationDate
                          ? moment(record.creationDate).format("hh:mm a")
                          : ""}
                      </span> */}
                    </React.Fragment>
                  );
                }}
              />
              <Column
                field="enabled"
                header="Status"
                body={(record) => {
                  return (
                    <React.Fragment>
                      {record.enabled ? (
                        <div>
                          <span
                            style={{ color: "blue", fontWeight: "bold" }}
                            type="text"
                          >
                            Enabled
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span
                            style={{ color: "red", fontWeight: "bold" }}
                            type="text"
                          >
                            Disabled
                          </span>
                        </div>
                      )}
                    </React.Fragment>
                  );
                }}
              />
              <Column
                field="action"
                header="Action"
                body={(record) => {
                  return (
                    <React.Fragment>
                      <div className="flex justify-content-center">
                        {permissions.map((permission) => {
                          return permission.name === UPDATE_USER ? (
                            <div>
                              <Button
                                style={{
                                  backgroundColor: "#037EEA",
                                  borderColor: "#037EEA",
                                }}
                                icon={"pi pi-pencil"}
                                label="Edit"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setEditCheck(true);
                                  setRequired(true);
                                  setNewUserDialog(true);
                                  setShowSelectCustomer(record.userType === 'Customer');
                                  form.setFieldsValue({
                                    id: record.id,
                                    name: record.name,
                                    roles: (record.roles || []).map(
                                      (p) => p.id
                                    ),
                                    userType: record.userType,
                                    enabled: setUserEnable(record.enabled),
                                    email: record.email,
                                    username: record.username,
                                    password: null,
                                    fullName: record.fullName,
                                    bankAccount: record.bankAccount,
                                    currency: record.currency,
                                    swiftCode: record.swiftCode,
                                    address: record.address,
                                    companyId: record.company?.id,
                                  });
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          );
                        })}
                      </div>
                    </React.Fragment>
                  );
                }}
              />
            </DataTable>
            <Paginator
              first={basicFirst}
              rows={totalRows}
              totalRecords={totalRecords}
              rowsPerPageOptions={[10, 20, 30]}
              onPageChange={onBasicPageChange}
            ></Paginator>
          </Spin>
        </div>

        <Dialog
          header="Add/Edit User"
          visible={newUserDialog}
          style={{ width: "30vw" }}
          onHide={() => {
            setNewUserDialog(false);
            setEditCheck(false);
            setRequired(false);
            form.resetFields();
          }}
        >
          <Spin spinning={false}>
            <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    pattern: ALPHABETICAL_INPUTS,
                    type: "string",
                    message: "Please enter valid name",
                  },
                ]}
              >
                <Input maxLength={512} />
              </Form.Item>
              <Form.Item
                name="roles"
                label="Roles"
                rules={[
                  {
                    required: true,
                    message: "Please select permissions from list",
                  },
                ]}
              >
                <Select
                  showSearch
                  mode="multiple"
                  dropdownStyle={{ zIndex: "9999" }}
                >
                  {rolesOptions
                    ? rolesOptions.map((role) => {
                        return (
                          <Option key={role.id} value={role.id}>
                            {role.name}
                          </Option>
                        );
                      })
                    : ""}
                </Select>
              </Form.Item>

              <Form.Item
                name="userType"
                label="User Type"
                rules={[
                  {
                    required: true,
                    message: "Please select user type from list",
                  },
                ]}
                z
              >
                <Select dropdownStyle={{ zIndex: "9999" }}
                  onChange={handleUserTypeChange}
                >
                  {USERS_TYPE.map((type) => (
                    <Option key={type.id} value={type.name}>
                      {type.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              { showSelectCustomer && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Customer is required.",
                      },
                    ]}
                    name="companyId"
                    label="Customer"
                  >
                    <Select
                      mode={"single"}
                      dropdownStyle={{ zIndex: "9999" }}
                      placeholder="Select Customer"
                      options={companyOptions}
                      
                    >
                    </Select>
                  </Form.Item>
                )}

              <Form.Item name="enabled" label="Enable">
                <Switch
                  style={{ float: "left" }}
                  defaultChecked
                  onChange={onChange}
                  checked={userEnable}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter valid email",
                  },
                ]}
              >
                <Input type="email" maxLength={64} />
              </Form.Item>

              <Form.Item
                name="username"
                label="Login ID"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input type="login" maxLength={20} />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                // hidden={required}
                rules={[
                  {
                    required: !required,
                    message: "Please input password",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  onChange={(e) => {
                    if (editCheck) {
                      e.target.value.length >= 0 && e.target.value !== ""
                        ? setRequired(false)
                        : form.getFieldValue("confirm") !== ""
                        ? setRequired(false)
                        : setRequired(true);
                    }
                  }}
                  autoComplete="new-password"
                  maxLength={60}
                />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                hasFeedback
                // hidden={required}
                rules={[
                  {
                    required: !required,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        "The two passwords that you entered do not match!"
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  onChange={(e) => {
                    if (editCheck) {
                      e.target.value.length >= 0 && e.target.value !== ""
                        ? setRequired(false)
                        : form.getFieldValue("password") !== ""
                        ? setRequired(false)
                        : setRequired(true);
                    }
                  }}
                />
              </Form.Item>
              <br />
              <h4 style={{ marginLeft: "8%" }}>Invoice Details:</h4>
              <Form.Item name="fullName" label="Full Name">
                <Input />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input />
              </Form.Item>
              <Form.Item name="bankAccount" label="Bank Account">
                <Input />
              </Form.Item>
              <Form.Item name="currency" label="Currency">
                <Input />
              </Form.Item>
              <Form.Item name="swiftCode" label="SwiftCode">
                <Input />
              </Form.Item>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <AntButton type="primary" key="submit" htmlType="submit">
                    Submit
                  </AntButton>
                </div>
              </div>
            </Form>
          </Spin>
        </Dialog>
        <AddCompanyDialog
        visible={visibleNewCompanyDialog}
        setVisible={changeVisibleNewCompanyDialogState}
      />
      </div>
    </React.Fragment>
  );
};

export default Users;
