import { SET_CURRENT_USER, APP_CONTEXT_URL } from "../utils/constants";
import request from "../service/index";
import { message } from "antd";

/** USER ACTIONS */

export const registerUser = (values, history) => {
  request({
    method: "POST",
    url: APP_CONTEXT_URL + "/v1/register",
    data: values,
    loadingText: "Please Wait...",
  }).then(() => {
    history.push("/login");
  });
};
export const loginUser = (values, history, location, props) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: APP_CONTEXT_URL + "/v1/login",
      data: values,
      loadingText: "Logging You In...",
    }).then((res) => {
      if (res.credentialsNonExpired === true) {
        dispatch(saveUserData(res));
        let { from } = location.state || { from: { pathname: "/dashboard" } };
        history.replace(from);
        props.authStatus();
        message.success("Login Successfull");
      } else {
        let { from } = location.state || { from: { pathname: "/" } };
        history.replace(from);
      }
    });
  };
};

export const loadUserInfo = () => {
  return (dispatch) => {
    request({
      method: "POST",
      url: APP_CONTEXT_URL + "/v1/user",
      loadingText: "Loading User Info...",
    }).then((res) => {
      dispatch(saveUserData(res));
    });
  };
};
export const saveUserData = (userInfo) => {
  return {
    type: SET_CURRENT_USER,
    payload: userInfo,
  };
};
