import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const addProjectComment = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/comment", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const deleteProjectComment = (id) => {
  return axios.delete(APP_CONTEXT_URL + "/v1/comment/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const updateProjectComment = (values) => {
  return axios.put(APP_CONTEXT_URL + "/v1/comment", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
  });
};

export const getProjectComment = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/comment/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "text",
  });
};

export const downloadAttachments = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/downloadAttachments/", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__Secure-floorplanner_token"),
    },
    responseType: "blob",
  });
};
